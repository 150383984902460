import React, { useState } from 'react';
import {Select, MenuItem, Button, Typography, Container, Grid} from '@mui/material';

const MoveItem = ({ movedItem, externalCheckList, boxesData, onMove, onClose, movedType }) => {
    const [selectedId, setSelectedId] = useState('');
    const handleTaskChange = (event) => {
        setSelectedId(event.target.value);

    };

    const handleMove = () => {
        if (selectedId) {
            if (movedType === 'task') {
                onMove(movedItem.task_item_id, selectedId);
            } else {
                onMove(movedItem.task_box_item_id, selectedId);
            }
            onClose();
        }
    };
    return (
        <Container>
            <Grid container justifyContent="center" spacing={2} alignItems={"center"}>
                <Grid item xs={12} sm={12} md={12}>
                    <Typography>
                        Move item: <b>{movedItem.part_number}</b>
                    </Typography>
                </Grid>
                <Grid item xs={1} sm={1} md={1}>
                    <Typography>
                        To
                    </Typography>
                </Grid>
                <Grid item xs={9} sm={9} md={9}>
                    <Select
                        value={selectedId}
                        onChange={handleTaskChange}
                        fullWidth
                    >
                        {movedType === 'task' ? (
                            externalCheckList.map((task) => (
                                <MenuItem key={task.task_id} value={task.task_id}>
                                    {task.task_num.split('_')[1]}
                                </MenuItem>
                            ))
                        ):(
                            boxesData.map((box) => (
                                <MenuItem key={box.task_box_id} value={box.task_box_id}>
                                    {box.task_box_name}
                                </MenuItem>
                            ))

                        )
                        }
                    </Select>
                </Grid>
                <Grid item xs={2} sm={2} md={2}>
                    <Button onClick={handleMove} color="success" variant="outlined">
                        Move
                    </Button>
                </Grid>
            </Grid>
        </Container>
    );
};

export default MoveItem;
