import React from 'react';
import { Button, Chip, } from '@mui/material';


const Chips = ({ chips, onDelete, onDeleteAll }) => {
    return(
        <div>
            {chips.map((chip, index) =>(
                <Chip
                    key={index}
                    label={chip}
                    onDelete={() => onDelete(chip)}
                />
            ))}
            {chips.length > 0 && (
                <Button variant='text' color='success' onClick={onDeleteAll}>
                    Clear filters
                </Button>
            )}
        </div>       
    );
}

export default Chips;