import {
    Button,
    Card,
    CardActionArea,
    CardActions,
    CardContent,
    CardMedia,
    Hidden,
    Typography
} from "@mui/material";
import default_ico from "../../../images/default_ico.png";
import React from "react";


export default function SingleProduct({product, onProductClick, handleOpenGallery, handleOpenAllGallery, taskType, handleMoveClick, handleLabelClick}){

    const baseRoute = process.env.REACT_APP_API_BASE_ROUTE

    return(
        <Card>
            <CardActionArea onClick={onProductClick}>
                <CardContent>
                    {product.source !== "" ? (
                        <CardMedia
                            component="img"
                            sx={{
                                width: "30%",
                                height: "30%",
                                objectFit: "cover",
                                display: "block",
                                margin: "auto",
                            }}
                            image={`${baseRoute}${product.source}`}

                        />
                    ): (
                        <CardMedia
                            component="img"
                            sx={{
                                width: "30%",
                                height: "30%",
                                objectFit: "cover",
                                display: "block",
                                margin: "auto",
                            }}
                            image={default_ico}

                        />
                    )}
                    <Typography align={"inherit"}>
                        <b>PN:</b> {product.part_number}
                    </Typography>
                    <Typography align={"inherit"}>
                        <b>DC:</b> {product.date_code}
                    </Typography>
                    <Typography align={"inherit"}>
                        <b>MNF:</b> {product.manufacturer}
                    </Typography>
                    <Hidden smDown>
                        <Typography align={"inherit"}>
                            <b>Desc:</b> {product.description}
                        </Typography>
                    </Hidden>
                    <Typography align={"inherit"}>
                        <b>QTY:</b> {product.quantity_sum} / {product.total_quantity}
                    </Typography>
                </CardContent>
            </CardActionArea>
            <CardActions>
                <Button variant="text" color="success" fullWidth onClick={handleOpenGallery}>
                    {product.s3_objects && product.s3_objects.length} photos
                </Button>
                <Button variant="text" color="success" fullWidth onClick={handleOpenAllGallery}>
                    All photos
                </Button>
                {taskType === "external" &&
                    <Button variant="text" color="success" fullWidth onClick={handleMoveClick} >
                        Move
                    </Button>
                }
            </CardActions>
            <CardActions>
                {taskType === "external" &&
                       <Button variant="text" color="success" fullWidth onClick={handleLabelClick}>
                           Change Label Info
                       </Button>
                }
            </CardActions>
        </Card>
    )
}