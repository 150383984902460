import {Grid} from "@mui/material";
import StockItems from "./StockItems";
import TaskItems from "./TaskItems";
import React from "react";


export default function AddItemsToTask({
                                           taskItemsResp,
                                           values,
                                           setValues,
                                           addedQuantity,
                                           handlePageChange,
                                           currentPage,
                                           stockItemsResp,
                                           selectedItems,
                                           setSelectedItems,
                                           handleAddItems,
                                           handleUpdateItems,
                                           handleRemoveItem,
                                           updatedItems,
                                           setUpdatedItems,
                                           currentTaskPage,
                                           handleTaskPageChange,
                                           partNumberFilter,
                                           setPartNumberFilter,
                                           selectedOrders,
                                           setSelectedOrders,
                                           taskFields
                                       }){

    return(
        <Grid container spacing={2} align={"center"}>
            { taskFields.task_state === 'preparing' ? (
                <>
                    <Grid item xs={6} sm={6} md={6} >
                        <StockItems
                            stockItems={stockItemsResp.data}
                            addedQuantity={addedQuantity}
                            selectedItems={selectedItems}
                            setSelectedItems={setSelectedItems}
                            handleAddItems={handleAddItems}
                            currentPage={currentPage}
                            lastPage={stockItemsResp.last_page}
                            handlePageChange={handlePageChange}
                            values={values}
                            setValues={setValues}
                            selectedOrders={selectedOrders}
                            setSelectedOrders ={setSelectedOrders}
                        />
                    </Grid>
                    <Grid item xs={6} sm={6} md={6}>
                        <TaskItems
                            taskItems={taskItemsResp.task_items}
                            handleUpdateItems={handleUpdateItems}
                            handleRemoveItem={handleRemoveItem}
                            updatedItems={updatedItems}
                            setUpdatedItems={setUpdatedItems}
                            currentPage={currentTaskPage}
                            lastPage={taskItemsResp.last_page}
                            handlePageChange={handleTaskPageChange}
                            partNumberFilter={partNumberFilter}
                            setPartNumberFilter={setPartNumberFilter}
                            taskFields={taskFields}
                        />
                    </Grid>
                </>
            ) : (
                <Grid item xs={12} sm={12} md={12}>
                            <TaskItems
                                taskItems={taskItemsResp.task_items}
                                handleUpdateItems={handleUpdateItems}
                                handleRemoveItem={handleRemoveItem}
                                updatedItems={updatedItems}
                                setUpdatedItems={setUpdatedItems}
                                currentPage={currentTaskPage}
                                lastPage={taskItemsResp.last_page}
                                handlePageChange={handleTaskPageChange}
                                partNumberFilter={partNumberFilter}
                                setPartNumberFilter={setPartNumberFilter}
                                taskFields={taskFields}
                            />
                </Grid>
            )}
        </Grid>
    )
}