import React, {useState} from 'react';
import {AppBar, Button, Toolbar, IconButton, MenuItem, Menu} from "@mui/material";
import { ReactComponent as LogoSvg } from '../../images/logoSvg.svg';
import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined';
import TaskIcon from '@mui/icons-material/Task';
import { Link } from 'react-router-dom';
import {AccountCircle} from "@mui/icons-material";

export default function Header({ loggedIn, onLogout, onToggleForm, isLoggedOut }) {
    const [anchorEl, setAnchorEl] = useState(null);

    const handleMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const userRoles = JSON.parse(localStorage.getItem('userRoles') || '[]');

    const isAdmin = userRoles.includes('Administrator')

    return (

            <AppBar
                position='static'
                color='success'
            >
                <Toolbar style={{ justifyContent: 'space-between' }}>
                    <div style={{ display: 'flex', alignItems: 'center'}}>
                        <Link to="/" style={{ marginRight: '30px' }}>
                            <IconButton style={{ padding: '0' }}>
                                <LogoSvg style={{ width: '40px', height: 'auto' }} />
                            </IconButton>
                        </Link>
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center'}}>
                        <Link to="/parcels">
                            <IconButton style={{ color: 'white' }}>
                                <LocalShippingOutlinedIcon fontSize="large"/>
                            </IconButton>
                        </Link>
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center'}}>
                        <Link to="/tasks">
                            <IconButton style={{ color: 'white' }}>
                                <TaskIcon fontSize="large"/>
                            </IconButton>
                        </Link>
                    </div>
                    <div>
                        {loggedIn ? (
                            <div>
                                <IconButton
                                    color="inherit"
                                    onClick={handleMenuOpen}
                                >
                                    <AccountCircle fontSize="large"/>
                                </IconButton>
                                <Menu
                                    anchorEl={anchorEl}
                                    keepMounted
                                    open={Boolean(anchorEl)}
                                    onClose={handleMenuClose}
                                >
                                    {isAdmin && (
                                        <Link to="/manageRoles" style={{ textDecoration: 'none' }}>
                                            <MenuItem style={{ background: 'none', color: 'black' }}>
                                                Manage Roles
                                            </MenuItem>
                                        </Link>
                                    )}
                                    <Link to="/roleRequest" style={{ textDecoration: 'none' }}>
                                        <MenuItem style={{ background: 'none', color: 'black' }}>
                                            Request Role
                                        </MenuItem>
                                    </Link>
                                    <Link to="/auth" style={{ textDecoration: 'none' }}>
                                        <MenuItem style={{ background: 'none', color: 'black' }} onClick={onLogout}>
                                            Logout
                                        </MenuItem>
                                    </Link>
                                </Menu>
                            </div>
                        ) : (
                            !isLoggedOut ? (
                                <>
                                    <Button color="inherit" onClick={() => onToggleForm("login")}>
                                        Log In
                                    </Button>
                                    <Button color="inherit" onClick={() => onToggleForm("sign")}>
                                        Sign Up
                                    </Button>
                                </>
                            ) : null
                        )}
                    </div>
                </Toolbar>
            </AppBar>
    );
}
