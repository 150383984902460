import React from 'react';
import { Checkbox, Container, Grid, TextField, FormControlLabel } from '@mui/material';
import Chips from './Chips';


export default function Filters({ values, setValues, exactSearch, setExactSearch }){
    const handleChange = (event, fieldName) =>{
        const inputValue = event.target.value;  // text field value
        const valueArray = inputValue.split(',');  // separating the value of a text field into more using commas, need to enter more values in a text fields
        const trimmedValues = valueArray.map((value) => value.trim());  //  trimming spaces, need to enter more values in a text fields
        setValues((prevValues) => (
            { ...prevValues, [fieldName]: trimmedValues }));  // updating value in object values for exact text field
    }

    const handleDelete = (chip) => {
        setValues((prevValues) => {
          const updatedValues = { ...prevValues };
          const [fieldName, value] = chip.split(': ');
          updatedValues[fieldName] = updatedValues[fieldName].filter((val) => val !== value);
          return updatedValues;
        });
      }

    const handleDleteAll = () =>{
            setValues({});
            setExactSearch(false);
        }

    const renderChips = () =>{
        const chips = [];
        Object.keys(values).forEach((fieldName) =>{
            values[fieldName].forEach((value, index) =>{
                const chipLabel = `${fieldName}: ${value}`;
                chips.push(chipLabel);
            });
        });
        return chips;
    }

    return(
        <Container>
            <Grid item xs={12}>
                <Chips
                    chips = {renderChips()}
                    onDelete = {handleDelete}
                    onDeleteAll={handleDleteAll}
                />
            </Grid>

            <Grid item xs={12} sm={12} md={12}>
                <FormControlLabel
                    control={<Checkbox checked={exactSearch} onChange={() => setExactSearch(!exactSearch)} />}
                    label="точный поиск"
                />
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={12}>
                        <TextField 
                            variant="standard" 
                            label="Введите организацию recipient"
                            type='search'
                            fullWidth
                            size='small'
                            value={values.ShipToCompany ? values.ShipToCompany.join(','): ''}
                            onChange={(event) => handleChange(event, 'ShipToCompany')}
                        >
                        </TextField>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                        <TextField 
                            variant="standard" 
                            label="Введите invoice number"
                            type='search'
                            fullWidth
                            size='small'
                            value={values.Invoice ? values.Invoice.join(','): ''}
                            onChange={(event) => handleChange(event, 'Invoice')}
                        >
                        </TextField>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                        <TextField 
                            variant="standard" 
                            label="Введите tracking number"
                            type='search'
                            fullWidth
                            size='small'
                            value={values.Tracking ? values.Tracking.join(','): ''}
                            onChange={(event) => handleChange(event, 'Tracking')}
                        >
                        </TextField>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                        <TextField 
                            variant="standard" 
                            label="Введите part number"
                            type='search'
                            fullWidth
                            size='small'
                            value={values.PartNumber ? values.PartNumber.join(','): ''}
                            onChange={(event) => handleChange(event, 'PartNumber')}
                        >
                        </TextField>
                    </Grid>
                    {/* <Grid item xs={12} sm={12} md={12}>
                        <TextField
                            variant='standard'
                            label='Введите PoDate'
                            type='date'
                            fullWidth
                            value={values.poDate ? values.poDate.join(','): ''}
                            onChange={(event) => handleChange(event, 'poDate')}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        >
                        </TextField>       
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                        <TextField
                            variant='standard'
                            label='Введите ArrivalDate'
                            type='date'
                            fullWidth
                            value={values.arrivalDate ? values.arrivalDate.join(','): ''}
                            onChange={(event) => handleChange(event, 'arrivalDate')}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        >
                        </TextField>
                    </Grid> */}
                </Grid>
            </Grid>
        </Container>
        
    );
}