import React, {useCallback, useState} from 'react';
import {
    Button,
    Dialog,
    DialogContent,
    DialogTitle,
    Grid,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField
} from "@mui/material";

export default function DocTable({ docsItems, onDocItemChange, orderId }) {

    const handleDocItemChange = useCallback((field, value, product_id, update) => {
        const defaultValue = ['price', 'netto', 'brutto'].includes(field) ? 0 : '';
        const editedItem = {
            ...docsItems.find(item => item.product_id === product_id),
            [field]: value ? value : defaultValue,
        };
        onDocItemChange(editedItem, orderId, update);
    }, [docsItems, onDocItemChange, orderId]);

    const handleKeyPress = (field, e, product_id) => {
        if (e.key === 'Enter') {
            handleDocItemChange(field, e.target.value, product_id, true);
        }
    };

    const handleBlur = (field, e, product_id) => {
        handleDocItemChange(field, e.target.value, product_id, true);
    };

    const [selectedItem, setSelectedItem] = useState(null)
    const handlePartNumberClick = (item) => {
        setSelectedItem(item)
    }
    const handleCloseDialog = () => {
        setSelectedItem(null)
    }


    const renderTaskItems = Array.isArray(docsItems) ? (
        <>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Type</TableCell>
                        <TableCell>Part Number</TableCell>
                        <TableCell>Manufacturer</TableCell>
                        <TableCell>Date Code</TableCell>
                        <TableCell>Part Desccription</TableCell>
                        <TableCell>QTY</TableCell>
                        <TableCell>Price</TableCell>
                        <TableCell>Netto</TableCell>
                        <TableCell>Brutto</TableCell>
                        <TableCell>HS Code</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {docsItems.map((item, itemIndex) => (
                        <TableRow key={itemIndex}>
                            <TableCell>
                                <Button
                                    onClick={() => handlePartNumberClick(item)}
                                    variant="text"
                                    style={{ color: 'black' }}
                                >
                                    {item.item_type}
                                </Button>
                            </TableCell>
                            <TableCell>
                                <TextField
                                    variant="standard"
                                    value={item.part_number}
                                    onChange={(e) => handleDocItemChange('part_number', e.target.value, item.product_id, false)}
                                    onKeyDown={(e) => handleKeyPress('part_number', e, item.product_id)}
                                    onBlur={(e) => handleBlur('part_number', e, item.product_id)}
                                />
                            </TableCell>
                            <TableCell>
                                <TextField
                                    variant="standard"
                                    value={item.manufacturer}
                                    onChange={(e) => handleDocItemChange('manufacturer', e.target.value, item.product_id, false)}
                                    onKeyDown={(e) => handleKeyPress('manufacturer', e, item.product_id)}
                                    onBlur={(e) => handleBlur('manufacturer', e, item.product_id)}
                                />
                            </TableCell>
                            <TableCell>
                                <TextField
                                    variant="standard"
                                    value={item.date_code}
                                    onChange={(e) => handleDocItemChange('date_code', e.target.value, item.product_id, false)}
                                    onKeyDown={(e) => handleKeyPress('date_code', e, item.product_id)}
                                    onBlur={(e) => handleBlur('date_code', e, item.product_id)}
                                />
                            </TableCell>
                            <TableCell>
                                <TextField
                                    variant="standard"
                                    value={item.part_description}
                                    onChange={(e) => handleDocItemChange('part_description', e.target.value, item.product_id, false)}
                                    onKeyDown={(e) => handleKeyPress('part_description', e, item.product_id)}
                                    onBlur={(e) => handleBlur('part_description', e, item.product_id)}
                                />
                            </TableCell>
                            <TableCell>
                                <TextField
                                    variant="standard"
                                    value={item.quantity}
                                    disabled
                                />
                            </TableCell>
                            <TableCell>
                                <TextField
                                    variant="standard"
                                    value={item.price || ""}
                                    onChange={(e) => handleDocItemChange('price', e.target.value, item.product_id, false)}
                                    onKeyDown={(e) => handleKeyPress('price', e, item.product_id)}
                                    onBlur={(e) => handleBlur('price', e, item.product_id)}
                                />
                            </TableCell>
                            <TableCell>
                                <TextField
                                    variant="standard"
                                    value={item.netto || ""}
                                    onChange={(e) => handleDocItemChange('netto', e.target.value, item.product_id, false)}
                                    onKeyDown={(e) => handleKeyPress('netto', e, item.product_id)}
                                    onBlur={(e) => handleBlur('netto', e, item.product_id)}
                                />
                            </TableCell>
                            <TableCell>
                                <TextField
                                    variant="standard"
                                    value={item.brutto || ""}
                                    onChange={(e) => handleDocItemChange('brutto', e.target.value, item.product_id, false)}
                                    onKeyDown={(e) => handleKeyPress('brutto', e, item.product_id)}
                                    onBlur={(e) => handleBlur('brutto', e, item.product_id)}
                                />
                            </TableCell>
                            <TableCell>
                                <TextField
                                    variant="standard"
                                    value={item.hs_code || ""}
                                    onChange={(e) => handleDocItemChange('hs_code', e.target.value, item.product_id, false)}
                                    onKeyDown={(e) => handleKeyPress('hs_code', e, item.product_id)}
                                    onBlur={(e) => handleBlur('hs_code', e, item.product_id)}
                                />
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
            {selectedItem && (
                <Dialog open={Boolean(selectedItem)} onClose={handleCloseDialog}>
                    <DialogTitle>Details from Stock</DialogTitle>
                    <DialogContent>
                        <TableContainer>
                            <Table>
                                <TableBody>
                                    <TableRow>
                                        <TableCell>Part Number:</TableCell>
                                        <TableCell>{selectedItem.stock_part_number}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>Manufacturer:</TableCell>
                                        <TableCell>{selectedItem.stock_manufacturer}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>Date Code:</TableCell>
                                        <TableCell>{selectedItem.stock_date_code}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>Part Description:</TableCell>
                                        <TableCell>{selectedItem.stock_part_description}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>Price:</TableCell>
                                        <TableCell>{selectedItem.stock_price}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>Netto:</TableCell>
                                        <TableCell>{selectedItem.stock_netto}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>Brutto:</TableCell>
                                        <TableCell>{selectedItem.stock_brutto}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>HS Code:</TableCell>
                                        <TableCell>{selectedItem.stock_hs_code}</TableCell>
                                    </TableRow>

                                </TableBody>
                            </Table>
                        </TableContainer>
                    </DialogContent>
                </Dialog>
            )}
        </>
    ) : (
        <Grid item>No available data</Grid>
    );

    return (
        <>
            {renderTaskItems}
        </>
    );
}