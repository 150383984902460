// SearchPanel.js
import React from 'react';
import { Container, Grid, TextField } from '@mui/material';

export default function SearchPanel({ values, setValues }) {

    return (
        <Grid item xs={12} sm={12} md={12}>
            <Container>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} md={6}>
                        {/* -----------------------------------search-text-field---------------------------------- */}
                        <TextField
                            variant="outlined"
                            label="Enter PO number"
                            type='search'
                            fullWidth
                            size='small'
                            value={values.po_num_filter || ''}
                            onChange={(event) => setValues({ ...values, po_num_filter: event.target.value })}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                        {/* -------------------------------------part-number-field--------------------------------- */}
                        <TextField
                            variant="outlined"
                            label="Enter Part Number"
                            type='search'
                            fullWidth
                            size='small'
                            value={values.part_number_filter || ''}
                            onChange={(event) => setValues({ ...values, part_number_filter: event.target.value })}
                        />
                    </Grid>
                </Grid>
            </Container>
        </Grid>
    );
}
