import {Box, Button, Collapse, Dialog, DialogContent, DialogTitle, Grid, Hidden} from "@mui/material";
import { makeStyles } from '@mui/styles';
import Header from "../Header";
import React, {useCallback, useEffect, useState} from "react";
import AddBox from "../AddBox";
import BoxFields from "./BoxFields";
import ProductFields from "./ProductFields";
import CheckList from "../CheckList";
import SelectBox from "./SelectBox";
import SingleBox from "../Boxes/SingleBox";
import Loader from "./Loader";
import {useParams} from 'react-router-dom';
import PhotoGallery from "../PhotoGallery";
import { v4 as uuidv4 } from 'uuid';
import { useNavigate } from  'react-router-dom'
import {toast} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AllPhotosGallery from "../AllPhotosGallery";
import Shipments from "../Shipments";
import AddTaskBox from "../ShipmentEditor/AddTaskBox";

const useStyles = makeStyles({
    sticky: {
        position: 'sticky',
        top: 0,
        overflow: 'auto',
        maxHeight: '100vh',
        direction: 'rtl',
        '&::-webkit-scrollbar': {
            width: '0.4em',
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: 'rgba(0,0,0,.1)',
        },
        '&::-webkit-scrollbar-track': {
            backgroundColor: 'rgba(0,0,0,.1)',
        },
        '&::-webkit-scrollbar-thumb:hover': {
            backgroundColor: 'rgba(0,0,0,.2)',
        },
        '& > *': {
            direction: 'ltr',
        },
        overflowX: 'hidden',
    },
    overflowVisible: {
        overflow: 'visible',
    },
});
export default function CheckOrderPage(){
    const classes = useStyles();

    const baseRoute = process.env.REACT_APP_BASE_ROUTE
    const apiRoute=process.env.REACT_APP_API_BASE_ROUTE
    const xToken=process.env.REACT_APP_X_TOKEN
    const api_token = localStorage.getItem('token');

    const navigate = useNavigate()
    const [boxUUID, setBoxUUID] = useState(uuidv4())
    const [productUUID, setProductUUID] = useState(uuidv4())
    const [productId, setProductId] = useState(0)
    const [isOpenGallery, setOpenGallery] = useState(false)
    const [isOpenAllGallery, setOpenAllGallery] = useState(false)
    const [orderID, setOrderID] = useState(localStorage.getItem('order_id'));
    const [orderData, setOrderData] = useState();
    const [boxesData, setBoxesData] = useState([])
    const [boxFields, setBoxFields] = useState({})
    const [checkList, setCheckList] = useState([])
    const [externalCheckList, setExternalCheckList] = useState([])
    const [searchProductText, setSearchProductText] = useState('')
    const [productFields, setProductFields] = useState({})
    const [photos, setPhotos] = useState([])
    const [selectedProductId, setSelectedProductId] = useState('');
    const [boxProducts, setBoxProducts] = useState([])
    const [isNewProd, setIsNewProd] = useState(true)
    const [isProduct, setIsProduct] = useState(false)
    const [documents, setDocuments] = useState([])
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [isEditBoxOpen, setEditBoxOpen] = useState(false)
    const [isAddProductOpen, setAddProductOpen] = useState(false)
    const [boxPhotos, setBoxPhotos] = useState([])
    const [selectedBoxId, setSelectedBoxId] = useState('')
    const [boxData, setBoxData] = useState({})
    const [isBoxesDataLoaded, setBoxesDataLoaded] = useState(false)
    const [prodPhotos, setProdPhotos] = useState([])
    const [allGalleryData, setAllGalleryData] = useState([])
    const [internalShipments, setInternalShipments] = useState([])
    const [externalShipments, setExternalShipments] = useState([])
    const { po_num } = useParams();
    const [checkListType, setCheckListType] = useState('acceptance')

    const [isAcceptance, setIsAcceptance] = useState(true);
    const [taskFields, setTaskFields] = useState({
        task_id: '',
        task_num: '',
        task_date: '',
        task_box_id: '',
        type: '',
    });

    const [isOpenHandleShipOptions, setIsOpenHandleShipOptions] = useState(false);

    useEffect(() => {
        localStorage.setItem('order_id', po_num);
        setOrderID(localStorage.getItem('order_id'));
      }, [po_num]);

    useEffect(() => {
        handleBoxFieldsChange(boxFields)
    }, [boxFields])
    const handleBoxFieldsChange = updatedData => {
        setBoxFields((prevFormData) => ({
            ...prevFormData,
            ...updatedData
        }))
    }

    const closeModal = () => {
        setOpenGallery(false);
        setProdPhotos([])
    };
    const closeAllModal = () => {
        setOpenAllGallery(false);
        setAllGalleryData([])
    };

    useEffect(() => {
        handleProductFieldsChange(productFields)
    }, [productFields])
    const handleProductFieldsChange = updatedData => {
        setProductFields((prevFormData) => ({
            ...prevFormData,
            ...updatedData
        }))
        setProductUUID(uuidv4())
    }


    const handleAddBox = async () => {
        setIsProduct(false);
        try {
            let newBox
            let data = await addBox()
            if (isAcceptance) {
                setBoxFields({
                    comment: '',
                    height: '',
                    width: '',
                    length: '',
                    weight: '',
                    track_num: '',
                    invoices: [],
                    order_id: orderData.order_id,
                    box_id: parseInt(data.box_id),
                });
                newBox = {
                    weight: '',
                    width: '',
                    height: '',
                    length: '',
                    comment: '',
                    invoices: [],
                    track_num: '',
                    box_status: null,
                    box_id: data.box_id,
                    positions: [],
                    s3_objects: [],
                };
                setSelectedBoxId(data.box_id)
            } else {
                setBoxFields({
                    comment: '',
                    height: '',
                    width: '',
                    length: '',
                    weight: '',
                    tracking: '',
                    task_id: taskFields.task_id,
                    task_box_id: data.task_box_id,
                });
                newBox = {
                    weight: '',
                    width: '',
                    height: '',
                    length: '',
                    comment: '',
                    tracking: '',
                    task_box_id: data.task_box_id,
                    positions: [],
                    s3_objects: []
                };
                setSelectedBoxId(data.task_box_id)
            }


            setPhotos([]);
            setBoxData(newBox);
            const updatedBoxData = [...boxesData, newBox];
            setBoxesData(updatedBoxData);
            setAddProductOpen(false);
            setEditBoxOpen(true);
        } catch (error) {
            console.log('AddBox Error', error);
        }
    };

    const handleEditBox = async (box) => {
        setIsProduct(false);
        setPhotos([]);
        setDocuments([]);
        setAddProductOpen(false);
        setBoxProducts([]);
        if (isAcceptance) {
            setBoxFields({
                comment: box.comment,
                height: box.height,
                width: box.width,
                length: box.length,
                weight: box.weight,
                track_num: box.track_num,
                invoices: box.invoices,
                order_id: orderData.order_id,
                box_id: box.box_id
            });
        } else {
            setBoxFields({
                comment: box.comment,
                height: box.height,
                width: box.width,
                length: box.length,
                weight: box.weight,
                tracking: box.tracking,
                task_id: taskFields.task_id,
                task_box_id: box.task_box_id
            });
        }

        for (let s3_object of box.s3_objects) {
            try {
                if (s3_object.view !== "doc") {
                    const encodedImage = await getS3Obj(s3_object.source);
                    const photo = {
                        dataUrl: encodedImage,
                        s3_id: s3_object.s3_object_id,
                        comment: s3_object.comment,
                        is_preview: s3_object.is_preview,
                        view: s3_object.view
                    };
                    setPhotos((prevPhotos) => [...prevPhotos, photo]);
                } else {
                    const doc = {
                        title: s3_object.title,
                        type: s3_object.type,
                        source: s3_object.source,
                        s3_object_id: s3_object.s3_object_id
                    };
                    setDocuments((prevDocs) => [...prevDocs, doc]);
                }
            } catch (error) {
                console.log('Load photos error ', error);
            }
        }
        setEditBoxOpen(true);
    };

    const handleDeleteBox = async(box) => {
        try {
            if (isAcceptance) {
                await deleteBox(box.box_id);
                setBoxesData((prevBoxes) => prevBoxes.filter((bx) => bx.box_id !== box.box_id))
            } else {
                await deleteBox(box.task_box_id);
                setBoxesData((prevBoxes) => prevBoxes.filter((bx) => bx.task_box_id !== box.task_box_id))
            }
            setAddProductOpen(false);
            setSelectedBoxId('');
        } catch (error) {
            console.log('Delete box error ', error);
        }
    }

    const handleSaveBox = async () => {
        try {
            setEditBoxOpen(false);
            await postBoxesData();

            const boxId = isAcceptance ? boxFields.box_id : boxFields.task_box_id;
            const box = boxesData.find((box) => box.box_id === boxId || box.task_box_id === boxId);

            const products = isAcceptance ? boxData.positions : await fetchItems(boxFields.task_box_id);
            setBoxProducts(products);

            setBoxesData((prevBoxes) => {
                return prevBoxes.map((prevBox) => {
                    if (prevBox.box_id === boxId || prevBox.task_box_id === boxId) {
                        return {
                            ...prevBox,
                            ...boxFields,
                            positions: isAcceptance ? prevBox.positions : products
                        };
                    }
                    return prevBox;
                });
            });

            setBoxData(box);
            setSelectedBoxId(boxId);

            setPhotos([]);
            setProductFields({
                part_number: '',
                date_code: '',
                manufacturer: '',
                cns: '',
                quantity: '',
                weight: '',
                box_id: isAcceptance ? boxFields.box_id : boxFields.task_box_id,
            });

            setAddProductOpen(true);
            setIsProduct(true);
            setIsNewProd(true);
            setSelectedProductId('');
            setBoxUUID(uuidv4());
        } catch (error) {
            console.log('Save box err: ', error);
        }
    }

    const fetchItems = useCallback(async(taskBoxId) => {
        try{
            const response = await fetch(`${baseRoute}/api/v1/taskBoxes/${taskBoxId}/items`,{
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': api_token,
                }
            })
            if (!response.ok) {
                toast("Error fetching products", {
                    position: "top-center",
                    autoClose: 3000,
                    type: "error",
                });
            }
            return await response.json()
        }
        catch (error){
            throw error
        }
    }, [api_token, baseRoute]);
    const handleSearchProduct = (text) => {
        setSearchProductText(text);
    }

    const handleProductClick = (product) => {
        if (isAddProductOpen){
            setIsNewProd(true)
            setSelectedProductId('')
            if (isAcceptance){
                setProductFields({
                    part_number: product.part_number,
                    date_code: product.date_code,
                    manufacturer: product.manufacturer,
                    box_id: boxData.box_id,
                    product_id: product.product_id,
                    cns: '',
                    quantity: '',
                    weight: ''
                })
            } else {
                setProductFields({
                    part_number: product.part_number,
                    date_code: product.date_code,
                    manufacturer: product.manufacturer,
                    task_box_id: product.task_box_id,
                    task_item_id: product.task_item_id,
                    cns: '',
                    quantity: '',
                    weight: ''
                })
            }
            setPhotos([])
        }
        if (isAcceptance){
            setProductId(product.product_id)
        }
    }

    const handleOpenGallery = async (product) => {
        setProdPhotos([])
        if (product.s3_objects.length === 0){
            toast("No photos available", {
                position: "top-center",
                autoClose: 3000,
                type: "error",
            });
            return
        }
        for (let s3_object of product.s3_objects){
            try {
                const encodedImage = await getS3Obj(s3_object.source);
                const photo = {
                    dataUrl: encodedImage,
                    s3_id: s3_object.s3_object_id,
                    comment: s3_object.comment,
                    is_preview: s3_object.is_preview,
                    view: s3_object.view
                }
                setProdPhotos((prevPhotos) => [...prevPhotos, photo])
            } catch (error) {
                console.log('Load photos error ', error)
            }
        }
        setProductId(product.product_id)
        setOpenGallery(true)
    }
    const handleOpenAllGallery = async (product) => {
        try {
            const data = await fetchAllPhotos(product.product_id, product.task_item_id);
            const promises = data.map(async (stock) => {
                const s3_objects = await Promise.all(stock.s3_objects.map(async (s3_object) => {
                    const encodedImage = await getS3Obj(s3_object.source);
                    return { ...s3_object, dataUrl: encodedImage, s3_id: s3_object.s3_object_id };
                }));
                return { ...stock, s3_objects };
            });

            const result = await Promise.all(promises);
            if (result.length === 0){
                toast("No photos available", {
                    position: "top-center",
                    autoClose: 3000,
                    type: "error",
                });
                return
            }
            setAllGalleryData(result);
            setOpenAllGallery(true);
        } catch (error) {
            console.log('Error open all gallery', error);
        }
    };
    const fetchAllPhotos = async(productId, taskItemId) => {
        try{
            const response = await fetch(`${baseRoute}/api/v1/s3objects/all`,{
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': api_token,
                },
                body: JSON.stringify({
                    product_id: productId,
                    task_item_id: taskItemId
                })
            })
            return await response.json()
        }
        catch (error){
            throw error;
        }
    }

    const handleSelectBox = async (event) => {
        const selectedBoxId = event.target.value;
        let selectedBox
        if (isAcceptance){
            selectedBox = boxesData.find((box) => box.box_id === selectedBoxId);
        } else {
            selectedBox = boxesData.find((box) => box.task_box_id === selectedBoxId);
        }

        setSelectedBoxId(selectedBoxId);
        setBoxData(selectedBox)


        const photos = await loadPhotos(selectedBox.s3_objects);
        setBoxPhotos(photos);
        if (!isAcceptance){
            setBoxesData(boxesData.map((box) => box.task_box_id === selectedBox.task_box_id ? { ...box, positions: [] } : box));
        }
        resetProductFields();

        if (isAcceptance){
            setBoxProducts(selectedBox.positions)
        } else {
            const products = await fetchItems(selectedBox.task_box_id);
            setBoxProducts(products);
        }
        setEditBoxOpen(false)
        setAddProductOpen(true)
        setIsProduct(true)
        setSelectedProductId('')
    }

    const resetProductFields = () => {
        if (isAcceptance){
            setProductFields({
                part_number: '',
                date_code: '',
                manufacturer: '',
                cns: '',
                quantity: '',
                weight: '',
                box_id: boxData.box_id,
                product_id: 0
            })
        } else {
            setProductFields({
                part_number: '',
                date_code: '',
                manufacturer: '',
                cns: '',
                quantity: '',
                weight: '',
                task_box_id: boxData.task_box_id,
                task_box_item_id: ''
            });
        }

        setIsNewProd(true);
        setSelectedProductId('');
        setPhotos([]);
    };


    const loadPhotos = async (s3_objects) => {
        const photos = [];
        for (let s3_object of s3_objects) {
            const encodedImage = await getS3Obj(s3_object.source);
            const photo = {
                dataUrl: encodedImage,
                s3_id: s3_object.s3_object_id,
                comment: s3_object.comment,
                is_preview: s3_object.is_preview,
                view: s3_object.view
            };
            photos.push(photo);
        }
        return photos;
    };

    const handleSelectProduct = async (prodData) => {
        if (prodData) {
            setIsNewProd(false);
            setPhotos([]);
            let position
            if (isAcceptance){
                position = boxProducts.find(
                    (position) => position.box_prod_id === prodData.box_prod_id
                );
                setProductFields({
                    part_number: prodData.part_number,
                    date_code: prodData.date_code,
                    manufacturer: prodData.manufacturer,
                    cns: prodData.cns,
                    quantity: `${prodData.quantity}`,
                    weight: prodData.weight,
                    box_id: boxData.box_id,
                    box_prod_id: prodData.box_prod_id
                });
                setProductId(prodData.product_id)
            } else {
                position = boxProducts.find((position) => position.task_box_item_id === prodData.task_box_item_id);
                setProductFields({
                    part_number: prodData.part_number,
                    date_code: prodData.date_code,
                    manufacturer: prodData.manufacturer,
                    cns: prodData.cns,
                    quantity: `${prodData.quantity}`,
                    weight: prodData.weight,
                    task_box_id: boxData.task_box_id,
                    task_box_item_id: prodData.task_box_item_id
                });
            }

            if (position) {
                const photos = await loadPhotos(position.s3_objects);
                setPhotos(photos);
            }

        } else {
            resetProductFields()
        }
    }

    const fetchInternalShipmentsData = useCallback(async () => {
        try{
            const response = await fetch(`${baseRoute}/api/v1/shipments/internal/${productId}`,{
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': api_token,
                }
            })
            return await response.json()
        } catch (error){
            throw error;
        }
    }, [productId, api_token, baseRoute]);

    const fetchExternalShipmentsData = useCallback(async () => {
        try{
            const response = await fetch(`${baseRoute}/api/v1/shipments/external/${productId}`,{
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': api_token,
                }
            })
            return await response.json()
        } catch (error){
            throw error;
        }
    }, [productId, api_token, baseRoute]);

    useEffect(() => {
        if (productId){
            fetchInternalShipmentsData()
                .then((data) => {
                    if (data)  {
                        data.type = 'internal'
                    }
                    setInternalShipments(data)
                })
                .catch((error) => {
                    console.log('Error set shipments data', error)
                })
            fetchExternalShipmentsData()
                .then((data) => {
                    if (data)  {
                        data = data.map(item => ({ ...item, type: 'external' }));
                    }
                    setExternalShipments(data)
                })
                .catch((error) => {
                    console.log('Error set shipments data', error)
                })
        }
    }, [productId, fetchInternalShipmentsData, fetchExternalShipmentsData] )

    const fetchOrderData = useCallback(async () => {
        try{
            const response = await fetch(`${baseRoute}/api/v1/orders/${orderID}`,{
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': api_token,
                }
            })
            if (!response.ok) {
                toast("Error loading order data", {
                    position: "top-center",
                    autoClose: 3000,
                    type: "error",
                });
            }

            return await response.json();
        }
        catch (error){
            throw error;
        }
    }, [orderID, api_token, baseRoute]);

    const fetchBoxesData = useCallback(async () => {
        try{
            let query
            if (isAcceptance) {
                query = `${baseRoute}/api/v1/boxes/${orderData.order_id}`
            } else {
                query = `${baseRoute}/api/v1/tasks/${taskFields.task_id}/boxes`
            }
            const response = await fetch(query,{
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': api_token,
                }
            })
            return await response.json();

        }
        catch (error){
            throw error;
        }
    }, [orderData, api_token, baseRoute, taskFields, isAcceptance]);

    const fetchCheckListData = useCallback(async() => {
        try{
            const queryParams = new URLSearchParams();
            if (searchProductText) {
                queryParams.append('filter', searchProductText)
            }
            let query
            if (isAcceptance) {
                query = `${baseRoute}/api/v1/checklist/${orderData.order_id}?${queryParams.toString()}`
            } else {
                if (taskFields.type === 'internal'){
                    query = `${baseRoute}/api/v1/tasks/internal/${taskFields.task_id}/checklist?${queryParams.toString()}`
                } else {
                    query = `${baseRoute}/api/v1/tasks/external/${taskFields.task_id}/checklist?${queryParams.toString()}`
                }

            }
            const response = await fetch(query,{
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': api_token,
                },
            });
            return await response.json()
        } catch (error){
            throw error;
        }
    }, [orderData, searchProductText, api_token, baseRoute, taskFields, isAcceptance]);

    useEffect(() => {
        fetchOrderData()
            .then((resp) => {
                setOrderData(resp)
            })
            .catch(() => {
                navigate('/Editor/not-found');
            });

    }, [orderID, fetchOrderData, navigate]);

    useEffect(() => {
        if (isAcceptance){
            if (orderData){
                fetchBoxesData()
                    .then((data) => {
                        if (data.length === 0){
                            setBoxesDataLoaded(true)
                            return
                        }
                        data.sort((a,b) => a.box_id - b.box_id)
                        setBoxesData(data)
                        setBoxesDataLoaded(true)
                        setBoxProducts(data[data.length - 1].positions)
                        setBoxData(data[data.length - 1]);
                        setSelectedBoxId(data[data.length - 1].box_id);
                        setAddProductOpen(true)
                        setIsProduct(true)
                    })
                    .catch((error) => {
                        console.log('Error fetching boxes data:', error);
                    });
            }
        } else {
            setBoxesDataLoaded(false)
            setIsNewProd(true)
            setSelectedProductId('')
            setEditBoxOpen(false)
            setAddProductOpen(false)
            setSelectedBoxId('')

            fetchBoxesData()
                .then(async (data) => {
                    if (data.length === 0){
                        setBoxesDataLoaded(true)
                        setAddProductOpen(false)
                        setEditBoxOpen(false)
                        setBoxesData(data)
                        setSelectedBoxId('')
                        return
                    }

                    data.sort((a,b) =>  {
                        if (a.task_box_state === 'shipped' && b.task_box_state !== 'shipped') {
                            return -1;
                        }
                        if (a.task_box_state !== 'shipped' && b.task_box_state === 'shipped') {
                            return 1;
                        }
                        return 0;
                    })

                    setBoxesData(data)
                    let items, taskBoxId
                    if (taskFields.type === 'external') {
                        taskBoxId = taskFields.task_box_id
                        items = await fetchItems(taskBoxId);
                        const selectedBox = data.find(box => box.task_box_id === taskBoxId);
                        setBoxData(selectedBox);
                        setSelectedBoxId(taskBoxId);
                    } else {
                        items = await fetchItems(data[data.length - 1].task_box_id);
                        taskBoxId = data[data.length - 1].task_box_id;
                        setBoxData(data[data.length - 1]);
                        setSelectedBoxId(taskBoxId);
                    }

                    return { items, taskBoxId };
                })
                .then(({items, taskBoxId}) => {
                    setBoxProducts(items)
                    setBoxesData((prevBoxes) => {
                        return prevBoxes.map(box => {
                            if (box.task_box_id === taskBoxId){
                                return {
                                    ...box,
                                    positions: items
                                };
                            }
                            return box;
                        });
                    });
                    setAddProductOpen(true)
                    setIsProduct(true)
                    setSelectedProductId('')
                    setBoxesDataLoaded(true)
                    setCheckListType('shipment')
                })
                .catch((error) => {
                    console.log('Error fetching boxes data:', error);
                });
        }
    },[orderData, fetchBoxesData, fetchItems, isAcceptance, taskFields])

    useEffect(() =>{
        if (orderData){
            fetchCheckListData()
                .then((data) => {
                    if (taskFields.type === 'external') {
                        setExternalCheckList(data);
                    } else {
                        setCheckList(data);
                    }
                })
                .catch((error) => {
                    console.log('Error set check list data', error)
                })
        }
    }, [orderData, searchProductText, fetchCheckListData, taskFields])

    const getS3Obj = async (source) => {
        try {
            const headers = new Headers();
            headers.append('X-Token', xToken);


            const binaryResponse = await fetch(`${apiRoute}${source}`, {
                method: 'GET',
                headers: headers
            });

            const arrayBuffer = await binaryResponse.arrayBuffer();
            const uint8Array = new Uint8Array(arrayBuffer);

            const binaryString = uint8Array.reduce((acc, byte) => acc + String.fromCharCode(byte), '');
            const base64 = btoa(binaryString);
            return "data:image/jpeg;base64," + base64;
        } catch (error) {
            throw error;
        }
    }



    const addBox = async() => {
        try {
            let query, body
            if (isAcceptance) {
                query = `${baseRoute}/api/v1/box`
                body = JSON.stringify({
                    order_id: parseInt(orderData.order_id),
                    idem_key: boxUUID
                })
            } else {
                query = `${baseRoute}/api/v1/taskBox`
                body = JSON.stringify({
                    task_id: taskFields.task_id,
                    idem_key: boxUUID
                })
            }
            const response = await fetch(query,{
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': api_token,
                },
                body: body
            });

            if (!response.ok) {
                if (response.status === 409) {
                    toast("Save this box to add a new one", {
                        position: "top-center",
                        autoClose: 3000,
                        type: "error",
                    });
                } else {
                    toast("Error adding box, try again", {
                        position: "top-center",
                        autoClose: 3000,
                        type: "error",
                    });
                }
            }

            return await response.json();
        }
        catch (error){
            throw error;
        }
    }

    const postBoxesData = async () => {
        try{
            let query, body, method
            if (isAcceptance) {
                query = `${baseRoute}/api/v1/orders/fillbox`
                body = JSON.stringify({
                    box_comment: boxFields.comment,
                    height: parseInt(boxFields.height),
                    width: parseInt(boxFields.width),
                    length: parseInt(boxFields.length),
                    weight:parseInt(boxFields.weight),
                    track_num: boxFields.track_num,
                    invoices: boxFields.invoices,
                    order_id: parseInt(orderData.order_id),
                    box_id: parseInt(boxFields.box_id),
                })
                method = 'POST'
            } else {
                query = `${baseRoute}/api/v1/taskBoxes/${boxFields.task_box_id}`
                body = JSON.stringify({
                    comment: boxFields.comment,
                    height: parseFloat(boxFields.height),
                    width: parseFloat(boxFields.width),
                    length: parseFloat(boxFields.length),
                    weight:parseFloat(boxFields.weight),
                    tracking: boxFields.tracking,
                })
                method = 'PUT'
            }
            const response = await fetch(query,{
                method: method,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': api_token,
                },
                body: body
            });

            if (!response.ok) {
                toast("Error adding box data, try again", {
                    position: "top-center",
                    autoClose: 3000,
                    type: "error",
                });
            }
            return await response.json();
        }
        catch (error){
            throw error;
        }
    }

    const deleteBox = async (box_id) => {
        try{
            let query
            if (isAcceptance) {
                query = `${baseRoute}/api/v1/boxes/${box_id}`
            } else {
                query = `${baseRoute}/api/v1/taskBoxes/${box_id}`
            }
            const response = await fetch(query,{
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': api_token,
                },
            });
            if (!response.ok) {
                toast("Box deleting error, try again", {
                    position: "top-center",
                    autoClose: 3000,
                    type: "error",
                });
            }
            return await response.json();
        }
        catch (error){
            throw error;
        }
    }
    const handleSaveProduct = async () => {
        try {
            const { box_prod_id, task_box_item_id, ...restFields } = productFields;
            let resp;
            if (isNewProd) {
                resp = await addProduct();
                const newProductId = isAcceptance ? resp.box_prod_id : resp.task_box_item_id;
                const idKey = isAcceptance ? 'box_prod_id' : 'task_box_item_id';
                setProductFields({ ...restFields, [idKey]: newProductId });
                setBoxProducts(prevBoxProducts => [...prevBoxProducts, { ...restFields, s3_objects: [], [idKey]: newProductId }]);
            }
            const id = isNewProd ? (isAcceptance ? resp.box_prod_id : resp.task_box_item_id) : (isAcceptance ? box_prod_id : task_box_item_id);
            await updateBoxProduct(id);
            updateProductList();
            resetProductFields();
            const data = await fetchCheckListData();
            if (taskFields.type === 'external') {
                setExternalCheckList(data);
            } else {
                setCheckList(data);
            }
        } catch (error) {
            console.log('Error in handleSaveProduct:', error);
        }
    };

    const updateProductList = () => {
        if (isAcceptance) {
            setBoxProducts((prevBoxProducts) =>
                prevBoxProducts.map((product) =>
                    product.box_prod_id === productFields.box_prod_id
                        ? { ...productFields, s3_objects: product.s3_objects }
                        : product
                )
            );
        } else {
            setBoxProducts((prevBoxProducts) =>
                prevBoxProducts.map((product) =>
                    product.task_box_item_id === productFields.task_box_item_id
                        ? { ...productFields, s3_objects: product.s3_objects }
                        : product
                )
            );
        }

    };
    const addProduct = async () => {
        try {
            let query, body
            if (isAcceptance){
                query = `${baseRoute}/api/v1/box/product`
                body = JSON.stringify({
                    box_id: boxData.box_id,
                    part_number: productFields.part_number,
                    manufacturer: productFields.manufacturer,
                    date_code: productFields.date_code,
                    quantity: productFields.quantity,
                    idem_key: productUUID,
                    product_id: productFields.product_id,
                })
            } else {
                query = `${baseRoute}/api/v1/taskBoxItem`
                body = JSON.stringify({
                    task_box_id: boxData.task_box_id,
                    task_item_id: productFields.task_item_id
                })
            }

            const response = await fetch(query, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': api_token,
                },
                body: body
            });

            if (!response.ok) {
                toast("Error adding product", {
                    position: "top-center",
                    autoClose: 3000,
                    type: "error",
                });
            }
            return await response.json();
        } catch (error) {
            throw error;
        }
    }
    const updateBoxProduct = async (productId) => {
        try{
            let query
            if (isAcceptance){
                query = `${baseRoute}/api/v1/boxes/products/${productId}`
            } else {
                query = `${baseRoute}/api/v1/taskBoxItems/${productId}`
            }
            const response = await fetch(query, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': api_token,
                },
                body: JSON.stringify({
                    part_number: productFields.part_number,
                    date_code: productFields.date_code,
                    manufacturer: productFields.manufacturer,
                    cns: productFields.cns,
                    quantity: parseInt(productFields.quantity),
                    weight: parseInt(productFields.weight)
                })
            })
            if (!response.ok) {
                toast("Error updating product", {
                    position: "top-center",
                    autoClose: 3000,
                    type: "error",
                });
            }
            return await response.json();
        }
        catch (error){
            throw error;
        }
    }

    const removeS3ObjectFromBox = (boxId, s3ObjectId) => {
        setBoxesData((prevBoxes) =>
            prevBoxes.map((box) =>
                box.box_id === boxId
                    ? { ...box, s3_objects: box.s3_objects.filter((obj) => obj.s3_object_id !== s3ObjectId) }
                    : box
            )
        );
    };

    const handleDeletePhotos = async (s3_id) => {
        try {
            await deleteS3Obj(s3_id);
            setPhotos((prevList) => prevList.filter((photo) => photo.s3_id !== s3_id));
            removeS3ObjectFromPosition(boxData.box_id, productFields.box_prod_id, s3_id);
            setBoxPhotos((prevList) => prevList.filter((photo) => photo.s3_id !== s3_id));
            removeS3ObjectFromBox(boxData.box_id, s3_id);
        } catch (error) {
            console.log("Delete Photo error ", error);
        }
    };

    const handleDeleteChecklistPhotos = async (s3_id) => {
        try {
            await deleteS3Obj(s3_id);
            setProdPhotos((prevList) => prevList.filter((photo) => photo.s3_id !== s3_id));
            removeS3ObjectFromChecklistPosition(s3_id, productId);
        } catch (error) {
            console.log("Delete Photo error ", error);
        }
    };

    const removeS3ObjectFromChecklistPosition = (s3_id, productId) => {
        setCheckList((prevCheckList) =>
            prevCheckList.map((product) =>
                product.product_id === productId
                    ? {
                        ...product,
                        s3_objects: product.s3_objects.filter(
                            (s3_object) => s3_object.s3_object_id !== s3_id
                        )
                    }
                    :product
            )
        )
    }

    const removeS3ObjectFromPosition = (boxId, boxProdId, s3ObjectId) => {
        setBoxesData((prevBoxes) =>
            prevBoxes.map((box) =>
                box.box_id === boxId
                    ? {
                        ...box,
                        positions: box.positions.map((position) =>
                            position.box_prod_id === boxProdId
                                ? {
                                    ...position,
                                    s3_objects: position.s3_objects.filter(
                                        (s3_object) => s3_object.s3_object_id !== s3ObjectId
                                    ),
                                }
                                : position
                        ),
                    }
                    : box
            )
        );
    };


    const deleteS3Obj = async(s3_id) => {
        try {

            const response = await fetch(`${baseRoute}/api/v1/s3objects/${s3_id}`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': api_token,
                },
            })
            if (!response.ok) {
                toast("Photo deletion error, try again", {
                    position: "top-center",
                    autoClose: 3000,
                    type: "error",
                });
            }
            return await response.json();
        } catch (error) {
            throw error;
        }
    }
    const setProductPhotos = (newS3Object, box_id, prod_id) => {
        if (isAcceptance) {
            setBoxesData((prevBoxes) => {
                return prevBoxes.map((box) => {
                    if (box.box_id === box_id) {
                        const updatedPositions = box.positions.map((position) => {
                            if (position.box_prod_id === prod_id) {
                                return {
                                    ...position,
                                    s3_objects: [...position.s3_objects, newS3Object]
                                };
                            }
                            return position;
                        });
                        return {
                            ...box,
                            positions: updatedPositions
                        };
                    }
                    return box;
                });
            });
        } else {
            setBoxesData((prevBoxes) => {
                return prevBoxes.map((box) => {
                    if (box.task_box_id === box_id) {
                        const updatedPositions = box.positions.map((position) => {
                            if (position.task_box_item_id === prod_id) {
                                return {
                                    ...position,
                                    s3_objects: [...position.s3_objects, newS3Object]
                                };
                            }
                            return position;
                        });
                        return {
                            ...box,
                            positions: updatedPositions
                        };
                    }
                    return box;
                });
            });
        }
    }

    const DataURIToBlob = (dataURI) => {
        const splitDataURI = dataURI.split(',')[1]
        const byteString = atob(splitDataURI)

        const ia = new Uint8Array(byteString.length)
        for (let i = 0; i < byteString.length; i++)
            ia[i] = byteString.charCodeAt(i)

        return new File([ia],"screenshot.jpg",{ type: "image/jpeg"})
    }
    const handleUploadPhotos = async (selectedFile, selectedView, isPreview, comment) => {
        try {
            const file = DataURIToBlob(selectedFile);
            const data = await postFileSD(file);
            if (isAcceptance) {
                const boxProdId = isProduct && isNewProd ? (await addProduct()).box_prod_id : productFields.box_prod_id;
                if (boxProdId) {
                    await updateBoxProduct(boxProdId);
                    setProductFields({ ...productFields, box_prod_id: boxProdId });

                    const resp = await postPhotoProductQCH(selectedView, data, boxProdId, isPreview, comment);
                    if (resp.s3object_id) {
                        handlePhotoUploadResponse(data, selectedFile, isPreview, comment, selectedView, resp.s3object_id, boxProdId);
                        if (isProduct && isNewProd) setIsNewProd(false);
                    }
                } else {
                    const resp = await postFileBoxQCH(data, selectedView, isPreview, comment);
                    if (resp.s3object_id) {
                        handlePhotoUploadResponse(data, selectedFile, isPreview, comment, selectedView, resp.s3object_id, boxData.box_id);
                    }
                }
            } else {
                const taskBoxItemId = isProduct && isNewProd ? (await addProduct()).task_box_item_id : productFields.task_box_item_id;
                if (taskBoxItemId) {
                    await updateBoxProduct(taskBoxItemId);
                    setProductFields({ ...productFields, task_box_item_id: taskBoxItemId });

                    const resp = await postPhotoProductQCH(selectedView, data, taskBoxItemId, isPreview, comment);
                    if (resp.s3object_id) {
                        handlePhotoUploadResponse(data, selectedFile, isPreview, comment, selectedView, resp.s3object_id, taskBoxItemId);
                        if (isProduct && isNewProd) setIsNewProd(false);
                    }
                } else {
                    const resp = await postFileBoxQCH(data, selectedView, isPreview, comment);
                    if (resp.s3object_id) {
                        handlePhotoUploadResponse(data, selectedFile, isPreview, comment, selectedView, resp.s3object_id, boxData.task_box_id);
                    }
                }
            }
        } catch (error) {
            console.log('Error upload photo', error);
        }
    };

    const handlePhotoUploadResponse = (data, selectedFile, isPreview, comment, selectedView, s3objectId, prodId) => {
        const photoData = { dataUrl: selectedFile, s3_id: s3objectId, comment, is_preview: isPreview, view: selectedView };
        setPhotos((prevPhotos) => [...prevPhotos, photoData]);

        const newS3Object = { source: data.source, title: data.title, type: data.mime, s3_object_id: s3objectId, view: selectedView, is_preview: isPreview, comment };
        if (isProduct) {
            if (isAcceptance) {
                setProductPhotos(newS3Object, boxData.box_id, prodId);
                setBoxProducts((prevBoxProducts) =>
                    isNewProd ?
                        [...prevBoxProducts, { ...productFields, box_prod_id: prodId, s3_objects: [newS3Object] }] :
                        prevBoxProducts.map((product) =>
                            product.box_prod_id === prodId ?
                                { ...product, s3_objects: [...product.s3_objects, newS3Object] } :
                                product
                        )
                );
            } else {
                setProductPhotos(newS3Object, boxData.task_box_id,prodId);
                setBoxProducts((prevBoxProducts) =>
                    isNewProd ?
                        [...prevBoxProducts, { ...productFields, task_box_item_id: prodId, s3_objects: [newS3Object] }] :
                        prevBoxProducts.map((product) =>
                            product.task_box_item_id === prodId ?
                                { ...product, s3_objects: [...product.s3_objects, newS3Object] } :
                                product
                        )
                );
            }
        } else {
            if (isAcceptance) {
                setBoxesS3(newS3Object, boxData.box_id);
            } else {
                setBoxesS3(newS3Object, boxData.task_box_id);
            }
            setBoxPhotos((prevPhotos) => [...prevPhotos, photoData]);
        }
    };

    const setBoxesS3 = (newS3Object, box_id) => {
        setBoxesData((prevBoxes) => {
            return prevBoxes.map((box) => {
                if (box.box_id === box_id) {
                    return {
                        ...box,
                        s3_objects: [...box.s3_objects, newS3Object]
                    };
                }
                return box;
            });
        });
    }

    const handleUploadDocuments = async (selectedFiles) => {
        for (const selectedFile of selectedFiles){
            try {
                const blob = new Blob([selectedFile], { type: selectedFile.type});
                const file = new File([blob], selectedFile.name, { type: blob.type });
                const data = await postFileSD(file);
                const resp = await postFileBoxQCH(data, 'doc');
                const doc = {
                    title: selectedFile.name,
                    source: data.source,
                    s3_object_id: resp.s3object_id,
                    type: selectedFile.type,
                    view: 'doc'
                };
                setDocuments((prevDocs) => [...prevDocs, doc]);
                if (isAcceptance) {
                    setBoxesS3(doc, boxData.box_id);
                } else {
                    setBoxesS3(doc, boxData.task_box_id);
                }
                setSelectedFiles([]);
            } catch (error) {
                console.log('Error uploading documents:', error);
            }
        }
    }

    const handleDeleteDocuments = async(s3_id) => {
        try {
            await deleteS3Obj(s3_id);
            setDocuments((prevList) => prevList.filter((doc) => doc.s3_object_id !== s3_id));
            removeS3ObjectFromBox(boxData.box_id, s3_id);
        } catch (error) {
            console.log("Delete Doc error ", error);
        }
    };

    const handleDeleteBoxProd = async () => {
        try {

            setSelectedProductId('');
            if (isAcceptance) {
                await deleteBoxProd(productFields.box_prod_id);
                setBoxProducts((prevBoxProducts) =>
                    prevBoxProducts.filter((product) => product.box_prod_id !== productFields.box_prod_id)
                );
                setProductFields({
                    part_number: '',
                    date_code: '',
                    manufacturer: '',
                    cns: '',
                    quantity: '',
                    weight: '',
                    box_id: boxData.box_id,
                    box_prod_id: ''
                });
                removePositionFromBox(boxData.box_id, productFields.box_prod_id);
            } else {
                await deleteBoxProd(productFields.task_box_item_id);
                setBoxProducts((prevBoxProducts) =>
                    prevBoxProducts.filter((product) => product.task_box_item_id !== productFields.task_box_item_id)
                );
                setProductFields({
                    part_number: '',
                    date_code: '',
                    manufacturer: '',
                    cns: '',
                    quantity: '',
                    weight: '',
                    task_box_id: boxData.task_box_id,
                    task_box_item_id: ''
                });
            }
            setPhotos([]);
            const data = await fetchCheckListData();
            if (taskFields.type === 'external') {
                setExternalCheckList(data);
            } else {
                setCheckList(data);
            }
            setIsNewProd(true);
        } catch (error) {
            console.log('Error delete Box Product', error);
        }
    };

    const removePositionFromBox = (boxId, boxProdId) => {
        setBoxesData((prevBoxes) =>
            prevBoxes.map((box) =>
                box.box_id === boxId
                    ? {
                        ...box,
                        positions: box.positions.filter(
                            (position) => position.box_prod_id !== boxProdId
                        ),
                    }
                    : box
            )
        );
    };


    const postFileSD = async(file) => {
        try{
            const headers = new Headers();
            headers.append('X-Token', xToken);
            const formData = new FormData()
            formData.append('file',file)

            const response = await fetch(`${apiRoute}/catalog/resources.json`, {
                method: 'POST',
                headers: headers,
                body: formData
            })
            return await response.json()
        }catch(error){
            throw error;
        }
    }

    const postFileBoxQCH = async (data, selectedView, isPreview, comment) => {
        try {
            let body
            if (isAcceptance){
                body = JSON.stringify({
                    title: data.title,
                    type: data.mime,
                    link: data.source,
                    box_id: boxData.box_id,
                    view: selectedView,
                    comment: comment,
                    is_preview: isPreview
                })
            } else {
                body = JSON.stringify({
                    title: data.title,
                    type: data.mime,
                    link: data.source,
                    task_box_id: boxData.task_box_id,
                    view: selectedView,
                    comment: comment,
                    is_preview: isPreview
                })
            }
            const response = await fetch(`${baseRoute}/api/v1/s3object`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': api_token,
                },
                body: body
            });
            return await response.json();
        } catch (error) {
            throw error;
        }
    }
    const postPhotoProductQCH = async(selectedView, data, prod_id, isPreview, comment) => {
        try{
            let body
            if (isAcceptance){
                body = JSON.stringify({
                    title: data.title,
                    type: data.mime,
                    link: data.source,
                    view: selectedView,
                    is_preview: isPreview,
                    comment: comment,
                    box_prod_id: prod_id
                })
            } else {
                body = JSON.stringify({
                    title: data.title,
                    type: data.mime,
                    link: data.source,
                    view: selectedView,
                    is_preview: isPreview,
                    comment: comment,
                    task_box_item_id: prod_id
                })
            }
            const response = await fetch (`${baseRoute}/api/v1/s3object`,{
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': api_token,
                },
                body: body
            })
            if (!response.ok) {
                toast("Error post product photo", {
                    position: "top-center",
                    autoClose: 3000,
                    type: "error",
                });
            }
            return await response.json()
        }
        catch(error){
           throw error;
        }
    }


    const deleteBoxProd = async(prod_id) => {
        try{
            let query
            if (isAcceptance){
                query = `${baseRoute}/api/v1/box/product/${prod_id}`
            } else {
                query = `${baseRoute}/api/v1/taskBoxItems/${prod_id}`
            }
            const response = await fetch (query,{
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': api_token,
                },
            })
            return await response.json()
        }
        catch(error){
            throw error;
        }
    }

    const handleUpdateBoxPhoto = async (s3_id, selectedView, comment, isPreview) => {
        try {
            await updatePhotoQCH(s3_id, selectedView, comment, isPreview);

            setPhotos((prevPhotos) =>
                prevPhotos.map((photo) => {
                    if (photo.s3_id === s3_id) {
                        return {
                            ...photo,
                            view: selectedView,
                            comment: comment,
                            is_preview: isPreview
                        };
                    }
                    return photo;
                })
            );

            setBoxPhotos((prevPhotos) =>
                prevPhotos.map((photo) => {
                    if (photo.s3_id === s3_id) {
                        return {
                            ...photo,
                            view: selectedView,
                            comment: comment,
                            is_preview: isPreview
                        };
                    }
                    return photo;
                })
            );

            setBoxesData((prevBoxes) => {
                return prevBoxes.map((box) => {
                    if (box.box_id === boxData.box_id) {
                        return {
                            ...box,
                            s3_objects: box.s3_objects.map((s3Object) => {
                                if (s3Object.s3_object_id === s3_id) {
                                    return {
                                        ...s3Object,
                                        comment: comment,
                                        view: selectedView,
                                        is_preview: isPreview,
                                    };
                                }
                                return s3Object;
                            }),
                        };
                    }
                    return box;
                });
            });
        } catch (error) {
            console.log('Error updating box photo:', error);
        }
    };
    const handleUpdateProdPhoto = async (s3_id, selectedView, comment, isPreview) => {
        try {
            await updatePhotoQCH(s3_id, selectedView, comment, isPreview);

            setPhotos((prevPhotos) =>
                prevPhotos.map((photo) => {
                    if (photo.s3_id === s3_id) {
                        return {
                            ...photo,
                            view: selectedView,
                            comment: comment,
                            is_preview: isPreview
                        };
                    }
                    return photo;
                })
            );

            setBoxesData((prevBoxes) => {
                return prevBoxes.map((box) => {
                    if (box.box_id === boxData.box_id) {
                        return {
                            ...box,
                            positions: box.positions.map((position) => {
                                if (position.box_prod_id === productFields.box_prod_id) {
                                    return {
                                        ...position,
                                        s3_objects: position.s3_objects.map((s3Object) => {
                                            if (s3Object.s3_object_id === s3_id) {
                                                return {
                                                    ...s3Object,
                                                    comment: comment,
                                                    view: selectedView,
                                                    is_preview: isPreview,
                                                };
                                            }
                                            return s3Object;
                                        }),
                                    };
                                }
                                return position;
                            }),
                        };
                    }
                    return box;
                });
            });
        } catch (error) {
            console.log('Error updating product photo:', error);
        }
    };

    const updatePhotoQCH = async(s3_id, selectedView, comment, isPreview) => {
        try{
            const response = await fetch (`${baseRoute}/api/v1/s3objects/${s3_id}`,{
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': api_token,
                },
                body: JSON.stringify({
                    s3_comment: comment,
                    s3_view: selectedView,
                    is_preview: isPreview,
                })
            })
            return await response.json()
        }
        catch(error){
            throw error;
        }
    }

    const [loggedIn, setLoggedIn] = useState(true);
    const [isLoggedOut, setIsLoggedOut] = useState(false);
    const handleLogout = () => {
        localStorage.removeItem('token');
        localStorage.removeItem('userRoles');
        setLoggedIn(false);
        setIsLoggedOut(true);

    }




    const handleAddToShipment = async (shipment) => {
        console.log('shipment', shipment)
        setIsAcceptance(false)
        setTaskFields({
            task_id: shipment.task_id,
            task_num: shipment.task_num,
            task_date: shipment.task_date,
            task_box_id: shipment.task_box_id,
            type: shipment.type,
        })
        setPhotos([]);
        setProductFields((prevFields) => {
            return {
                part_number: prevFields.part_number,
                date_code: prevFields.date_code,
                manufacturer: prevFields.manufacturer,
                quantity: shipment.shipped_qty,
                weight: prevFields.weight,
                task_item_id: shipment.task_item_id,
                type: shipment.type,
            }
        })
    }
    const handleLabelPrint = async () => {
        try {
            const response = await fetch(`${baseRoute}/api/v1/tasks/${taskFields.task_id}/print-labels`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': api_token,
                },
            });
            if (!response.ok) {
                toast("Error printing label, try again", {
                    position: "top-center",
                    autoClose: 3000,
                    type: "error",
                });
            }
            const data = await response.json();

            openBase64Pdf(data.pdf_bytes);
        } catch (error) {
            throw error;
        }

    }

    function openBase64Pdf(base64String) {

        const binaryString = window.atob(base64String);
        const len = binaryString.length;
        const bytes = new Uint8Array(len);
        for (let i = 0; i < len; i++) {
            bytes[i] = binaryString.charCodeAt(i);
        }

        const blob = new Blob([bytes], { type: 'application/pdf' });

        const url = URL.createObjectURL(blob);

        window.open(url, '_blank');
    }

    const handleShip = async() => {
        setIsOpenHandleShipOptions(true);
    }

    const closeSelectShip = () => {
        setIsOpenHandleShipOptions(false);
    }
    const handleSelectShip = async (isAll) => {
        if (isAll === null){
            setIsOpenHandleShipOptions(false);
            return
        }
        await Ship(isAll);
        setIsOpenHandleShipOptions(false);
    }
    const handleBack = () => {
        setIsAcceptance(true);
        setCheckListType("acceptance")
        setTaskFields({
            task_id: '',
            task_num: '',
            task_date: '',
            task_box_id: '',
            type: '',
        })
        setProductFields({
            part_number: '',
            date_code: '',
            manufacturer: '',
            quantity: '',
            cns: '',
            weight: '',
        })
        setBoxesData([])
        setSelectedBoxId('')
        setBoxData({})
        setAddProductOpen(false)
        setExternalCheckList([])
        setInternalShipments([])
        setExternalShipments([])
        setBoxProducts([])
        setProductId(null)
        setSearchProductText('')
    }
    const Ship = async(isAll) => {
        try {
            const response = await fetch(`${baseRoute}/api/v1/tasks/${taskFields.task_id}/approveShipment`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': api_token,
                },
                body: JSON.stringify({
                    all: isAll
                })
            })
            if (!response.ok) {
                toast("Approve shipment error, try again", {
                    position: "top-center",
                    autoClose: 3000,
                    type: "error",
                });
            }
            toast("Shipment approved", {
                position: "top-center",
                autoClose: 3000,
                type: "info",
            });

            return await response.json()
        } catch (error) {
            throw error
        }
    }


    return(
        <Box>
            <Grid container spacing={6}>
                <Grid item xs={12} sm={12} md={12} >
                    <Header
                        loggedIn={loggedIn}
                        onLogout={handleLogout} 
                        isLoggedOut={isLoggedOut}
                    />
                </Grid>

                <Grid item xs={12} sm={12} md={12}>
                    <Grid container spacing={4} className={classes.overflowVisible}>

                        <Grid item sm={2} md={2} className={classes.sticky}>
                            <Hidden smDown>
                                <CheckList checkList={checkList} onSearchProduct={handleSearchProduct} onProductClick={handleProductClick} handleOpenGallery={handleOpenGallery} type={checkListType} handleOpenAllGallery={handleOpenAllGallery} externalCheckList={externalCheckList} handleLabelsPrint={handleLabelPrint} />
                            </Hidden>
                        </Grid>

                        <Grid item xs={12} sm={7} md={7}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={12} md={12}>
                                    {isAcceptance ? (
                                        orderData ? (
                                            <AddBox orderData={orderData} onButtonClick={handleAddBox}/>
                                        ):null
                                    ) : (
                                        <Grid item xs={12} sm={12} md={12}>
                                            <AddTaskBox taskFields={taskFields} handleAddBox={handleAddBox} handleShip={handleShip}/>
                                        </Grid>
                                    )}
                                </Grid>
                                <Grid item xs={12} sm={12} md={12}>
                                    {isBoxesDataLoaded ? (
                                        <SelectBox boxes={boxesData} selectedBoxId={selectedBoxId} onSelectBox={handleSelectBox}/>
                                    ) : (
                                        <Loader/>
                                    )}
                                </Grid>
                                {selectedBoxId && !isEditBoxOpen && (
                                    <Grid item xs={12} sm={12} md={12}>
                                        <SingleBox
                                            box={boxData}
                                            boxNum={
                                                boxData.task_box_name ? boxData.task_box_name :
                                                    boxesData.findIndex((box) => {
                                                        if (isAcceptance){
                                                            return box.box_id === boxData.box_id
                                                        } else {
                                                            return  box.task_box_id === boxData.task_box_id
                                                        }
                                                    }) + 1
                                            }
                                            boxPhotos={boxPhotos}
                                            onDeleteButtonClick={handleDeleteBox}
                                            onEditButtonClick={handleEditBox}
                                            handleUpdatePhoto={handleUpdateBoxPhoto}
                                            handleDeletePhoto={handleDeletePhotos}/>
                                    </Grid>
                                )}
                                <Grid item xs={12} sm={12} md={12}>
                                    {!isEditBoxOpen ? null : (
                                        <Grid item>
                                            <span style={{ fontSize: '24px' }}>Box# {boxesData.findIndex((box) => {
                                                if (isAcceptance){
                                                    return box.box_id === boxData.box_id
                                                } else {
                                                    return  box.task_box_id === boxData.task_box_id
                                                }
                                            }) + 1}
                                            </span>
                                        </Grid>
                                    )}
                                </Grid>
                                <Grid item xs={12} sm={12} md={12}>
                                    <Collapse in={isEditBoxOpen}>
                                        <BoxFields photos={photos} documents={documents} onBoxFieldsChange={handleBoxFieldsChange} boxFields={boxFields} handleUploadPhotos={handleUploadPhotos} handleSaveBox={handleSaveBox} handleDeletePhotos={handleDeletePhotos} handleUploadDocuments={handleUploadDocuments} handleDeleteDocs={handleDeleteDocuments} selectedFiles={selectedFiles} setSelectedFiles={setSelectedFiles} handleUpdatePhoto={handleUpdateBoxPhoto}/>
                                    </Collapse>
                                </Grid>

                                <Grid item xs={12} sm={12} md={12}>
                                    {!isAddProductOpen ? null : (<span style={{ fontSize: '24px' }}>Add products to Box# {boxesData.findIndex((box) => {
                                        if (isAcceptance){
                                            return box.box_id === boxData.box_id
                                        } else {
                                            return  box.task_box_id === boxData.task_box_id
                                        }
                                    }) + 1}</span>)}
                                </Grid>
                                <Grid item xs={12} sm={12} md={12}>
                                    <Collapse in={isAddProductOpen}>
                                            <Hidden smUp>
                                                <Grid container justifyContent={'center'} spacing={2}>
                                                    <Grid item xs={4} sm={4}>
                                                        <CheckList checkList={checkList} onSearchProduct={handleSearchProduct} onProductClick={handleProductClick} handleOpenGallery={handleOpenGallery} type={checkListType} handleOpenAllGallery={handleOpenAllGallery}  handleLabelsPrint={handleLabelPrint} />
                                                    </Grid>
                                                    <Grid item xs={8} sm={8}>
                                                        {isAddProductOpen && boxesData.length > 0 ? (
                                                            <ProductFields photos={photos} onProductFieldsChange={handleProductFieldsChange} productFields={productFields} handleSaveProduct={handleSaveProduct} boxProducts={boxProducts} handleUploadPhotos={handleUploadPhotos} onSelectProduct={handleSelectProduct} handleDeletePhotos={handleDeletePhotos} handleDeleteBoxProd={handleDeleteBoxProd} selectedProductId={selectedProductId} setSelectedProductId={setSelectedProductId} handleUpdatePhoto={handleUpdateProdPhoto}/>
                                                        ) : null}
                                                    </Grid>
                                                </Grid>
                                            </Hidden>
                                            <Hidden smDown>
                                                {isAddProductOpen && boxesData.length > 0 ? (
                                                    <ProductFields photos={photos} onProductFieldsChange={handleProductFieldsChange} productFields={productFields} handleSaveProduct={handleSaveProduct} boxProducts={boxProducts} handleUploadPhotos={handleUploadPhotos} onSelectProduct={handleSelectProduct} handleDeletePhotos={handleDeletePhotos} handleDeleteBoxProd={handleDeleteBoxProd} selectedProductId={selectedProductId} setSelectedProductId={setSelectedProductId} handleUpdatePhoto={handleUpdateProdPhoto}/>
                                                ) : null}
                                            </Hidden>
                                    </Collapse>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item xs={12} sm={3} md={3} className={classes.sticky}>
                            <Shipments internalShipments={internalShipments} externalShipments={externalShipments} onAddToShipmentBox={handleAddToShipment} handleBack={handleBack} isAcceptance={isAcceptance}/>
                        </Grid>
                    </Grid>
                    <Dialog open={isOpenHandleShipOptions} onClose={closeSelectShip}>
                        <DialogTitle>Ship Options</DialogTitle>
                        <Button variant='outlined' color="success"  onClick={() => handleSelectShip(true)}>
                            Ship all items
                        </Button>
                        <Button variant='outlined' color="success" onClick={() => handleSelectShip(false)}>
                            Ship selected items
                        </Button>
                        <Button variant='outlined' color="success" onClick={() => handleSelectShip(null)}>
                            Cancel
                        </Button>
                    </Dialog>
                    <Dialog open={isOpenGallery} onClose={closeModal}>
                        <DialogContent>
                            <PhotoGallery photosGallery={prodPhotos} handleUpdatePhoto={handleUpdateProdPhoto} handleDelete={handleDeleteChecklistPhotos}/>
                        </DialogContent>
                    </Dialog>
                    <Dialog open={isOpenAllGallery} onClose={closeAllModal}>
                        <DialogContent>
                            <AllPhotosGallery photosGallery={allGalleryData}/>
                        </DialogContent>
                    </Dialog>
                </Grid>
            </Grid>
        </Box>
    )
}
