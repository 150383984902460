import React, {useEffect, useState} from "react";
import {
    Button,
    Card,
    CardContent,
    Checkbox,
    Collapse,
    Container,
    FormControlLabel,
    Grid,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TextField,
    Pagination,
    Dialog,
    DialogTitle,
    DialogContent,
    TableContainer, Typography, InputAdornment, RadioGroup, Radio
} from "@mui/material";

import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import FilterListIcon from "@mui/icons-material/FilterList";
import CloseIcon from "@mui/icons-material/Close";
import SearchPanel from "./SearchPanel";

export default function StockItems({
                                       stockItems,
                                       selectedItems,
                                       setSelectedItems,
                                       handleAddItems,
                                       currentPage,
                                       lastPage,
                                       handlePageChange,
                                       values,
                                       setValues,
                                       selectedOrders,
                                       setSelectedOrders
                                   }) {
    const [expandedOrders, setExpandedOrders] = useState([]);
    const [selectAllPage, setSelectAllPage] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);

    const handleReservedClick = (item) => {
        setSelectedItem(item);
    };

    const handleCloseDialog = () => {
        setSelectedItem(null);
    };

    const toggleExpand = (orderId) => {
        setExpandedOrders((prev) => {
            const isOrderAlreadyExpanded = prev.includes(orderId);
            const newExpandedOrders = isOrderAlreadyExpanded
                ? prev.filter((item) => item !== orderId)
                : [orderId];

            if (!isOrderAlreadyExpanded) {
                setPartNumberFilter('');
                setManufacturerFilter('');
                setPartNumberFilterOpen(false);
                setManufacturerFilterOpen(false);
            }

            return newExpandedOrders;
        });
    };

    useEffect(() => {
        setSelectAllPage(false);
    }, [stockItems]);

    const calculateUpdatedOrders = (selectedItems, stockItems, allOrders) => {
        return stockItems.reduce((acc, order) => {
            const orderItemIDs = order.stock_items.map((item) => item.item_id);
            const allItemsSelected = orderItemIDs.every((id) =>
                selectedItems.some((item) => item.item_id === id)
            );

            if (allItemsSelected && !acc.includes(order.order_id)) {
                return [...acc, order.order_id];
            } else if (!allItemsSelected && acc.includes(order.order_id)) {
                return acc.filter((id) => id !== order.order_id);
            } else {
                return acc;
            }
        }, allOrders);
    };

    const handleCheckboxChange = (item_id, item_type) => {
        setSelectedItems((prevSelectedItems) => {
            const existingItem = prevSelectedItems.find((item) => item.item_id === item_id && item.item_type === item_type);

            if (existingItem) {
                const updatedItems = prevSelectedItems.filter((item) => !(item.item_id === item_id && item.item_type === item_type));
                const updatedOrders = calculateUpdatedOrders(updatedItems, stockItems, selectedOrders);
                setSelectedOrders(updatedOrders);
                return updatedItems;
            } else {
                const stockItem = stockItems
                    .flatMap((order) => order.stock_items)
                    .find((item) => item.item_id === item_id && item.item_type === item_type);

                if (stockItem) {
                    const updatedItems = [
                        ...prevSelectedItems,
                        { item_id, quantity: stockItem.quantity, item_type: stockItem.item_type, stock_id: stockItem.stock_id },
                    ];

                    const updatedOrders = calculateUpdatedOrders(updatedItems, stockItems, selectedOrders);
                    setSelectedOrders(updatedOrders);
                    return updatedItems;
                } else {
                    return prevSelectedItems;
                }
            }
        });
    };

    const handleQuantityChange = (item_id, item_type, quantity) => {
        setSelectedItems((prevSelectedItems) => {
            if (quantity === 0) {
                return prevSelectedItems.filter((item) => !(item.item_id === item_id && item.item_type === item_type));
            } else {
                const existingItemIndex = prevSelectedItems.findIndex((item) => item.item_id === item_id && item.item_type === item_type);
                if (existingItemIndex !== -1) {
                    const updatedItems = [...prevSelectedItems];
                    updatedItems[existingItemIndex] = { ...updatedItems[existingItemIndex], quantity };
                    return updatedItems;
                } else {
                    const stockItem = stockItems.flatMap((order) => order.stock_items).find((item) => item.item_id === item_id && item.item_type === item_type);
                    if (stockItem) {
                        return [...prevSelectedItems, { item_id, quantity, item_type: stockItem.item_type, stock_id: stockItem.stock_id }];
                    } else {
                        return prevSelectedItems;
                    }
                }
            }
        });
    };


    const handleSelectAllPage = () => {
        setSelectAllPage(!selectAllPage);

        const allPageItems = stockItems.flatMap((order) =>
            order.stock_items.map((item) => ({ item_id: item.item_id, item_type: item.item_type }))
        );

        const allPageItemsToAdd = allPageItems
            .filter((item) => !selectedItems.some((selectedItem) => selectedItem.item_id === item.item_id && selectedItem.item_type === item.item_type))
            .map((item) => ({
                item_id: item.item_id,
                quantity: !selectAllPage
                    ? stockItems
                    .flatMap((order) => order.stock_items)
                    .find((stockItem) => stockItem.item_id === item.item_id && stockItem.item_type === item.item_type)?.quantity || 0
                    : 0,
                item_type: item.item_type,
                stock_id: stockItems
                    .flatMap((order) => order.stock_items)
                    .find((stockItem) => stockItem.item_id === item.item_id && stockItem.item_type === item.item_type)?.stock_id || ""
            }));

        setSelectedItems((prevSelectedItems) =>
            selectAllPage
                ? prevSelectedItems.filter((selectedItem) => !allPageItems.some((item) => selectedItem.item_id === item.item_id && selectedItem.item_type === item.item_type))
                : [...prevSelectedItems, ...allPageItemsToAdd]
        );

        const allPageOrderIDs = stockItems.map((order) => order.order_id);
        const selectedOrdersToAdd = allPageOrderIDs
            .filter((orderId) => !selectedOrders.includes(orderId))
            .map((orderId) => orderId);

        setSelectedOrders((prevSelectedOrders) =>
            selectAllPage
                ? prevSelectedOrders.filter((id) => !allPageOrderIDs.includes(id))
                : [...prevSelectedOrders, ...selectedOrdersToAdd]
        );
    };

    const handleSelectOrder = (orderId) => {
        setSelectedOrders((prevSelectedOrders) =>
            prevSelectedOrders.includes(orderId)
                ? prevSelectedOrders.filter((id) => id !== orderId)
                : [...prevSelectedOrders, orderId]
        );

        const orderItems = stockItems.find(
            (order) => order.order_id === orderId
        )?.stock_items || [];

        const orderItemsToAdd = orderItems
            .filter((item) => !selectedItems.some((selectedItem) => selectedItem.item_id === item.item_id && selectedItem.item_type === item.item_type))
            .map((item) => ({
                item_id: item.item_id,
                quantity: item.quantity,
                item_type: item.item_type,
                stock_id: item.stock_id
            }));

        setSelectedItems((prevSelectedItems) =>
            selectedOrders.includes(orderId)
                ? prevSelectedItems.filter((selectedItem) => !orderItems.some((item) => selectedItem.item_id === item.item_id && selectedItem.item_type === item.item_type))
                : [...prevSelectedItems, ...orderItemsToAdd]
        );
    };

    useEffect(() => {
        console.log(selectedItems)
    }, [selectedItems])

    const [partNumberFilter, setPartNumberFilter] = useState('');
    const [manufacturerFilter, setManufacturerFilter] = useState('');
    const [typeFilter, setTypeFilter] = useState('');
    const [isTypeFilterOpen, setTypeFilterOpen] = useState(false);
    const [isPartNumberFilterOpen, setPartNumberFilterOpen] = useState(false);
    const [isManufacturerFilterOpen, setManufacturerFilterOpen] = useState(false);

    const renderStockItems = Array.isArray(stockItems) ? (
        stockItems.map((order, index) => (
            <Grid key={index} item xs={12} sm={12} md={12}>
                <Card sx={{ display: "flex" }}>
                    <Grid sx={{ flexGrow: 1, m: 2 }}>
                        <CardContent>
                            <Grid container alignItems="center">
                                <Grid item xs={1} sm={1} md={1}>
                                    <Checkbox
                                        checked={selectedOrders.includes(order.order_id)}
                                        onChange={() => handleSelectOrder(order.order_id)}
                                    />
                                </Grid>
                                <Grid item xs={10} sm={10} md={10}>
                                    <Grid container>
                                        <Grid item xs={12} sm={12} md={12}>
                                            <Typography variant="body1" style={{ fontStyle: 'italic' }}>
                                                {`PO Num: ${order.po_num}`}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={12}>
                                            <Typography>
                                                {`Count of pos: ${order.count_positions} | `}
                                                {`Real sum: ${order.real_qty_sum}`}{order.virtual_qty_sum > 0 && ` | `}
                                                {order.virtual_qty_sum > 0 && `Virtual sum: ${order.virtual_qty_sum}`}{order.reserved_r_sum > 0 && ` | `}
                                                {order.reserved_r_sum > 0 && `Reserved real : ${order.reserved_r_sum}`}{order.reserved_v_sum > 0 && ` | `}
                                                {order.reserved_v_sum > 0 &&`Reserved virtual : ${order.reserved_v_sum}`}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={1} sm={1} md={1}>
                                    <IconButton
                                        onClick={() => toggleExpand(order.order_id)}
                                        aria-label="toggle-expand"
                                    >
                                        {expandedOrders.includes(order.order_id) ? (
                                            <ExpandLessIcon />
                                        ) : (
                                            <ExpandMoreIcon />
                                        )}
                                    </IconButton>
                                </Grid>


                            </Grid>
                            <Collapse in={expandedOrders.includes(order.order_id)}>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell sx={{ width: '10%' }}>
                                                {isTypeFilterOpen ? (
                                                    <RadioGroup
                                                        row
                                                        name="typeFilter"
                                                        value={typeFilter}
                                                        onChange={(e) => setTypeFilter(e.target.value)}
                                                    >
                                                        <Grid container alignItems="flex-start" direction="row">
                                                            <FormControlLabel value="REAL" control={<Radio />} label="REAL" />
                                                            <FormControlLabel value="VIRTUAL" control={<Radio />} label="VIRTUAL" />
                                                        </Grid>
                                                        <IconButton onClick={() => {
                                                            setTypeFilter('')
                                                            setTypeFilterOpen(false)
                                                        }}>
                                                            <CloseIcon />
                                                        </IconButton>
                                                    </RadioGroup>
                                                ) : (
                                                    <>
                                                        <Grid container alignItems="center" direction="row">
                                                            <Typography>Type</Typography>
                                                            <IconButton onClick={() => setTypeFilterOpen(true)}>
                                                                <FilterListIcon />
                                                            </IconButton>
                                                        </Grid>
                                                    </>
                                                )}
                                            </TableCell>
                                            <TableCell sx={{ width: '20%' }}>
                                                {isPartNumberFilterOpen ? (
                                                    <TextField
                                                        label="PN Filter"
                                                        value={partNumberFilter}
                                                        onChange={(e) => setPartNumberFilter(e.target.value)}
                                                        autoFocus
                                                        InputProps={{
                                                            startAdornment: (
                                                                <InputAdornment position="start">
                                                                    <FilterListIcon />
                                                                </InputAdornment>
                                                            ),
                                                            endAdornment: (
                                                                <InputAdornment position="end">
                                                                    <IconButton onClick={() => {
                                                                        setPartNumberFilter('')
                                                                        setPartNumberFilterOpen(false)
                                                                    }}>
                                                                        <CloseIcon />
                                                                    </IconButton>
                                                                </InputAdornment>
                                                            ),
                                                        }}
                                                    />
                                                ) : (
                                                    <>
                                                        <Grid container alignItems="center" direction="row">
                                                            <Typography>Part Number</Typography>
                                                            <IconButton onClick={() => setPartNumberFilterOpen(true)}>
                                                                <FilterListIcon />
                                                            </IconButton>
                                                        </Grid>
                                                    </>
                                                )}
                                            </TableCell>
                                            <TableCell sx={{ width: '20%' }}>
                                                {isManufacturerFilterOpen ? (
                                                    <TextField
                                                        label="MNF Filter"
                                                        value={manufacturerFilter}
                                                        onChange={(e) => setManufacturerFilter(e.target.value)}
                                                        autoFocus
                                                        InputProps={{
                                                            startAdornment: (
                                                                <InputAdornment position="start">
                                                                    <FilterListIcon />
                                                                </InputAdornment>
                                                            ),
                                                            endAdornment: (
                                                                <InputAdornment position="end">
                                                                    <IconButton onClick={() => {
                                                                        setManufacturerFilter('')
                                                                        setManufacturerFilterOpen(false)
                                                                    }}>
                                                                        <CloseIcon />
                                                                    </IconButton>
                                                                </InputAdornment>
                                                            ),
                                                        }}
                                                    />
                                                ) : (
                                                    <>
                                                        <Grid container alignItems="center" direction="row">
                                                            <Typography>Manufacturer</Typography>
                                                            <IconButton onClick={() => setManufacturerFilterOpen(true)}>
                                                                <FilterListIcon />
                                                            </IconButton>
                                                        </Grid>
                                                    </>
                                                )}
                                            </TableCell>
                                            <TableCell sx={{ width: '10%' }}>Date Code</TableCell>
                                            <TableCell sx={{ width: '10%' }}>Available Quantity</TableCell>
                                            <TableCell sx={{ width: '10%' }}>Reserved Quantity</TableCell>
                                            <TableCell sx={{ width: '10%' }}>Add Quantity</TableCell>
                                            <TableCell sx={{ width: '10%' }}>Select</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {order.stock_items
                                            .filter(item => item.item_type.toLowerCase().includes(typeFilter.toLowerCase()))
                                            .filter(item => item.part_number.toLowerCase().includes(partNumberFilter.toLowerCase()))
                                            .filter(item => item.manufacturer.toLowerCase().includes(manufacturerFilter.toLowerCase()))
                                            .map((item, itemIndex) => (
                                                <React.Fragment key={itemIndex}>
                                                    <TableRow>
                                                        <TableCell>{item.item_type}</TableCell>
                                                        <TableCell>{item.part_number}</TableCell>
                                                        <TableCell>{item.manufacturer}</TableCell>
                                                        <TableCell>{item.date_code}</TableCell>
                                                        <TableCell>{item.quantity}</TableCell>
                                                        <TableCell>
                                                            <Button onClick={() => handleReservedClick(item)}>
                                                                {item.reserved_qty}
                                                            </Button>
                                                        </TableCell>
                                                        <TableCell>
                                                            <TextField
                                                                variant="standard"
                                                                value={selectedItems.find(
                                                                    (selectedItem) => selectedItem.item_id === item.item_id
                                                                        && selectedItem.item_type === item.item_type
                                                                )?.quantity || 0}
                                                                onChange={(e) => {
                                                                    const newQuantity = parseInt(e.target.value, 10) || 0;
                                                                    const clampedQuantity = Math.max(0, Math.min(newQuantity, item.quantity));
                                                                    handleQuantityChange(item.item_id, item.item_type, clampedQuantity);
                                                                }}
                                                            />
                                                        </TableCell>
                                                        <TableCell>
                                                            <Checkbox
                                                                checked={selectedItems.some(
                                                                    (selectedItem) =>
                                                                        selectedItem.item_id === item.item_id
                                                                        && selectedItem.item_type === item.item_type
                                                                )}
                                                                onChange={() => handleCheckboxChange(item.item_id, item.item_type)}
                                                            />
                                                        </TableCell>
                                                    </TableRow>
                                                    {selectedItem && (
                                                        <Dialog open={Boolean(selectedItem)} onClose={handleCloseDialog}>
                                                            <DialogTitle>Reserved quantities of {selectedItem.part_number}:</DialogTitle>
                                                            <DialogContent>
                                                                <TableContainer>
                                                                    <Table>
                                                                        <TableHead>
                                                                            <TableRow>
                                                                                <TableCell>
                                                                                    Task Number
                                                                                </TableCell>
                                                                                <TableCell>
                                                                                    Reserved Quantity
                                                                                </TableCell>
                                                                            </TableRow>
                                                                        </TableHead>
                                                                        <TableBody>
                                                                            {selectedItem.reserved_item &&
                                                                                selectedItem.reserved_item.map((reserved, index) => (
                                                                                    <TableRow key={index}>
                                                                                        <TableCell>{reserved.task_num}</TableCell>
                                                                                        <TableCell>{reserved.quantity}</TableCell>
                                                                                    </TableRow>
                                                                                ))}
                                                                        </TableBody>
                                                                    </Table>
                                                                </TableContainer>
                                                            </DialogContent>
                                                        </Dialog>
                                                    )}
                                                </React.Fragment>
                                            ))}
                                    </TableBody>
                                </Table>
                            </Collapse>
                        </CardContent>
                    </Grid>
                </Card>
            </Grid>
        ))
    ) : (
        <Grid item>No available data</Grid>
    );

    return (
        <Container>
            <Grid container spacing={5}>
                <Grid item xs={12} sm={12} md={12}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={12}>
                            <SearchPanel setValues={setValues} values={values} />
                        </Grid>

                        <Grid item xs={12} sm={12} md={12}>
                            <Collapse in={selectedItems.length > 0}>
                                <Button variant="outlined" color="success" onClick={handleAddItems}>
                                    ADD ITEMS TO TASK
                                </Button>
                            </Collapse>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12}>
                            <Grid container justifyContent="center" alignItems="center">
                                <Pagination
                                    count={lastPage}
                                    page={currentPage}
                                    onChange={handlePageChange}
                                    color="success"
                                />
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} container justifyContent={"flex-start"}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={selectAllPage}
                                        onChange={handleSelectAllPage}
                                    />
                                }
                                label="Select All Orders Items on Page"
                            />
                        </Grid>
                        {renderStockItems}
                    </Grid>
                </Grid>
            </Grid>
        </Container>
    );
}