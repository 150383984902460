import {Button, Grid, Typography} from "@mui/material";
import React from "react";


export default function AddTaskBox({ taskFields, handleAddBox, handleShip }){
    return(
        <Grid container spacing={2}
              alignItems="center"
              justifyContent="space-between"
        >
            <Grid item xs={12} sm={3} md={3}>
                <Typography variant="h5">
                    TASK #{taskFields.task_num}
                </Typography>
            </Grid>

            <Grid item xs={12} sm={3} md={3}>
                <Typography>
                    SHIPMENT DATE: {new Date(taskFields.task_date).toLocaleDateString('en-GB')}
                </Typography>
            </Grid>
            <Grid item xs={12} sm={3} md={3}>
                <Button variant='outlined' color="success" fullWidth onClick={handleAddBox}>ADD BOX</Button>
            </Grid>
            <Grid item xs={12} sm={3} md={3}>
                <Button variant='outlined' color="success" fullWidth onClick={handleShip}>APPROVE SHIPMENT</Button>
            </Grid>
        </Grid>
    )
}