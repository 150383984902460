import React, { useEffect } from 'react';
import { createRoot } from 'react-dom/client';
import { createBrowserRouter, createRoutesFromElements, Route, RouterProvider, useNavigate } from 'react-router-dom';
import StartPage from './components/StartPage';
import CheckOrderPage from './components/CheckOrderPage';
import LogInPage from './components/LogInPage';
import RoleRequestPage from './components/RoleRequestPage';
import ManageRolesPage from './components/ManageRolesPage';
import "./Styles/style.css";
import NotFoundPage from "./components/NotFoundPage";
import {ToastContainer} from "react-toastify";
import OrderDashBoardPage from "./components/OrderDashBoardPage";
import TaskPage from "./components/TaskPage";
import ShipmentEditor from "./components/ShipmentEditor";

const router = createBrowserRouter(
    createRoutesFromElements(
        <>
            <Route path="/manageRoles" element={<PrivateRoute element={<ManageRolesPage />} requiredRoles={["Administrator"]} />} />
            <Route path="/roleRequest" element={<PrivateRoute element={<RoleRequestPage />} requiredRoles={["Default"]} />} />
            <Route path="/auth" element={<LogInPage />} />
            <Route path="/" element={<PrivateRoute element={<StartPage />} requiredRoles={["QCheck engineer", "Review engineer"]} />} />
            <Route path="/Editor/:po_num" element={<PrivateRoute element={<CheckOrderPage />} requiredRoles={["QCheck engineer", "Review engineer"]} />} />
            <Route path="/parcels" element={<PrivateRoute element={<OrderDashBoardPage />} requiredRoles={["Sales manager", "Review engineer"]} />} />
            <Route path="/tasks" element={<PrivateRoute element={<TaskPage />} requiredRoles={["Sales manager", "QCheck engineer", "Review engineer"]} />} />
            <Route path="/tasks/:taskNum/shipmentEditor" element={<PrivateRoute element={<ShipmentEditor />} requiredRoles={["QCheck engineer", "Review engineer"]} />} />
            <Route path="/Editor/not-found" element={<NotFoundPage/>} />
        </>
    )
);


function PrivateRoute({ element, requiredRoles }) {
    const navigate = useNavigate();

    useEffect(() => {
        if (!(!!localStorage.getItem('token'))) {
            navigate('/auth');
        } else if (requiredRoles && requiredRoles.length > 0) {
            const userRoles = JSON.parse(localStorage.getItem('userRoles') || '[]');
            
            if (!hasRequiredRoles(userRoles, requiredRoles)) {
                navigate('/roleRequest');
            }
        }
    }, [navigate, requiredRoles]);

    return element;
}

function hasRequiredRoles(userRoles, requiredRoles) {
    return requiredRoles.some(role => userRoles.includes(role)); 
}

createRoot(document.getElementById('root')).render(
    <>
        <RouterProvider router={router} />
        <ToastContainer/>
    </>

);
