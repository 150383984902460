import {Box, Button, Grid, TextField} from "@mui/material";
import PhotoEditor from "../PhotoEditor";
import React from "react";
import DocumentUploader from "../DocumentUploader";

const TextFieldComponent = ({id, label, value, onChange}) => (
    <Grid item xs={3} sm={3} md={3}>
        <Box>
            <TextField id={id} label={label} InputLabelProps={{ shrink: true }} variant="standard" value={value || ''} onChange={onChange}/>
        </Box>
    </Grid>
);

export default function BoxFields({photos,  documents, onBoxFieldsChange, boxFields, handleUploadPhotos, handleUploadDocuments, handleSaveBox, handleDeletePhotos, handleDeleteDocs, selectedFiles, setSelectedFiles, handleUpdatePhoto}){
    const divider = 5000
    const handleChange = (event) => {
        const {id, value} = event.target;
        onBoxFieldsChange({[id]: value});
    };

    const handleInvoiceChange = (event) => {
        const { value } = event.target;
        const invoiceValues = value.split(",").map((item) => ({ inv_num: item.trim() }));
        onBoxFieldsChange({ invoices: invoiceValues});
    }

    let vol_weight = parseInt(boxFields.length)*parseInt(boxFields.width)*parseInt(boxFields.height)/divider
    if(!vol_weight){
        vol_weight = 0
    }

    const parsedInvoices = boxFields.invoices
        ? boxFields.invoices.map((invoice) => invoice.inv_num).join(", ")
        : "";

    const fields = [
        {id: "invoices", label: "Invoice", value: parsedInvoices, onChange: handleInvoiceChange},
        {id: "track_num", label: "Tracking", value: boxFields.track_num, onChange: handleChange},
        {id: "weight", label: "Weight(grams)", value: boxFields.weight, onChange: handleChange},
        {id: "length", label: "Length", value: boxFields.length, onChange: handleChange},
        {id: "width", label: "Width", value: boxFields.width, onChange: handleChange},
        {id: "height", label: "Height", value: boxFields.height, onChange: handleChange},
        {id: "volume_weight", label: "Volume Weight", value: vol_weight, onChange: handleChange},
        {id: "comment", label: "Comment", value: boxFields.comment, onChange: handleChange}
    ];

    return(
        <Grid container spacing={2}>
            {fields.map((field, index) => (
                <TextFieldComponent key={index} id={field.id} label={field.label} value={field.value} onChange={field.onChange} />
            ))}
            <Grid item xs={12} sm={12} md={12}>
                <PhotoEditor photos={photos} handleUploadPhotos={handleUploadPhotos} handleDeletePhotos={handleDeletePhotos} handleUpdatePhoto={handleUpdatePhoto}/>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
                <DocumentUploader handleUpload={handleUploadDocuments} handleDelete={handleDeleteDocs} documents={documents} selectedFiles={selectedFiles} setSelectedFiles={setSelectedFiles}/>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
                <Grid container justifyContent={'flex-end'}>
                    <Button variant={'outlined'} color="success" onClick={handleSaveBox}>Save Box</Button>
                </Grid>
            </Grid>
        </Grid>
    )
}