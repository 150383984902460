import React, { useState } from 'react';
import { Button, Card, CardActions, CardContent, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, FormControl, Grid, InputLabel, MenuItem, Select, Typography, useTheme, useMediaQuery } from '@mui/material';
import {toast} from "react-toastify";


export default function SingleRequest({ request, sDeleted, setSDeleted }) {

    const baseRoute = process.env.REACT_APP_BASE_ROUTE
    const api_token = localStorage.getItem('token');

    const theme = useTheme();
    const xsScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [isDialogOkOpen, setIsDialogOkOpen] = useState(false);
    const [isDialogDenyOpen, setIsDialogDenyOpen] = useState(false);
    const [role, setRole] = useState('');

    const handleRequest = () => {
        setIsDialogOpen(true);
    }

    const handleChangeRole = (event) => {
        setRole(event.target.value);
    }

    const handleAssignRole = async () => {
        const requestData = {
            request_id: request.request_number,
            user_name: request.user_name,
            role: role,
        }
        try {
            const response = await fetch(`${baseRoute}/api/v1/users/role`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': api_token,
                },
                body: JSON.stringify(requestData),
            });
            if (response.status === 200) {
                setIsDialogOpen(false);
                setIsDialogOkOpen(true);
            } else if (response.status === 400){
                toast("User already have this role", {
                    position: "top-center",
                    autoClose: 3000,
                    type: "info",
                })
                setRole('')
            } else {
                toast(`Error request, try again(err code: ${response.status}`, {
                    position: "top-center",
                    autoClose: 3000,
                    type: "error",
                });
            }
        } catch (error) {
            console.error('Произошла ошибка при попытке выдать роль');
        }
    }

    const handleDenyRequest = () => {
        setIsDialogDenyOpen(true);
    }

    const handleBdeleteRequest = async () => {
        try {
            const response = await fetch(`${baseRoute}/api/v1/requests/${request.request_number}`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': api_token,
                }
            });
            const resp = await response.json();
            console.log(resp);
            if (response.status === 200){
                setSDeleted(!sDeleted)
            } else {
                toast(`Error request, try again(err code: ${response.status}`, {
                    position: "top-center",
                    autoClose: 3000,
                    type: "error",
                });
            }
            setIsDialogDenyOpen(false);
        } catch (error) {
            console.error('Ошибка при попытке удалить заявку');
        }
    }

    return(
        <Card sx={{ display: 'flex' }}>
            <Grid container spacing={0}
                justifyContent="space-between"
                alignItems="center"
            >
                <Grid item xs={12} sm={8} md={8}>
                    <CardContent sx={{ flex: '1 1 auto', maxHeight: 'calc(100% - 48px)', wordWrap: 'break-word' }}>
                        <Typography>
                            <b>Request #:</b> {' '}{request.request_number}{' '}{' '}
                            <b>Requsted role:</b> {' '}{request.requested_role}{' '}{' '}
                            <b>User Name:</b> {' '}{request.user_name}{' '}{' '}
                        </Typography>
                        <Typography>
                            <b>Full Name:</b> {' '}{request.fio}{' '}{' '}
                            <b>employee's position:</b> {' '}{request.title}{' '}{' '}
                        </Typography>
                        <Typography>
                            <b>Contact:</b> {' '}{request.contact_data}{' '}{' '}
                        </Typography>
                    </CardContent>
                </Grid>
                <Grid item xs={12} sm={4} md={4}>
                    <CardActions sx={{ flexGrow: 0 }}>
                        <Grid container spacing={2}
                            direction='row'
                            justifyContent={xsScreen ? 'center' : 'flex-start'}
                            alignItems={xsScreen ? 'center' : 'flex-end'}
                        >
                            <Grid item xs={6} sm={12} md={12}>
                                <Button variant='contained' color="success" fullWidth onClick={handleRequest}>Обработать</Button>
                            </Grid>
                            <Grid item xs={6} sm={12} md={12}>
                                <Button variant='outlined' color="success" fullWidth onClick={handleDenyRequest}>Отклонить</Button>
                            </Grid>
                        </Grid>
                    </CardActions>
                </Grid>
                <Dialog
                    open={isDialogOpen}
                    onClose={() => setIsDialogOpen(false)}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{`Номер заявки: ${request.request_number}`}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Кому: {' '}{request.user_name} <br/>
                            Выдать роль:
                            <FormControl fullWidth>
                                <InputLabel id="select-label">Roles*</InputLabel>
                                <Select
                                    labelId="select-label"
                                    value={role}
                                    label="Roles"
                                    onChange={handleChangeRole}
                                >
                                    <MenuItem value={'QCheck'}>QCheck engineer</MenuItem>
                                    <MenuItem value={'Sales'}>Sales manager</MenuItem>
                                    <MenuItem value={'Review'}>Review engineer</MenuItem>
                                    <MenuItem value={'Administrator'}>Administrator</MenuItem>
                                </Select>
                            </FormControl> 
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setIsDialogOpen(false)} color="success" autoFocus>
                            Отмена
                        </Button>
                        <Button onClick={handleAssignRole} color="success" autoFocus variant='contained'>
                            Подтвердить
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog
                    open={isDialogOkOpen}
                    onClose={() => setIsDialogOkOpen(false)}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{`Номер заявки: ${request.request_number}`}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Роль выдана
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setIsDialogOkOpen(false)} color="success" autoFocus>
                            ОК
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog
                    open={isDialogDenyOpen}
                    onClose={() => setIsDialogDenyOpen(false)}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{`Номер заявки: ${request.request_number}`}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Подтвердите отклонение заявки
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setIsDialogDenyOpen(false)} color="success" autoFocus>
                            Отмена
                        </Button>
                        <Button onClick={handleBdeleteRequest} color="success" autoFocus>
                            Подтвердить 
                        </Button>
                    </DialogActions>
                </Dialog>
            </Grid>
        </Card>
    );
}