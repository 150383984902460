// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.errorField {
    background-color: #fce4e4; 
    border-color: red;
}

.errorText {
    color: red;
}

.warningField {
    background-color: #ffe48d;
    border-color: orangered;
}

.warningText {
    color: orangered;
}`, "",{"version":3,"sources":["webpack://./src/components/LogInPage/styles/style.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,iBAAiB;AACrB;;AAEA;IACI,UAAU;AACd;;AAEA;IACI,yBAAyB;IACzB,uBAAuB;AAC3B;;AAEA;IACI,gBAAgB;AACpB","sourcesContent":[".errorField {\n    background-color: #fce4e4; \n    border-color: red;\n}\n\n.errorText {\n    color: red;\n}\n\n.warningField {\n    background-color: #ffe48d;\n    border-color: orangered;\n}\n\n.warningText {\n    color: orangered;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
