import React, { useState } from 'react';
import {Button, Container, Grid, TextField} from '@mui/material';
import TuneIcon from '@mui/icons-material/Tune';
import FiltersBoard from "./Filters";


export default function SearchPanel({ onSearch, values, setValues}){

    const [on, setOn] = useState(false)    // state for filters button
    const handleSearchTextChange = (event) => {
        const searchText = event.target.value;
        onSearch(searchText);
    }

    return(
        <Grid item xs={12} sm={12} md={12}>
            <Container>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} md={6}>
                        {/* -----------------------------------search-text-field---------------------------------- */}
                        <TextField
                            variant="outlined"
                            label="Enter tracking number"
                            type='search'
                            fullWidth
                            size='small'
                            onChange={handleSearchTextChange}
                        >
                        </TextField>
                    </Grid>
                    <Grid item xs={0} sm={4} md={4}></Grid>
                    {/* -----------------------------------filters-button---------------------------------- */}
                    <Grid item xs={12} sm={2} md={2}>
                        <Button
                            variant='outlined'
                            color="success"
                            id='filtersBtn'
                            startIcon={<TuneIcon />}
                            onClick={() => setOn(p => !p)}
                            fullWidth
                        >
                            Filters
                        </Button>
                    </Grid>
                    {/* -------------------------------------filters------------------------------------- */}
                    <Grid item xs={12} sm={12} md={12}>
                        <Grid container spacing={2}>
                            {on && <FiltersBoard values={values} setValues={setValues}/>}
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
        </Grid>
    );
}