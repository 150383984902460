import { Box, Button, Grid, Paper, TextField } from '@mui/material';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Header from '../Header';
import "./styles/style.css";


function LoginForm({ username, password, setUsername, setPassword, 
                    handleLogin, usernameError, passwordError, 
                    setUsernameError,  setPasswordError, errorText }) {
    return (
        <Grid container 
            spacing={3}
            direction="column"
            justifyContent="center"
            alignItems="center"
        >
            <Grid item xs={12} sm={12} md={12}>
                QCHECK 3
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
                <TextField 
                    variant='filled'
                    label='username'
                    type='login'
                    size='small'
                    value={username}
                    onChange={(e) => {
                        setUsername(e.target.value);
                        setUsernameError(false);
                    }}
                    error={usernameError}
                    //helperText={usernameError && 'Incorrect username'}
                    className={usernameError ? 'errorField' : ''}
                >
                </TextField>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
                <TextField 
                    variant='filled'
                    label='password'
                    type='password'
                    size='small'
                    value={password}
                    onChange={(e) => {
                        setPassword(e.target.value);
                        setPasswordError(false);
                    }}
                    error={passwordError}
                    //helperText={passwordError && 'Incorrect password'}
                    className={passwordError ? 'errorField' : ''}
                >
                </TextField>
            </Grid>
            {errorText && (
                <Grid item xs={12} sm={12} md={12} className="errorText">
                    {errorText}
                </Grid>
            )}
            <Grid item xs={12} sm={12} md={12}>
                <Button variant='contained' color='success' fullWidth onClick={handleLogin}>
                    Log in
                </Button>
            </Grid>
            <Grid item></Grid>
        </Grid>
    )
}
function RegisterForm({ username, password, confirmPassword, setUsername, 
                        setPassword, setConfirmPassword, handleRegister,
                        usernameWarning, passwordWarning, warningText,
                        setUsernameWarning}) {
    const [passwordsMatch, setPasswordsMatch] = useState(false);

    const handlePasswordChange = (e) => {
        const newPass = e.target.value;
        setPassword(newPass);
        setPasswordsMatch(confirmPassword === newPass);
    }

    const handleConfirmPasswordChange = (e) => {
        const confirmPass = e.target.value;
        setConfirmPassword(confirmPass);
        setPasswordsMatch(password === confirmPass);
    }
    return (
        <Grid container 
            spacing={3}
            direction="column"
            justifyContent="center"
            alignItems="center"
        >
            <Grid item xs={12} sm={12} md={12}>
                QCHECK 3 SIGN UP
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
                <TextField 
                    variant='filled'
                    label='username'
                    type='login'
                    size='small'
                    value={username}
                    onChange={(e) => {
                        setUsername(e.target.value);
                        setUsernameWarning(false);
                    }}
                    warning={usernameWarning}
                    className={usernameWarning ? 'warningField' : ''}
                >
                </TextField>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
                <TextField 
                    variant='filled'
                    label='password'
                    type='password'
                    size='small'
                    value={password}
                    onChange={handlePasswordChange}
                    warning={passwordWarning}
                    className={passwordWarning ? 'warningField' : ''}
                >
                </TextField>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
                <TextField 
                    variant='filled'
                    label='confirm password'
                    type='password'
                    size='small'
                    value={confirmPassword}
                    onChange={handleConfirmPasswordChange}
                    warning={passwordWarning}
                    className={passwordWarning ? 'warningField' : ''}
                >
                </TextField>
            </Grid>
            {password !== confirmPassword && (
                <Grid item xs={12} sm={12} md={12} className="errorText">
                    Passwords do not match
                </Grid>
            )}
            {warningText && (
                <Grid item xs={12} sm={12} md={12} className="warningText">
                    {warningText}
                </Grid>
            )}
            <Grid item xs={12} sm={12} md={12}>
                <Button variant='contained' color='success' fullWidth onClick={handleRegister} disabled={!passwordsMatch}>
                    Sign Up
                </Button>
            </Grid>
            <Grid item></Grid>
        </Grid>
    )
}

export default function LogInPage(){
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [loggedIn, setLoggedIn] = useState(false);
    const [showRegisterForm, setShowRegisterForm] = useState(false);

    const baseRoute = process.env.REACT_APP_BASE_ROUTE
    const navigate = useNavigate();

    const [usernameError, setUsernameError] = useState(false);
    const [passwordError, setPasswordError] = useState(false);
    const [usernameWarning, setUsernameWarning] = useState(false);
    const [passwordWarning, setPasswordWarning] = useState(false);

    const [messageText, setMessageText] = useState('');
    const [warningMsText, setWarningMsText] = useState('');

    const handleLogin = async () => {
        try{
            const response = await fetch(`${baseRoute}/api/v1/login`,{
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    username: username, 
                    password: password
                })
            });
            if (response.ok) {
                const authData = await response.json();
                localStorage.setItem('token', authData.token);
                localStorage.setItem('userRoles', JSON.stringify(authData.roles));
                setLoggedIn(true);
                const userRoles = JSON.parse(localStorage.getItem('userRoles') || '[]');
                if (userRoles.includes('Administrator')) {
                    navigate('/manageRoles');
                } else if (userRoles.includes('QCheck engineer') || userRoles.includes('Review engineer')) {
                    navigate('/');
                }else if (userRoles.includes('Sales manager')){
                    navigate('/tasks')
                }else if (userRoles.includes('Default')) {
                    navigate('/roleRequest');
                }
            } else {
                console.log('Login failed');
                setUsernameError(true);
                setPasswordError(true);
                const errorData = await response.json();
                setMessageText(errorData.message);
            }
        } catch (error) {
            console.error('Error during login:', error);
        }
    }
    const handleToggleForm = (type) => {
        if (type === 'login') {
            setShowRegisterForm(false);
        } else if (type === 'sign') {
            setShowRegisterForm(true);
            setPassword('')
            setUsername('')
        }
    }

    const handleRegister = async () => {
        try {
            const response = await fetch(`${baseRoute}/api/v1/register`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    username: username,
                    password: password
                })
            });
            if (response.ok) {
                setShowRegisterForm(false);
                setUsername('');
                setPassword('');
                setConfirmPassword('');
                const answer = await response.json();
                console.log('Successful registration', answer)
            } else {
                console.log('Registration failed');
                setUsernameWarning(true);
                setPasswordWarning(true);
                const warningData = await response.json();
                setWarningMsText(warningData.message);
            }
        } catch (error) {
            console.error('Error during registration:', error);
        }
    }

    return(
        <Box>
            <Grid container 
                spacing={9}                
                justifyContent="center"
                alignItems="center"
            >
                <Grid item xs={12} sm={12} md={12}>
                    <Header 
                        loggedIn={loggedIn}
                        showRegisterForm={showRegisterForm} 
                        onToggleForm={handleToggleForm}
                    />
                </Grid>
                <Grid item xs={12} sm={4} md={4}>
                    <Paper elevation={12}>
                        {showRegisterForm ? (
                            <RegisterForm
                                username={username}
                                password={password}
                                confirmPassword={confirmPassword}
                                setUsername={setUsername}
                                setPassword={setPassword}
                                setConfirmPassword={setConfirmPassword}
                                handleRegister={handleRegister}
                                usernameWarning={usernameWarning}
                                passwordWarning={passwordWarning}
                                setUsernameWarning={setUsernameWarning}
                                warningText={warningMsText}
                            />  
                        ) : (
                            <LoginForm
                                username={username}
                                password={password}
                                setUsername={setUsername}
                                setPassword={setPassword}
                                handleLogin={handleLogin}
                                usernameError={usernameError}
                                passwordError={passwordError}
                                setUsernameError={setUsernameError}
                                setPasswordError={setPasswordError}
                                errorText={messageText}
                            />
                        )}

                    </Paper>
                </Grid>
            </Grid>
        </Box>
    );
}