import React from 'react';
import { Container, Grid } from '@mui/material';
import SingleRequest from './SingleRequest';


export default function RequestsCards({ requests, sDeleted, setSDeleted}){

    const renderCards = Array.isArray(requests) ? (
        requests.map((request, index) =>(
            <Grid key={index} item  xs={12} sm={12} md={12}>
                <SingleRequest request = {request} sDeleted={sDeleted} setSDeleted={setSDeleted}/>
            </Grid>
        ))
    ) : (
        <Grid item>No available data</Grid>
    )
    
    return(
        <Container>
            <Grid container spacing={5}>
                <Grid item xs={12} sm={12} md={12}>
                    <Grid container spacing={2}>
                        {renderCards}
                    </Grid>
                </Grid>
            </Grid>     
        </Container>        
    );
}