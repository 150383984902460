import {
    Box,
    Card,
    CardActionArea,
    CardMedia,
    Dialog,
    Grid,
    IconButton,
    Typography
} from "@mui/material";
import React, {useState} from "react";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";

export default function AllPhotosGallery({photosGallery}){

    const baseRoute = process.env.REACT_APP_API_BASE_ROUTE
    const [photoIndex, setPhotoIndex] = useState(0)
    const [isOpen, setIsOpen] = useState(false)
    const allImages = photosGallery.flatMap(stock => stock.s3_objects.map(photo => `${baseRoute}${photo.source}`));


    return(
        <Grid container spacing={2} align="flex-start">
            {photosGallery.map((stock, index) => (
                <Grid item xs={12} sm={12} md={12} key={index}>
                    <Typography variant="h6">{stock.stock_name}</Typography>
                    <Grid container spacing={2}>
                        {stock.s3_objects.map((photo, index) => {
                            const currentImageIndex = allImages.findIndex(image => image === `${baseRoute}${photo.source}`);
                            return (
                                <Grid item key={index} xs={12} sm={6} md={4}>
                                    <Card>
                                        <CardActionArea>
                                            <CardMedia
                                                component="img"
                                                sx={{
                                                    width: "100%",
                                                    height: "100%",
                                                    objectFit: "cover",
                                                    display: "block",
                                                    margin: "auto",
                                                }}
                                                image={`${baseRoute}${photo.source}`}
                                                onClick={() => {setIsOpen(true); setPhotoIndex(currentImageIndex)}}
                                            />
                                        </CardActionArea>
                                    </Card>
                                </Grid>
                            )
                        })}
                    </Grid>
                </Grid>
            ))}
            <Dialog open={isOpen} onClose={() => setIsOpen(false)} fullWidth={true} maxWidth="md">
                <Box sx={{ position: 'relative', width: '100%', height: '100%' }}>
                    <IconButton
                        sx={{ position: 'absolute', left: 0, top: '50%', transform: 'translateY(-50%)', color: 'white' }}
                        onClick={() => setPhotoIndex((photoIndex + allImages.length - 1) % allImages.length)}
                    >
                        <NavigateBeforeIcon />
                    </IconButton>
                    <img src={allImages[photoIndex]} alt="" style={{width: '100%', height: 'auto'}} />
                    <IconButton
                        sx={{ position: 'absolute', right: 0, top: '50%', transform: 'translateY(-50%)', color: 'white' }}
                        onClick={() => setPhotoIndex((photoIndex + 1) % allImages.length)}
                    >
                        <NavigateNextIcon />
                    </IconButton>
                </Box>
            </Dialog>
        </Grid>
    )
}