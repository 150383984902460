import {Box, Grid, TextField} from "@mui/material";
import React, {useEffect, useState} from "react";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";

export default function DocInfo({ docsFields, onDocsFieldsChange, onChangePriceCoef, selectedTabIndex}){

    const [priceCoef, setPriceCoef] = useState(1)

    const handleChange = (field, value) => {
        if(field === 'priceCoef') {
            setPriceCoef(value)
            onChangePriceCoef(value, docsFields.order_id)
        } else {
            const editedDocsFields = {
                ...docsFields,
                [field]: value,
            };
            onDocsFieldsChange(editedDocsFields, docsFields.order_id);
        }
    };

    useEffect(() => {
        setPriceCoef(1)
    }, [selectedTabIndex])

    return(
        <Grid container align={"center"}>
            <Grid item xs={12} sm={12} md={12}>
                <Box>
                    <Grid container spacing={1} alignItems={"center"}>
                        <Grid item xs={4} sm={4} md={4}>
                            {docsFields.orderId === 0 ? (
                                <TextField
                                    id="po_num"
                                    InputLabelProps={{ shrink: true }}
                                    label="PO Number"
                                    variant="standard"
                                    value={docsFields.po_num}
                                    disabled
                                />
                            ) : (
                                <TextField
                                    id="po_num"
                                    InputLabelProps={{ shrink: true }}
                                    label="PO Number"
                                    variant="standard"
                                    value={docsFields.po_num}
                                    onChange={(e) => handleChange('po_num', e.target.value)}
                                />
                            )}
                        </Grid>
                        <Grid item xs={4} sm={4} md={4}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                    id="po_date"
                                    label={docsFields.po_num !== "BASE DOC" ? "Select PO Date" : "Select POs Dates" }
                                    inputFormat={"DD.MM.YYYY"}
                                    value={docsFields.po_date}
                                    onChange={(value) => handleChange('po_date', value)}
                                    renderInput={(params) => <TextField {...params} />}
                                />
                            </LocalizationProvider>
                        </Grid>
                        <Grid item xs={4} sm={4} md={4}>
                            {docsFields.po_num !== "BASE DOC" ?
                                <TextField
                                    variant="standard"
                                    label="price coef"
                                    value={priceCoef}
                                    onChange={(e) => handleChange('priceCoef', e.target.value)}
                                />
                                : null
                            }
                        </Grid>
                    </Grid>
                </Box>
            </Grid>
        </Grid>
    )
}