import React from 'react';
import {Typography, Grid  } from '@mui/material';
import Products from "../Products";

export default function TaskClients({
                                        externalCheckList,
                                        onProductClick,
                                        handleOpenGallery,
                                        handleOpenAllGallery,
                                        handleMoveClick,
                                        handleLabelClick
                                    }) {
    const renderClients = externalCheckList
        .filter(task => task.positions && task.positions.length > 0)
        .map((task, index) => {
        const clientName = task.task_num ? task.task_num.split('_')[1] : '';
        return (
            <Grid key={index} item xs={12} sm={12} md={12}>

                    <Typography variant="h5" align="center">
                        {clientName}
                    </Typography>

                <Products
                    products={task.positions}
                    onProductClick={onProductClick}
                    handleOpenGallery={handleOpenGallery}
                    handleOpenAllGallery={handleOpenAllGallery}
                    taskType={"external"}
                    handleMoveClick={handleMoveClick}
                    handleLabelClick={handleLabelClick}
                />
            </Grid>
        );
    });

    return (
        <Grid container spacing={2}>
            {renderClients}
        </Grid>
    );
}