import React from 'react';
import '../../Styles/Loader.css'

const Loader = () => {
    return (
        <div className="loader-container">
            <div className="loader-text">Boxes data loading</div>
            <div className="loader"></div>
        </div>
    );
};


export default Loader;