import {Button, Card, CardActions, CardContent, Collapse, Grid, Typography} from "@mui/material";
import React from "react";


export default function Shipments({ internalShipments, externalShipments, onAddToShipmentBox, handleBack, isAcceptance }) {
    return (
        <Grid container spacing={5}>
            <Grid item xs={12} sm={12} md={12} container justifyContent={"center"}>
                <Typography variant="h5">Shipments</Typography>
            </Grid>

            <Grid item xs={12} sm={12} md={12}>
                <Collapse in={!isAcceptance}>
                    <Button variant="text" color="success" onClick={handleBack}>
                        Back to Acceptance
                    </Button>
                </Collapse>
            </Grid>
            {internalShipments && internalShipments.length >0 && (
                <Grid item xs={12} sm={12} md={12}>
                    <Typography variant="h6">Internal Shipments</Typography>
                </Grid>
            )}
            {internalShipments && internalShipments.map((shipment, index) => (
                <Grid key={index} item xs={12} sm={12} md={12} >
                    <Card>
                        <CardContent>
                            <Typography align="center" fontWeight="bold">
                                {shipment.part_number}
                            </Typography>
                            <Typography>
                                TASK NUM {shipment.task_num}
                            </Typography>
                            <Typography>
                                SHIP {shipment.shipped_qty}
                            </Typography>
                            <Typography>
                                {shipment.route_name}
                            </Typography>
                        </CardContent>
                        <CardActions>
                            <Button variant="text" color="success" onClick={() => onAddToShipmentBox(shipment)}>
                                ADD TO SHIPMENT BOX
                            </Button>
                            {/*<Button variant="text" color="success" onClick={() => onLabelsClick(shipment.labels)}>*/}
                            {/*    LABELS*/}
                            {/*</Button>*/}
                        </CardActions>
                    </Card>
                </Grid>
            ))}
            {externalShipments && externalShipments.length > 0 && (
                <Grid item xs={12} sm={12} md={12}>
                    <Typography variant="h6">External Shipments</Typography>
                </Grid>

            )}
            {externalShipments.map((shipment, index) => (
                <Grid key={index} item xs={12} sm={12} md={12} >
                    <Card>
                        <CardContent>
                            <Typography align="center" fontWeight="bold">
                                {shipment.part_number}
                            </Typography>
                            <Typography>
                                TASK NUM {shipment.task_num}
                            </Typography>
                            <Typography>
                                SHIP {shipment.shipped_qty}
                            </Typography>
                            <Typography>
                                TO {shipment.route_name}
                            </Typography>
                        </CardContent>
                        <CardActions>
                            <Button variant="text" color="success" onClick={() => onAddToShipmentBox(shipment)}>
                                ADD TO SHIPMENT BOX
                            </Button>
                        </CardActions>
                    </Card>
                </Grid>
            ))}
        </Grid>
    );
}