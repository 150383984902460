import React from "react";
import { Autocomplete, TextField, Typography } from "@mui/material";

export default function SelectProduct({
                                          products,
                                          onSelectProduct,
                                          selectedProductId,
                                          setSelectedProductId,
                                      }) {
    const handleProductChange = (event, newValue) => {
        if (newValue) {
            setSelectedProductId(newValue.box_prod_id || newValue.task_box_item_id);
            onSelectProduct(newValue);
        } else {
            setSelectedProductId(null);
            onSelectProduct(null);
        }
    };

    return (
        <Autocomplete
            options={products}
            value={products.find((product) => product.box_prod_id === selectedProductId || product.task_box_item_id === selectedProductId) || null}
            getOptionLabel={(product) => (product.part_number ? product.part_number : "empty")}
            onChange={handleProductChange}
            renderInput={(params) => (
                <TextField {...params} label="Select product" variant="outlined" fullWidth />
            )}
            renderOption={(props, product) => (
                <li {...props} key={product.box_prod_id || product.task_box_item_id}>
                    {product.part_number ? product.part_number : <Typography>empty</Typography>}
                </li>
            )}
        />
    );
}