import {Box, Grid, Pagination} from "@mui/material";
import Header from "../Header";
import React, {useCallback, useEffect, useState} from "react";
import SearchPanel from "./SearchPanel";
import TrackingTable from "./TrackingTable";



export default function OrderDashBoardPage(){
    const baseRoute = process.env.REACT_APP_BASE_ROUTE
    const api_token = localStorage.getItem('token');

    const perPage = 20;
    const [searchText, setSearchText] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [values,  setValues] = useState({});
    const [tracksData, setTracksData] = useState([]);



    const handleSearch = (text) => {
        setSearchText(text);
    }
    const handlePageChange = (event, page) =>{
        setCurrentPage(page);
    }

    const fetchDealData = useCallback(async () =>{
        try{
            const response = await fetch(`${baseRoute}/api/v1/parcels/byfilters`,{
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': api_token,
                },
                body: JSON.stringify({
                    tracking_filter: searchText,
                    supplier_filter: values.Supplier,
                    deal_filter: values.Deal,
                    status_filter: values.Status,
                    page: currentPage,
                    per_page: perPage
                })
            });
            const tracksData = await response.json();
            setTracksData(tracksData);
            console.log(tracksData);
        }
        catch (error){
            throw error
        }
    }, [baseRoute, api_token, searchText, values, currentPage]);

    useEffect(() =>{
        fetchDealData().catch((error) => {
            console.error('Error fetching deal data:', error);
        });
    }, [fetchDealData]);

    const [loggedIn, setLoggedIn] = useState(true);
    const [isLoggedOut, setIsLoggedOut] = useState(false);
    const handleLogout = () => {
        localStorage.removeItem('token');
        setLoggedIn(false);
        setIsLoggedOut(true);
    }


    return(
        <Box>
            <Grid container spacing={5}>
                <Grid item xs={12} sm={12} md={12}>
                    <Header
                        loggedIn={loggedIn}
                        onLogout={handleLogout}
                        isLoggedOut={isLoggedOut}
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                    <Grid container spacing={3}>
                        {/* -----------------------------------------Search-panel-------------------------------------------- */}
                        <Grid item xs={12} sm={12} md={12}>
                            <SearchPanel onSearch={handleSearch} values={values} setValues={setValues}/>
                        </Grid>
                        {/* -----------------------------------------Deals-cards-------------------------------------------- */}
                        <Grid item xs={12} sm={12} md={12}>
                            <TrackingTable tracks={tracksData.data}/>
                        </Grid>
                        {/* -----------------------------------------Pagination-------------------------------------------- */}
                        <Grid item xs={12} sm={12} md={12}>
                            <Grid container
                                  justifyContent='center'
                                  alignItems='center'
                            >
                                <Pagination
                                    count={tracksData.last_page}
                                    page={currentPage}
                                    onChange={handlePageChange}
                                    color='success'
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    )
}