import React, {useCallback, useEffect, useState} from 'react';
import { Box, Button, Container, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, FormControl, Grid, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material';
import Header from '../Header';


export default function RoleRequestPage() {

    const baseRoute = process.env.REACT_APP_BASE_ROUTE
    const api_token = localStorage.getItem('token');

    const [role, setRole] = useState('');
    const [fio, setFio] = useState('');
    const [title, setTitle] = useState('');
    const [contactData, setContactData] = useState('');
    const [isFormValid, setIsFormValid] = useState(false);
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [requestNumber, setRequestNumber] = useState(null);

    const checkFormValidity = useCallback(() => {
        return role.trim !== '' && fio.trim() !== '' && title.trim() !== '' && contactData.trim() !== '';
    }, [role, fio, title, contactData]);

    useEffect(() => {
        setIsFormValid(checkFormValidity());
    }, [checkFormValidity]);

    const handleChangeRole = (event) => {
        setRole(event.target.value);
    }

    const handleFio = (event) => {
        setFio(event.target.value);
    }

    const handleTitle = (event) => {
        setTitle(event.target.value);
    }

    const handleContactData = (event) => {
        setContactData(event.target.value);
    }

    const [loggedIn, setLoggedIn] = useState(true);
    const [isLoggedOut, setIsLoggedOut] = useState(false);
    const handleLogout = () => {
        localStorage.removeItem('token');
        localStorage.removeItem('userRoles');
        setLoggedIn(false);
        setIsLoggedOut(true);
    }

    const handleMakeRequest = async () => {
        const requestData = {
            role,
            fio,
            title,
            contact_data: contactData,
        }
        try {
            const response = await fetch(`${baseRoute}/api/v1/roles/request`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': api_token,
                },
                body: JSON.stringify(requestData),
            });
            if (response.status === 200) {
                const responseData = await response.json();
                setRequestNumber(responseData.request_number);
                console.log('Заявка успешно отправлена.');
                setRole('');
                setFio('');
                setTitle('');
                setContactData('');
                openDialog(requestNumber);
            } else {
                console.error('Произошла ошибка при отправке заявки.');
            }
        } catch (error) {
            console.error('Произошла ошибка при отправке заявки:', error);
        }
    }

    const openDialog = () => {
        setIsDialogOpen(true);
    };

    return(
        <Box>
            <Grid container 
                spacing={5} 
                justifyContent="center"
                alignItems="center" 
            >
                <Grid item xs={12} sm={12} md={12}>
                    <Header
                        loggedIn={loggedIn}
                        onLogout={handleLogout}
                        isLoggedOut={isLoggedOut}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                    <Container>
                        <Grid container 
                            spacing={2}
                            justifyContent="center"
                            alignItems="center"
                        >
                            <Grid item xs={12} sm={12} md={12}>
                                <Typography variant="h5" gutterBottom>
                                    Запросить права доступа
                                </Typography>              
                            </Grid>
                            <Grid item xs={12} sm={12} md={12}>
                                <Typography>Выберите роль:</Typography>
                                <FormControl fullWidth>
                                    <InputLabel id="select-label">Roles *</InputLabel>
                                    <Select
                                        labelId="select-label"
                                        value={role}
                                        label="Roles"
                                        onChange={handleChangeRole}
                                    >
                                        <MenuItem value={'QCheck'}>QCheck engineer</MenuItem>
                                        <MenuItem value={'Sales'}>Sales manager</MenuItem>
                                        <MenuItem value={'Review'}>Review engineer</MenuItem>
                                        <MenuItem value={'Administrator'}>Administrator</MenuItem>
                                    </Select>
                                </FormControl>         
                            </Grid>
                            <Grid item xs={12} sm={12} md={12}>
                                <TextField 
                                    variant='outlined'
                                    label='Введите ФИО *'
                                    value={fio}
                                    fullWidth
                                    onChange={handleFio}
                                />      
                            </Grid>
                            <Grid item xs={12} sm={12} md={12}>
                                <TextField 
                                    variant='outlined'
                                    label='Укажите свою должность *'
                                    value={title}
                                    fullWidth
                                    onChange={handleTitle}
                                />      
                            </Grid>
                            <Grid item xs={12} sm={12} md={12}>
                                <TextField 
                                    variant='outlined'
                                    label='Оставте информацию для обратной связи *'
                                    value={contactData}
                                    fullWidth
                                    onChange={handleContactData}
                                    helperText='email/телефон, также можно оставить любой комментарий'
                                />      
                            </Grid>
                            <Grid item xs={12} sm={12} md={12}>
                                <Button variant='contained' color='success' disabled={!isFormValid} onClick={handleMakeRequest}>
                                    Оставить заявку
                                </Button>     
                            </Grid>
                        </Grid>
                    </Container>
                </Grid>
                <Dialog
                    open={isDialogOpen}
                    onClose={() => setIsDialogOpen(false)}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{"Заявка отправлена успешно"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Ваша заявка успешно отправлена. Номер заявки: {requestNumber}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setIsDialogOpen(false)} color="success" autoFocus>
                            OK
                        </Button>
                    </DialogActions>
                </Dialog>
            </Grid>
        </Box>
    );
}