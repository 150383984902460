import React, { useEffect, useRef, useState } from 'react';
import {
    Button,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select, Switch, TextField,
} from '@mui/material';
import Webcam from 'react-webcam';
import PhotoGallery from "../PhotoGallery";
import {green} from "@mui/material/colors";

export default function PhotoEditor({ handleUploadPhotos, photos, handleDeletePhotos, handleUpdatePhoto }) {
    const [devices, setDevices] = useState([]);
    const [selectedDevice, setSelectedDevice] = useState(null);
    const [selectedView, setSelectedView] = useState('');
    const [isPreview, setIsPreview] = useState(0)
    const [comment, setComment] = useState('')

    const webcamRef = useRef(null);
    const fileInputRef = useRef(null);


    const handleChangePreview = () => {
        setIsPreview(isPreview === 0 ? 1 : 0);
    };

    const handleChangeComment = (event) => {
        setComment(event.target.value)
    }

    useEffect(() => {
        navigator.mediaDevices.enumerateDevices().then((mediaDevices) => {
            const videoDevices = mediaDevices.filter((device) => device.kind === 'videoinput');
            setDevices(videoDevices);
            if (videoDevices.length > 1) {
                setSelectedDevice(videoDevices[1]);
            } else {
                setSelectedDevice(videoDevices[0]);
            }
        });
    }, []);

    useEffect(() => {
        if (webcamRef.current && selectedDevice) {
            navigator.mediaDevices.getUserMedia({ video: { deviceId: selectedDevice.deviceId } }).then((stream) => {
                if (webcamRef.current) {
                    webcamRef.current.srcObject = stream;
                }
            }).catch((error) => {
                console.error('Ошибка при получении медиапотока:', error);
            });
        }
    }, [selectedDevice]);

    const handleUpload = () => {
        const canvas = document.createElement('canvas');
        canvas.width = webcamRef.current.video.videoWidth;
        canvas.height = webcamRef.current.video.videoHeight;

        const context = canvas.getContext('2d');
        context.drawImage(webcamRef.current.video, 0, 0, canvas.width, canvas.height);

        const imageSrc = canvas.toDataURL('image/jpeg');
        handleUploadPhotos(imageSrc, selectedView, isPreview, comment);
        setIsPreview(0)
        setComment('')
        setSelectedView('')
    };

    const handleDeviceChange = (event) => {
        const selectedDeviceId = event.target.value;
        const selectedDevice = devices.find((device) => device.deviceId === selectedDeviceId);
        setSelectedDevice(selectedDevice);
    };

    const handleViewChange = (event) => {
        setSelectedView(event.target.value);
    };

    const handleFileChange = () => {
        const files = Array.from(fileInputRef.current.files);
        files.forEach((file) => {
            const reader = new FileReader();
            reader.onload = (event) => {
                const imageSrc = event.target.result;
                handleUploadPhotos(imageSrc, selectedView);
            };
            reader.readAsDataURL(file);
        });
    };

    return (
        <Grid container spacing={2} alignItems={'flex-start'}>

            <Grid item xs={12} sm={12} md={12}>
                <Grid container spacing={2}>

                    <Grid item xs={9} sm={9} md={9}>
                        <FormControl fullWidth>
                            <InputLabel id="select_device">Select device</InputLabel>
                            {devices.length >= 0 && (
                                <Select label='Select device' value={selectedDevice ? selectedDevice.deviceId : ''} onChange={handleDeviceChange}>
                                    {devices.map((device) => (
                                        <MenuItem key={device.deviceId} value={device.deviceId}>
                                            {device.label || `No available devices ${device.deviceId}`}
                                        </MenuItem>
                                    ))}
                                </Select>
                            )}
                        </FormControl>
                    </Grid>

                    <Grid item xs={8} sm={10} md={10}>
                        <FormControl fullWidth>
                            <Webcam
                                audio={false}
                                videoConstraints={{
                                    deviceId: selectedDevice ? selectedDevice.deviceId : undefined,
                                    facingMode: "environment"
                                }}
                                ref={webcamRef}
                                screenshotFormat="image/jpeg"
                                mirrored={false}
                                style={{ maxWidth: '100%', maxHeight: '100%' }}
                            />
                        </FormControl>
                    </Grid>

                    <Grid item xs={4} sm={2} md={2}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={12} md={12}>
                                <FormControl fullWidth>
                                    <InputLabel>Select side</InputLabel>
                                    <Select value={selectedView} onChange={handleViewChange} label={'Select side'}>
                                        <MenuItem value="Pack view">Pack view</MenuItem>
                                        <MenuItem value="Top view">Top view</MenuItem>
                                        <MenuItem value="Bottom view">Bottom view</MenuItem>
                                        <MenuItem value="Side view">Side view</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12}>
                                <Switch
                                    checked={Boolean(isPreview)}
                                    onChange={handleChangePreview}
                                    sx={{
                                        '& .MuiSwitch-thumb': {
                                            backgroundColor: green[400], // Зелёный цвет тумблера
                                        },
                                        '& .MuiSwitch-track': {
                                            backgroundColor: green[300], // Зелёный цвет трека
                                        },
                                    }}
                                />
                                <span>isPreview</span>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12}>
                                <TextField id="comment" multiline label="Comment" InputLabelProps={{ shrink: true }} value={comment} variant="standard" onChange={handleChangeComment}/>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item xs={12} sm={12} md={12}>
                        <Grid container spacing={2}>
                            <Grid item xs={5} sm={4} md={2}>
                                <Button variant='contained' color="success" onClick={handleUpload}>
                                    SHOT
                                </Button>
                            </Grid>
                            <Grid item xs={5} sm={4} md={2}>
                                <Button variant='contained' color="success" onClick={() => fileInputRef.current.click()}>
                                    UPLOAD
                                </Button>
                                <input
                                    type="file"
                                    multiple
                                    style={{ display: 'none' }}
                                    ref={fileInputRef}
                                    onChange={handleFileChange}
                                />
                            </Grid>
                        </Grid>
                    </Grid>

                </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
                <PhotoGallery photosGallery={photos} handleDelete={handleDeletePhotos} handleUpdatePhoto={handleUpdatePhoto}/>
            </Grid>

        </Grid>
    );
};
