import React, { useState } from 'react';
import { Grid, Tooltip } from '@mui/material';
import ErrorDialog from '../../ErrorDialog';

export default function InvoiceNum({ invoices, showFullList }) {
    const [error, setError] = useState(null);
    const [dialogOpen, setDialogOpen] = useState(false);

    const openErrorDialog = (errorMsg) => {
        setError(errorMsg);
        setDialogOpen(true);
    };

    let invoicesString = '';

    if (Array.isArray(invoices)) {
        invoicesString = invoices.map(invoice => invoice.inv_num).join('; ');
    } else {
        openErrorDialog("Invalid invoice data.");
    }

    const showTooltip = invoicesString.length > 50;

    return (
        <Grid container>
            {showTooltip ? (
                <Tooltip title={invoicesString}>
                    <Grid 
                        item
                        className="invoices-list"
                    >
                        {invoicesString.slice(0, 50)}...
                    </Grid>
                </Tooltip>
            ) : (
                <Grid
                    item
                    className="invoices-list"
                >
                    {invoicesString}
                </Grid>
            )}
            <ErrorDialog open={dialogOpen} onClose={() => setDialogOpen(false)} error={error} />
        </Grid>
    );
}