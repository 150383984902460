import {Button, Grid, Typography} from "@mui/material";
import React from "react";


export default function AddBox({orderData, onButtonClick}){


    // const[orderData, setOrderData] = useState({})


    // const fetchOrderData = async () =>{
    //     try{
    //         const response = await fetch('http://localhost:8080/api/v1/orders/{order_id}',{
    //             method: 'GET',
    //             headers: {
    //                 'Content-Type': 'application/json',
    //             },
    //             body: JSON.stringify({
    //                 order_id: orderId
    //             })
    //         });
    //         const order = await response.json();
    //         setOrderData(order);
    //         console.log(order);
    //     }
    //     catch (error){
    //         console.log('Load boxes data error: ', error);
    //     }
    // }
    // let orderData




    return(
        <Grid container spacing={2}
              alignItems="center"
              justifyContent="center"
        >
            <Grid item xs={12} sm={12} md={12}>
                <Typography variant="h5">
                    Order# {orderData.po_num}
                </Typography>
            </Grid>

            <Grid item xs={12} sm={3.33} md={3.33}>
                <Typography>
                    arrival date: {new Date(orderData.arr_date).toLocaleDateString('en-GB')}
                </Typography>
            </Grid>
            <Grid item xs={12} sm={3.33} md={3.33}>
                <Typography>
                    deal date: {new Date(orderData.po_date).toLocaleDateString('en-GB')}
                </Typography>
            </Grid>
            <Grid item xs={12} sm={3.33} md={3.33}>
                <Typography>
                    recipient: {orderData.recipient}
                </Typography>
            </Grid>
            <Grid item xs={12} sm={2} md={2}>
                    <Button variant='outlined' color="success" fullWidth onClick={onButtonClick}>ADD BOX</Button>
            </Grid>
        </Grid>
    )
}