import {
    Grid,
    IconButton,
    ImageList,
    ImageListItem,
    Modal,
    Box,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Switch,
    TextField,
    Button
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import React, { useState} from "react";
import {green} from "@mui/material/colors";

export default function PhotoGallery({ photosGallery, handleDelete, handleUpdatePhoto}) {

    const [modalOpen, setModalOpen] = useState(false);
    const [modalImageUrl, setModalImageUrl] = useState("");
    const [currentPhotoIndex, setCurrentPhotoIndex] = useState(0);
    const maxModalWidth = "90vw";
    const maxModalHeight = "90vh";

    const iconSize = "36px";

    const [selectedView, setSelectedView] = useState("");
    const [isPreview, setIsPreview] = useState(0);
    const [comment, setComment] = useState("");
    const [s3Id, setS3Id] = useState("")

    const openModal = (imageUrl, index) => {
        setComment(photosGallery[index].comment)
        setIsPreview(photosGallery[index].is_preview)
        setSelectedView(photosGallery[index].view)
        setS3Id(photosGallery[index].s3_id)
        setModalImageUrl(imageUrl);
        setCurrentPhotoIndex(index);
        setModalOpen(true);
    };

    const closeModal = () => {
        setModalOpen(false);
        setModalImageUrl("");
    };

    const prevPhoto = () => {
        if (currentPhotoIndex > 0) {
            openModal(photosGallery[currentPhotoIndex - 1].dataUrl, currentPhotoIndex - 1);
        }
    };

    const nextPhoto = () => {
        if (currentPhotoIndex < photosGallery.length - 1) {
            openModal(photosGallery[currentPhotoIndex + 1].dataUrl, currentPhotoIndex + 1);
        }
    };

    const handleModalClick = (event) => {
        if (event.target === event.currentTarget) {
            closeModal();
        }
    };

    const handleViewChange = (event) => {
        setSelectedView(event.target.value);
    };

    const handleChangePreview = () => {
        setIsPreview(isPreview === 0 ? 1 : 0);
    };

    const handleChangeComment = (event) => {
        setComment(event.target.value);
    };

    const handleUpdate = () => {
        handleUpdatePhoto(s3Id, selectedView, comment, isPreview)
    }

    return (
        <Grid container align={"center"}>
            {photosGallery.length > 0 && (
                <ImageList
                    cols={Math.min(photosGallery.length, 4)}
                    sx={{
                        maxWidth: '100vw', maxHeight: '60vh',
                        backgroundColor: 'rgba(255, 255, 255, 1)'
                    }}
                >
                    {photosGallery.length > 0 && (
                        photosGallery.map((photo, index) => (
                            <ImageListItem key={photo.s3_id}>
                                <img
                                    src={photo.dataUrl}
                                    alt={photo.title}
                                    loading="lazy"
                                    style={{ objectFit: "contain", maxWidth: '100vw', maxHeight: '50vh'}}
                                    onClick={() => openModal(photo.dataUrl, index)}
                                />
                                <IconButton
                                    sx={{ backgroundColor: "white" }}
                                    onClick={() => handleDelete(photo.s3_id)}
                                >
                                    <DeleteIcon />
                                </IconButton>
                            </ImageListItem>
                        ))
                    )}
                </ImageList>
            )}
            <Modal
                open={modalOpen}
                onClose={closeModal}
                style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                }}
            >
                <div
                    style={{
                        backgroundColor: "rgba(255, 255, 255, 1)",
                        padding: "20px"
                    }}
                    onClick={handleModalClick}
                >
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            maxWidth: maxModalWidth,
                            maxHeight: maxModalHeight,
                            position: "relative",
                        }}
                    >
                        <div
                            style={{
                                display: "flex",
                                alignItems: "center",
                            }}
                        >
                            <IconButton
                                sx={{
                                    color: "green",
                                    fontSize: iconSize,
                                }}
                                onClick={prevPhoto}
                            >
                                <NavigateBeforeIcon />
                            </IconButton>
                            <img
                                src={modalImageUrl}
                                alt="Full Screen"
                                style={{ maxHeight: "60vh", maxWidth: "60vw", marginBottom: "20px" }}
                            />
                            <IconButton
                                sx={{
                                    color: "green",
                                    fontSize: iconSize,
                                }}
                                onClick={nextPhoto}
                            >
                                <NavigateNextIcon />
                            </IconButton>
                        </div>

                        <Grid container spacing={2}>
                            <Grid item xs={6} sm={6} md={6}>
                                <FormControl fullWidth sx={{ textAlign: 'left' }}>
                                    <InputLabel>Select side</InputLabel>
                                    <Select value={selectedView} onChange={handleViewChange} label={'Select side'}>
                                        <MenuItem value="Pack view">Pack view</MenuItem>
                                        <MenuItem value="Top view">Top view</MenuItem>
                                        <MenuItem value="Bottom view">Bottom view</MenuItem>
                                        <MenuItem value="Side view">Side view</MenuItem>
                                    </Select>
                                </FormControl>

                                <FormControl fullWidth sx={{ textAlign: 'left', marginTop: '10px' }}>
                                    <Switch
                                        checked={Boolean(isPreview)}
                                        onChange={handleChangePreview}
                                        sx={{
                                            '& .MuiSwitch-thumb': {
                                                backgroundColor: green[400],
                                            },
                                            '& .MuiSwitch-track': {
                                                backgroundColor: green[300],
                                            },
                                        }}
                                    />
                                    <span>isPreview</span>
                                </FormControl>

                                <FormControl fullWidth sx={{ textAlign: 'left', marginTop: '10px' }}>
                                    <TextField
                                        id="comment"
                                        multiline
                                        label="Comment"
                                        InputLabelProps={{ shrink: true }}
                                        value={comment}
                                        variant="standard"
                                        onChange={handleChangeComment}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={6} sm={6} md={6} sx={{ display: 'flex', flexDirection: 'column-reverse' }}>
                                <Button variant={'contained'} color={'success'} sx={{ alignSelf: 'flex-end' }} onClick={handleUpdate}>
                                    SAVE
                                </Button>
                            </Grid>
                        </Grid>
                    </Box>
                </div>
            </Modal>
        </Grid>
    );
}
