import React from 'react';
import { Grid, Tooltip } from '@mui/material';
import ErrorDialog from '../../ErrorDialog';

export default function TrackingNum({ trackings }) {
    const [error, setError] = React.useState(null);
    const [dialogOpen, setDialogOpen] = React.useState(false);

    const openErrorDialog = (errorMsg) => {
        setError(errorMsg);
        setDialogOpen(true);
    };

    let trackingString = '';
    if (Array.isArray(trackings)) {
        trackingString = trackings.map(tracking => tracking.track_num).join('; ');
    } else {
        openErrorDialog("Invalid tracking data.");
    }

    const showTooltip = trackingString.length > 50;

    return (
        <Grid container>
            {showTooltip ? (
                <Tooltip title={trackingString}>
                    <Grid
                        item
                        className="tracking-list"
                    >
                        {trackingString.slice(0, 50)}...
                    </Grid>
                </Tooltip>
            ) : (
                <Grid
                    item
                    className="tracking-list"
                >
                    {trackingString}
                </Grid>
            )}
            <ErrorDialog open={dialogOpen} onClose={() => setDialogOpen(false)} error={error} />
        </Grid>
    );
}
