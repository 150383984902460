import {
    Box, Button,
    Card,
    CardContent, Collapse,
    Container,
    Grid, Pagination, Table, TableBody,
    TableCell,
    TableHead,
    TableRow,
    TextField
} from "@mui/material";
import React from "react";
import SearchTaskPanel from "./SearchTaskPanel";


export default function TaskItems({
                                      taskItems,
                                      updatedItems,
                                      setUpdatedItems,
                                      handleRemoveItem,
                                      currentPage,
                                      lastPage,
                                      handlePageChange,
                                      partNumberFilter,
                                      setPartNumberFilter,
                                      handleUpdateItems,
                                  }){

    const handleQuantityChange = (task_item_id, quantity) => {
        setUpdatedItems((prevUpdatedItems) => {
            const existingItemIndex = prevUpdatedItems.findIndex(
                (item) => item.task_item_id === task_item_id
            );

            const taskItem = taskItems.find((item) => item.task_item_id === task_item_id);

            if (taskItem) {
                const maxQuantity = taskItem.quantity + taskItem.avail_qty;
                const clampedQuantity = Math.max(0, Math.min(quantity, maxQuantity));

                if (clampedQuantity !== taskItem.quantity) {
                    if (existingItemIndex !== -1) {
                        return prevUpdatedItems.map((item, index) =>
                            index === existingItemIndex ? { ...item, quantity: clampedQuantity } : item
                        );
                    } else {
                        return [...prevUpdatedItems, { task_item_id, quantity: clampedQuantity }];
                    }
                } else {
                    return prevUpdatedItems.filter((item) => item.task_item_id !== task_item_id);
                }
            } else {
                return prevUpdatedItems;
            }
        });
    };

    const renderTaskItems = Array.isArray(taskItems) ? (
        <Grid item xs={12} sm={12} md={12}>
            <Card sx={{ display: 'flex' }}>
                <Box sx={{ flexGrow: 1,  m: 2 }}>
                    <CardContent>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Type</TableCell>
                                    <TableCell>Part Number</TableCell>
                                    <TableCell>Manufacturer</TableCell>
                                    <TableCell>Date Code</TableCell>
                                    <TableCell>Added Quantity</TableCell>
                                    <TableCell></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {taskItems.map((item, itemIndex) => (
                                    <TableRow key={itemIndex}>
                                        <TableCell >{item.item_type}</TableCell>
                                        <TableCell>{item.part_number}</TableCell>
                                        <TableCell>{item.manufacturer}</TableCell>
                                        <TableCell>{item.date_code}</TableCell>
                                        <TableCell>
                                            <TextField
                                                variant="standard"
                                                value={updatedItems.find(
                                                    (updatedItem) => updatedItem.task_item_id === item.task_item_id
                                                )?.quantity || item.quantity}
                                                onChange={(e) => {
                                                    const newQuantity = parseInt(e.target.value, 10) || 0;
                                                    const clampedQuantity = Math.max(0, Math.min(newQuantity, item.quantity + item.avail_qty));
                                                    handleQuantityChange(item.task_item_id, clampedQuantity);
                                                }}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <Button variant="outlined" color="success" onClick={() => handleRemoveItem(item.task_item_id)}>
                                                Remove From Task
                                            </Button>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </CardContent>
                </Box>
            </Card>
        </Grid>
    ) : (
        <Grid item>No available data</Grid>
    );

    return(
        <Container>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={12}>
                    <SearchTaskPanel partNumberFilter={partNumberFilter} setPartNumberFilter={setPartNumberFilter}/>
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                    <Collapse in={updatedItems.length>0}>
                        <Grid item xs={12} sm={12} md={12}>
                            <Button variant='outlined' color="success" onClick={handleUpdateItems}>
                                UPDATE ITEMS
                            </Button>
                        </Grid>
                    </Collapse>
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                    <Grid container
                          justifyContent='center'
                          alignItems='center'
                    >
                        <Pagination
                            count={lastPage}
                            page={currentPage}
                            onChange={handlePageChange}
                            color='success'
                        />
                    </Grid>
                </Grid>
                {renderTaskItems}
            </Grid>
        </Container>
    )
}