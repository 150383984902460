import {Grid} from "@mui/material";
import SingleProduct from "./SingleProduct";


export default function Products({products, onProductClick, handleOpenGallery, handleOpenAllGallery, taskType, handleMoveClick, handleLabelClick}){
    const renderProducts = Array.isArray(products) ? (
        products.map((product, index) => (
            <Grid item key={index} xs={12} sm={12} md={12}>
                <SingleProduct
                    product = {product}
                    onProductClick={() => onProductClick(product)}
                    handleOpenGallery={() => handleOpenGallery(product)}
                    handleOpenAllGallery={() => handleOpenAllGallery(product)}
                    taskType={taskType}
                    handleMoveClick={() => handleMoveClick(product, "tasks")}
                    handleLabelClick={() => handleLabelClick(product)}
                />
            </Grid>
        ))
    ) : (
        <Grid item>No available data</Grid>
    )


    return(
        <Grid container spacing={1} align={'flex-start'}>
            {renderProducts}
        </Grid>
    )
}