import {
    Box,
    Button,
    Collapse,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    Hidden,
    TextField
} from "@mui/material";
import React, {useCallback, useEffect, useState} from "react";
import PhotoGallery from "../PhotoGallery";
import {v4 as uuidv4} from 'uuid';
import {toast} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CheckList from "../CheckList";
import SelectBox from "../CheckOrderPage/SelectBox";
import Loader from "../CheckOrderPage/Loader";
import SingleBox from "../Boxes/SingleBox";
import BoxFields from "../CheckOrderPage/BoxFields";
import ProductFields from "../CheckOrderPage/ProductFields";
import AddTaskBox from "./AddTaskBox";
import Header from "../Header";
import AllPhotosGallery from "../AllPhotosGallery";
import MoveItem from "../MoveItem";
import LabelInfo from "../LabelInfo";

export default function ShipmentEditor(){

    const baseRoute = process.env.REACT_APP_BASE_ROUTE
    const apiRoute=process.env.REACT_APP_API_BASE_ROUTE
    const xToken=process.env.REACT_APP_X_TOKEN
    const api_token = localStorage.getItem('token');

    const [boxUUID, setBoxUUID] = useState(uuidv4())
    const [taskBoxItemId, setTaskBoxItemId] = useState('')
    const [isOpenGallery, setOpenGallery] = useState(false)
    const [isOpenAllGallery, setOpenAllGallery] = useState(false)
    const [boxesData, setBoxesData] = useState([])
    const [boxNum, setBoxNum] = useState(null)
    const [boxFields, setBoxFields] = useState({})
    const [checkList, setCheckList] = useState([])
    const [externalCheckList, setExternalCheckList] = useState([])
    const [searchProductText, setSearchProductText] = useState('')
    const [clientFilter, setClientFilter] = useState('')
    const [productFields, setProductFields] = useState({})
    const [photos, setPhotos] = useState([])
    const [selectedProductId, setSelectedProductId] = useState('');
    const [boxProducts, setBoxProducts] = useState([])
    const [isNewProd, setIsNewProd] = useState(true)
    const [isProduct, setIsProduct] = useState(false)
    const [documents, setDocuments] = useState([])
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [isEditBoxOpen, setEditBoxOpen] = useState(false)
    const [isAddProductOpen, setAddProductOpen] = useState(false)
    const [boxPhotos, setBoxPhotos] = useState([])
    const [selectedBoxId, setSelectedBoxId] = useState('')
    const [boxData, setBoxData] = useState({})
    const [isBoxesDataLoaded, setBoxesDataLoaded] = useState(false)
    const [prodPhotos, setProdPhotos] = useState([])
    const [allGalleryData, setAllGalleryData] = useState([])
    const [taskFields, setTaskFields] = useState([])

    useEffect(() => {
        setTaskFields((prevTaskFields) => ({
            ...prevTaskFields,
            task_id: localStorage.getItem('task_id'),
            task_num: localStorage.getItem('task_num'),
            task_date: localStorage.getItem('task_date'),
            task_type: localStorage.getItem('task_type')
        }));
    }, [])

    useEffect(() => {
        handleBoxFieldsChange(boxFields)
    }, [boxFields])
    const handleBoxFieldsChange = updatedData => {
        setBoxFields((prevFormData) => ({
            ...prevFormData,
            ...updatedData
        }))
    }

    const closeModal = () => {
        setOpenGallery(false);
        setProdPhotos([])
    };

    const closeAllModal = () => {
        setOpenAllGallery(false);
        setAllGalleryData([])
    };

    useEffect(() => {
        handleProductFieldsChange(productFields)
    }, [productFields])
    const handleProductFieldsChange = updatedData => {
        setProductFields((prevFormData) => ({
            ...prevFormData,
            ...updatedData
        }))
    }
    const handleAddBox = async () => {
        setIsProduct(false);

        try {
            const data = await addBox()

            setPhotos([]);
            setBoxFields({
                comment: '',
                height: '',
                width: '',
                length: '',
                weight: '',
                tracking: '',
                task_box_name: 'Enter box name',
                task_id: taskFields.task_id,
                task_box_id: data.task_box_id,
            });
            const newBox = {
                weight: '',
                width: '',
                height: '',
                length: '',
                comment: '',
                tracking: '',
                task_box_name: 'Enter box name',
                task_box_id: data.task_box_id,
                positions: [],
                s3_objects: []
            };
            const updatedBoxData = [...boxesData, newBox];
            setSelectedBoxId('');
            setBoxesData(updatedBoxData);
            setAddProductOpen(false);
            setEditBoxOpen(true);
        } catch (error) {
            console.log('AddBox Error', error);
        }
    };

    const loadBoxPhotos = async (box) => {
        const photos = [];
        for (let s3_object of box.s3_objects) {
            if (s3_object.view !== "doc") {
                const encodedImage = await getS3Obj(s3_object.source);
                const photo = {
                    dataUrl: encodedImage,
                    s3_id: s3_object.s3_object_id,
                    comment: s3_object.comment,
                    is_preview: s3_object.is_preview,
                    view: s3_object.view
                };
                photos.push(photo);
            }
        }
        return photos;
    };

    const loadBoxDocuments = (box) => {
        const documents = [];
        for (let s3_object of box.s3_objects) {
            if (s3_object.view === "doc") {
                const doc = {
                    title: s3_object.title,
                    type: s3_object.type,
                    source: s3_object.source,
                    s3_object_id: s3_object.s3_object_id
                };
                documents.push(doc);
            }
        }
        return documents;
    };

    const handleEditBox = async () => {
        setIsProduct(false);
        setPhotos([]);
        setDocuments([]);
        setAddProductOpen(false);
        setBoxProducts([]);

        const box = boxesData[boxNum - 1];
        setBoxFields({
            comment: box.comment,
            height: box.height,
            width: box.width,
            length: box.length,
            weight: box.weight,
            tracking: box.tracking,
            task_box_name: box.task_box_name,
            task_id: taskFields.task_id,
            task_box_id: box.task_box_id
        });

        const photos = await loadBoxPhotos(box);
        setPhotos(photos);

        const documents = loadBoxDocuments(box);
        setDocuments(documents);

        setEditBoxOpen(true);
    };

    const handleDeleteBox = async() => {
        deleteBox()
            .then(() => {
                setBoxesData((prevBoxes) => prevBoxes.filter((_, index) => index !== boxNum - 1))
                setAddProductOpen(false)
                setSelectedBoxId('')
                setBoxNum(null)
            })
            .catch((error) => {
                console.log('Delete box error ', error)
            })
    }

    const fetchItems = async(taskBoxId) => {
        try{
            const response = await fetch(`${baseRoute}/api/v1/taskBoxes/${taskBoxId}/items`,{
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': api_token,
                }
            })
            if (!response.ok) {
                toast("Error fetching products", {
                    position: "top-center",
                    autoClose: 3000,
                    type: "error",
                });
            }
            return await response.json()
        }
        catch (error){
            throw error
        }
    }
    const handleSaveBox = async () => {
        try {
            setEditBoxOpen(false);

            await updateBox();

            setBoxesData((prevBoxes) => {
                return prevBoxes.map((box) => {
                    if (box.task_box_id === boxFields.task_box_id) {
                        return {
                            ...box,
                            comment: boxFields.comment,
                            height: boxFields.height,
                            invoices: boxFields.invoices,
                            length: boxFields.length,
                            tracking: boxFields.tracking,
                            weight: boxFields.weight,
                            width: boxFields.width,
                            task_box_name: boxFields.task_box_name,
                            positions: []
                        };
                    }
                    return box;
                });
            });



            setPhotos([]);
            const products = await fetchItems(boxFields.task_box_id);
            setBoxProducts(products)

            setIsNewProd(true);
            setProductFields({
                part_number: '',
                date_code: '',
                manufacturer: '',
                cns: '',
                quantity: '',
                weight: '',
                task_box_id: boxFields.task_box_id,
            });

            setAddProductOpen(true);
            setBoxData(
                {
                    ...boxData,
                    comment: boxFields.comment,
                    height: boxFields.height,
                    width: boxFields.width,
                    length: boxFields.length,
                    weight: boxFields.weight,
                    tracking: boxFields.tracking,
                    task_box_name: boxFields.task_box_name
                }
            )
            setSelectedBoxId(boxFields.task_box_id)
            setIsProduct(true);
            setSelectedProductId('');
            setBoxUUID(uuidv4());
        } catch (error) {
            console.log('Save box err: ', error);
        }
    }

    const handleSearchProduct = (text) => {
        setSearchProductText(text);
    }

    const handleProductClick = (product) => {
        if (isAddProductOpen){
            setIsNewProd(true)
            setSelectedProductId('')
            setProductFields({
                part_number: product.part_number,
                date_code: product.date_code,
                manufacturer: product.manufacturer,
                task_box_id: boxesData[boxNum-1].task_box_id,
                task_item_id: product.task_item_id,
                cns: '',
                quantity: '',
                weight: ''
            })
            setPhotos([])
        }
    }

    const handleOpenGallery = async (product) => {
        setProdPhotos([])
        if (product.s3_objects.length === 0){
            toast("No photos available", {
                position: "top-center",
                autoClose: 3000,
                type: "error",
            });
            return
        }
        for (let s3_object of product.s3_objects){
            try {
                const encodedImage = await getS3Obj(s3_object.source);
                const photo = {
                    dataUrl: encodedImage,
                    s3_id: s3_object.s3_object_id,
                    comment: s3_object.comment,
                    is_preview: s3_object.is_preview,
                    view: s3_object.view
                }
                setProdPhotos((prevPhotos) => [...prevPhotos, photo])
            } catch (error) {
                console.log('Load photos error ', error)
            }
        }
        setTaskBoxItemId(product.task_box_item_id)
        setOpenGallery(true)
    }

    const handleOpenAllGallery = async (product) => {
        try {
            const data = await fetchAllPhotos(product.task_item_id);
            const promises = data.map(async (stock) => {
                const s3_objects = await Promise.all(stock.s3_objects.map(async (s3_object) => {
                    const encodedImage = await getS3Obj(s3_object.source);
                    return { ...s3_object, dataUrl: encodedImage, s3_id: s3_object.s3_object_id };
                }));
                return { ...stock, s3_objects };
            });

            const result = await Promise.all(promises);
            if (result.length === 0) {
                toast("No photos available", {
                    position: "top-center",
                    autoClose: 3000,
                    type: "error",
                });
                return;
            }
            setAllGalleryData(result);
            setOpenAllGallery(true);
        } catch (error) {
            console.log('Error open all gallery', error);
        }
    };

    const fetchAllPhotos = async(taskItemId) => {
        try{
            const response = await fetch(`${baseRoute}/api/v1/s3objects/all`,{
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': api_token,
                },
                body: JSON.stringify({
                    task_item_id: taskItemId
                })
            })
            return await response.json()
        }
        catch (error){
            throw error
        }
    }

    const loadPhotos = async (s3_objects) => {
        const photos = [];
        for (let s3_object of s3_objects) {
            const encodedImage = await getS3Obj(s3_object.source);
            const photo = {
                dataUrl: encodedImage,
                s3_id: s3_object.s3_object_id,
                comment: s3_object.comment,
                is_preview: s3_object.is_preview,
                view: s3_object.view
            };
            photos.push(photo);
        }
        return photos;
    };

    const resetProductFields = () => {
        const task_box_id = boxesData[boxNum-1] ? boxesData[boxNum-1].task_box_id : '';
        setProductFields({
            part_number: '',
            date_code: '',
            manufacturer: '',
            cns: '',
            quantity: '',
            weight: '',
            task_box_id: task_box_id,
            task_box_item_id: ''
        });
        setIsNewProd(true);
        setSelectedProductId('');
        setPhotos([]);
    };

    const handleSelectBox = async (event) => {
        try {
            const selectedBoxId = event.target.value;
            const selectedBox = boxesData.find((box) => box.task_box_id === selectedBoxId);

            if (!selectedBox) {
                console.log('Box not found');
            }

            setSelectedBoxId(selectedBoxId);
            setBoxNum(boxesData.indexOf(selectedBox) + 1);
            setPhotos([]);

            const photos = await loadPhotos(selectedBox.s3_objects);
            setBoxPhotos(photos);

            setBoxesData(boxesData.map((box) => box.task_box_id === selectedBox.task_box_id ? { ...box, positions: [] } : box));
            resetProductFields();

            const products = await fetchItems(selectedBox.task_box_id);
            setBoxProducts(products);
            if (selectedBox.task_box_name){
                setClientFilter(selectedBox.task_box_name)
            }

            setAddProductOpen(true);
            setEditBoxOpen(false);
            setIsProduct(true);
            setSelectedProductId('');
            setBoxData(selectedBox);
        } catch (error) {
            console.error('Error selecting box:', error);
        }
    };

    const handleSelectProduct = async (prodData) => {
        if (prodData) {
            setIsNewProd(false);
            setPhotos([]);
            const position = boxProducts.find((position) => position.task_box_item_id === prodData.task_box_item_id);
            if (position) {
                const photos = await loadPhotos(position.s3_objects);
                setPhotos(photos);
            }
            setProductFields({
                part_number: prodData.part_number,
                date_code: prodData.date_code,
                manufacturer: prodData.manufacturer,
                cns: prodData.cns,
                quantity: `${prodData.quantity}`,
                weight: prodData.weight,
                task_box_id: boxData.task_box_id,
                task_box_item_id: prodData.task_box_item_id
            });
            console.log(boxProducts)
        } else {
            resetProductFields();
        }
    };


    const fetchBoxesData = useCallback(async () => {
        try {
            const response = await fetch(`${baseRoute}/api/v1/tasks/${taskFields.task_id}/boxes`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': api_token,
                }
            });
            const data = await response.json();
            data.sort((a, b) => a.task_box_id - b.task_box_id);
            return data;
        } catch (error) {
            throw error;
        }
    }, [taskFields, api_token, baseRoute]);

    useEffect(() => {
        if (taskFields.task_id) {
            fetchBoxesData()
                .then((data) => {
                    setBoxesData(data);
                    setBoxesDataLoaded(true);
                })
                .catch((error) => {
                    console.log('Error fetching boxes data:', error);
                });
        }
    }, [fetchBoxesData, taskFields.task_id]);


    const fetchCheckListData = useCallback(async() => {
        try{
            const queryParams = new URLSearchParams();
            if (searchProductText) {
                queryParams.append('pnFilter', searchProductText)
            }
            if (clientFilter) {
                queryParams.append('clientFilter', clientFilter)
            }
            let url
            if (localStorage.getItem("task_type") === 'external'){
                url = `${baseRoute}/api/v1/tasks/external/${taskFields.task_id}/checklist?${queryParams.toString()}`
            } else {
                url = `${baseRoute}/api/v1/tasks/internal/${taskFields.task_id}/checklist?${queryParams.toString()}`
            }
            const response = await fetch(url,{
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': api_token,
                },
            });
            return await response.json()
        } catch (error){
            throw error
        }
    }, [taskFields, searchProductText, clientFilter, api_token, baseRoute]);

    useEffect(() =>{
        if (taskFields.task_id){
            fetchCheckListData()
                .then((data) => {
                    if (taskFields.task_type === 'external'){
                        setExternalCheckList(data)
                    } else {
                        setCheckList(data)
                    }
                })
                .catch((error) => {
                    console.log('Error fetch check list data', error)
                })
        }
    }, [taskFields.task_id, taskFields.task_type, searchProductText, clientFilter, fetchCheckListData, boxData])

    const getS3Obj = async (source) => {
        try {
            const headers = new Headers();
            headers.append('X-Token', xToken);


            const binaryResponse = await fetch(`${apiRoute}${source}`, {
                method: 'GET',
                headers: headers
            });

            const arrayBuffer = await binaryResponse.arrayBuffer();
            const uint8Array = new Uint8Array(arrayBuffer);

            const binaryString = uint8Array.reduce((acc, byte) => acc + String.fromCharCode(byte), '');
            const base64 = btoa(binaryString);
            return "data:image/jpeg;base64," + base64;
        } catch (error) {
            console.log('Error loading photo from API:', error);
        }
    }



    const addBox = async() => {
        try {
            const response = await fetch(`${baseRoute}/api/v1/taskBox`,{
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': api_token,
                },
                body: JSON.stringify({
                    task_id: taskFields.task_id,
                    idem_key: boxUUID
                })
            });

            if (!response.ok) {
                if (response.status === 409) {
                    toast("Save this box to add a new one", {
                        position: "top-center",
                        autoClose: 3000,
                        type: "error",
                    });
                } else {
                    toast("Error adding box, try again", {
                        position: "top-center",
                        autoClose: 3000,
                        type: "error",
                    });
                }
            }

            return await response.json();
        }
        catch (error){
            throw error;
        }
    }

    const updateBox = async () => {
        try{
            const response = await fetch(`${baseRoute}/api/v1/taskBoxes/${boxFields.task_box_id}`,{
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': api_token,
                },
                body: JSON.stringify({
                    comment: boxFields.comment,
                    height: parseFloat(boxFields.height),
                    width: parseFloat(boxFields.width),
                    length: parseFloat(boxFields.length),
                    weight:parseFloat(boxFields.weight),
                    tracking: boxFields.tracking,
                    client_name: boxFields.task_box_name,
                    task_id: taskFields.task_id,
                    task_type: taskFields.task_type
                })
            });

            if (!response.ok) {
                toast("Error adding box data, try again", {
                    position: "top-center",
                    autoClose: 3000,
                    type: "error",
                });
            }
            return await response.json();
        }
        catch (error){
            throw error;
        }
    }



    const deleteBox = async () => {
        try{
            const response = await fetch(`${baseRoute}/api/v1/taskBoxes/${boxesData[boxNum-1].task_box_id}`,{
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': api_token,
                },
            });
            if (!response.ok) {
                toast("Box deleting error, try again", {
                    position: "top-center",
                    autoClose: 3000,
                    type: "error",
                });
            }
            return await response.json();
        }
        catch (error){
            throw error;
        }
    }
    const updateProductList = () => {
        setBoxProducts((prevBoxProducts) =>
            prevBoxProducts.map((product) =>
                product.task_box_item_id === productFields.task_box_item_id
                    ? { ...productFields, s3_objects: product.s3_objects}
                    : product
            )
        );
    };

    const handleSaveProduct = async () => {
        try {
            let task_box_item_id = productFields.task_box_item_id;
            if (isNewProd) {
                const resp = await addProduct();
                if (!resp.task_box_item_id){
                    return
                }
                setProductFields({
                    ...productFields,
                    task_box_item_id: resp.task_box_item_id
                });

                setBoxProducts((prevBoxProducts) => [
                    ...prevBoxProducts,
                    { ...productFields, s3_objects: [], task_box_item_id: resp.task_box_item_id }
                ]);
                task_box_item_id = resp.task_box_item_id;
            }
            await updateBoxItem(task_box_item_id);
            updateProductList();
            resetProductFields();

            const data = await fetchCheckListData();
            if (taskFields.task_type === 'external' ){
                setExternalCheckList(data);
            } else {
                setCheckList(data);
            }
        } catch (error) {
            console.log('Error saving product:', error);
        }
    }
    const addProduct = async () => {
        try {
            const response = await fetch(`${baseRoute}/api/v1/taskBoxItem`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': api_token,
                },
                body: JSON.stringify({
                    task_box_id: boxesData[boxNum-1].task_box_id,
                    task_item_id: productFields.task_item_id
                })
            });

            if (!response.ok) {
                if (response.status === 409) {
                    toast("This box already shipped, add a new one", {
                        position: "top-center",
                        autoClose: 3000,
                        type: "error",
                    });
                } else {
                    toast("Error adding product", {
                        position: "top-center",
                        autoClose: 3000,
                        type: "error",
                    });
                }
            }
            return await response.json();
        } catch (error) {
            throw error;
        }
    }
    const updateBoxItem = async (taskBoxItemId) => {
        try{
            const response = await fetch(`${baseRoute}/api/v1/taskBoxItems/${taskBoxItemId}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': api_token,
                },
                body: JSON.stringify({
                    part_number: productFields.part_number,
                    date_code: productFields.date_code,
                    manufacturer: productFields.manufacturer,
                    cns: productFields.cns,
                    quantity: parseInt(productFields.quantity),
                    weight: parseFloat(productFields.weight)
                })
            })
            if (!response.ok) {
                toast("Error saving product", {
                    position: "top-center",
                    autoClose: 3000,
                    type: "error",
                });
            }
            return await response.json();
        }
        catch (error){
            throw error;
        }
    }

    const removeS3ObjectFromBox = (boxId, s3ObjectId) => {
        setBoxesData((prevBoxes) =>
            prevBoxes.map((box) =>
                box.task_box_id === boxId
                    ? { ...box, s3_objects: box.s3_objects.filter((obj) => obj.s3_object_id !== s3ObjectId) }
                    : box
            )
        );
    };
    const removeS3ObjectFromPosition = (taskBoxItemId, s3ObjectId) => {
        setBoxProducts((prevBoxProducts) =>
            prevBoxProducts.map((boxProduct) =>
                boxProduct.task_box_item_id === taskBoxItemId
                    ? { ...boxProduct, s3_objects: boxProduct.s3_objects.filter((s3_object) => s3_object.s3_object_id !== s3ObjectId) }
                    : boxProduct
            )
        );
    };

    const handleDeletePhotos = (s3_id) => {
        if (isProduct){
            deleteS3Obj(s3_id)
                .then(() => {
                    setPhotos((prevList) => prevList.filter((photo) => photo.s3_id !== s3_id))
                    removeS3ObjectFromPosition(productFields.task_box_item_id,s3_id)
                })
                .catch((error) => {
                    console.log("Delete Photo error ", error)
                })
        }else{
            deleteS3Obj(s3_id)
                .then(() => {
                    setPhotos((prevList) => prevList.filter((photo) => photo.s3_id !== s3_id))
                    removeS3ObjectFromBox(boxesData[boxNum-1].task_box_id, s3_id)
                })
                .catch((error) => {
                    console.log("Delete Photo error ", error)
                })
        }
    };

    const handleDeleteChecklistPhotos = (s3_id) => {
        deleteS3Obj(s3_id)
            .then(() => {
                setProdPhotos((prevList) => prevList.filter((photo) => photo.s3_id !== s3_id))
                removeS3ObjectFromChecklistPosition(s3_id, taskBoxItemId)
            })
            .catch((error) => {
                console.log("Delete Photo error ", error)
            })
    }

    const removeS3ObjectFromChecklistPosition = (s3_id, taskBoxItemId) => {
        setCheckList((prevCheckList) =>
            prevCheckList.map((product) =>
                product.task_box_item_id === taskBoxItemId
                    ? {
                        ...product,
                        s3_objects: product.s3_objects.filter(
                            (s3_object) => s3_object.s3_object_id !== s3_id
                        )
                    }
                    :product
            )
        )
    }
    const deleteS3Obj = async(s3_id) => {
        try {

            const response = await fetch(`${baseRoute}/api/v1/s3objects/${s3_id}`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': api_token,
                },
            })
            if (!response.ok) {
                toast("Photo deletion error, try again", {
                    position: "top-center",
                    autoClose: 3000,
                    type: "error",
                });
            }
            return await response.json();
        } catch (error) {
            throw error;
        }
    }


    const DataURIToBlob = (dataURI) => {
        const splitDataURI = dataURI.split(',')[1]
        const byteString = atob(splitDataURI)
        const ia = new Uint8Array(byteString.length)
        for (let i = 0; i < byteString.length; i++)
            ia[i] = byteString.charCodeAt(i)
        return new File([ia],"screenshot.jpg",{ type: "image/jpeg"})
    }

    const handleUploadPhotos = async (selectedFile, selectedView, isPreview, comment) => {
        try {
            const file = DataURIToBlob(selectedFile);
            const data = await postFileSD(file);
            const taskBoxItemId = isProduct && isNewProd ? (await addProduct()).task_box_item_id : productFields.task_box_item_id;

            if (taskBoxItemId) {
                await updateBoxItem(taskBoxItemId);
                setProductFields({ ...productFields, task_box_item_id: taskBoxItemId });

                const resp = await postPhotoProductQCH(selectedView, data, taskBoxItemId, isPreview, comment);
                if (resp.s3object_id) {
                    handlePhotoUploadResponse(data, selectedFile, isPreview, comment, selectedView, resp.s3object_id, taskBoxItemId);
                    if (isProduct && isNewProd) setIsNewProd(false);
                }
            } else {
                const resp = postFileBoxQCH(data, selectedView, isPreview, comment)
                if (resp.s3object_id) {
                    handlePhotoUploadResponse(resp, selectedFile, isPreview, comment, selectedView, resp.s3object_id, boxData.task_box_id);
                }
            }
        } catch (error) {
            console.log('Upload photo error', error);
        }
    };

    const setProductPhotos = (newS3Object, box_id, prod_id) => {
        setBoxesData((prevBoxes) => {
            return prevBoxes.map((box) => {
                if (box.task_box_id === box_id) {
                    const updatedPositions = box.positions.map((position) => {
                        if (position.task_box_item_id === prod_id) {
                            return {
                                ...position,
                                s3_objects: [...position.s3_objects, newS3Object]
                            };
                        }
                        return position;
                    });
                    return {
                        ...box,
                        positions: updatedPositions
                    };
                }
                return box;
            });
        });
    }

    const handlePhotoUploadResponse = (resp, selectedFile, isPreview, comment, selectedView, s3objectId, taskBoxItemId) => {
        const photoData = { dataUrl: selectedFile, s3_id: s3objectId, comment, is_preview: isPreview, view: selectedView };
        setPhotos((prevPhotos) => [...prevPhotos, photoData]);

        const newS3Object = { source: resp.source, title: resp.title, type: resp.mime, s3_object_id: s3objectId, view: selectedView, is_preview: isPreview, comment };
        if (isProduct) {
            setBoxProducts((prevBoxProducts) =>
                isNewProd ?
                    [...prevBoxProducts, { ...productFields, task_box_item_id: taskBoxItemId,  s3_objects: [newS3Object]}] :
                    prevBoxProducts.map((product) =>
                        product.task_box_item_id === taskBoxItemId ?
                            { ...product, s3_objects: [...product.s3_objects, newS3Object] } :
                            product
                    )
            );
            setProductPhotos(newS3Object, boxData.task_box_id,taskBoxItemId);
        } else {
            setBoxesS3(newS3Object, boxData.task_box_id);
            setBoxPhotos((prevPhotos) => [...prevPhotos, photoData]);
        }
    };

    const setBoxesS3 = (newS3Object, task_box_id) => {
        setBoxesData((prevBoxes) => prevBoxes.map((box) => box.task_box_id === task_box_id ? { ...box, s3_objects: [...box.s3_objects, newS3Object] } : box));
    };

    const handleUploadDocuments = async (selectedFiles) => {
        for (const selectedFile of selectedFiles){
            try {
                const blob = new Blob([selectedFile], { type: selectedFile.type});
                const file = new File([blob], selectedFile.name, { type: blob.type });
                const data = await postFileSD(file);
                const resp = await postFileBoxQCH(data, 'doc');
                const doc = {
                    title: selectedFile.name,
                    source: data.source,
                    s3_object_id: resp.s3object_id,
                    type: selectedFile.type,
                    view: 'doc'
                };
                setDocuments((prevDocs) => [...prevDocs, doc]);
                setBoxesS3(doc, boxesData[boxNum-1].task_box_id);
                setSelectedFiles([]);
            } catch (error) {
                console.log('Error uploading documents:', error);
            }
        }
    }

    const handleDeleteDocuments = async(s3_id) => {
        try {
            await deleteS3Obj(s3_id);
            setDocuments((prevList) => prevList.filter((doc) => doc.s3_object_id !== s3_id));
            removeS3ObjectFromBox(boxesData[boxNum-1].task_box_id, s3_id);
        } catch (error) {
            console.log("Delete Doc error ", error);
        }
    };

    const handleDeleteBoxProd = async () => {
        try {
            await deleteBoxProd(productFields.task_box_item_id);
            setSelectedProductId('');
            setBoxProducts((prevBoxProducts) =>
                prevBoxProducts.filter((product) => product.task_box_item_id !== productFields.task_box_item_id)
            );
            setProductFields({
                part_number: '',
                date_code: '',
                manufacturer: '',
                cns: '',
                quantity: '',
                weight: '',
                task_box_id: boxesData[boxNum-1].task_box_id,
                task_box_item_id: ''
            });
            setPhotos([]);
            const data = await fetchCheckListData();
            setCheckList(data);
            setIsNewProd(true);
        } catch (error) {
            console.log('Error delete Box Product', error);
        }
    };

    const postFileSD = async(file) => {
        try{
            const headers = new Headers();
            headers.append('X-Token', xToken);
            const formData = new FormData()
            formData.append('file',file)

            const response = await fetch(`${apiRoute}/catalog/resources.json`, {
                method: 'POST',
                headers: headers,
                body: formData
            })
            if (!response.ok){
                toast("Photo loading error", {
                    position: "top-center",
                    autoClose: 3000,
                    type: "error",
                });
            }
            return await response.json()
        }catch(error){
            throw error
        }
    }

    const postFileBoxQCH = async (data, selectedView, isPreview, comment) => {
        try {
            const response = await fetch(`${baseRoute}/api/v1/s3object`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': api_token,
                },
                body: JSON.stringify({
                    title: data.title,
                    type: data.mime,
                    link: data.source,
                    task_box_id: boxesData[boxNum - 1].task_box_id,
                    view: selectedView,
                    comment: comment,
                    is_preview: isPreview
                })
            });
            if (!response.ok) {
                toast("Photo loading error", {
                    position: "top-center",
                    autoClose: 3000,
                    type: "error",
                });
            }
            return await response.json();
        } catch (error) {
           throw error
        }
    };
    const postPhotoProductQCH = async(selectedView, data, task_box_item_id, isPreview, comment) => {
        try{
            const response = await fetch (`${baseRoute}/api/v1/s3object`,{
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': api_token,
                },
                body: JSON.stringify({
                    title: data.title,
                    type: data.mime,
                    link: data.source,
                    view: selectedView,
                    is_preview: isPreview,
                    comment: comment,
                    task_box_item_id: task_box_item_id
                })
            })
            if (!response.ok) {
                toast("Photo loading error", {
                    position: "top-center",
                    autoClose: 3000,
                    type: "error",
                });
            }
            return await response.json()
        }
        catch(error){
            throw error
        }
    }


    const deleteBoxProd = async(task_box_item_id) => {
        try{
            const response = await fetch (`${baseRoute}/api/v1/taskBoxItems/${task_box_item_id}`,{
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': api_token,
                },
            })
            if (!response.ok) {
                toast("Delete Box Product error", {
                    position: "top-center",
                    autoClose: 3000,
                    type: "error",
                });
            }
            return await response.json()
        }
        catch(error) {
            throw error
        }
    }

    const handleUpdateBoxPhoto = async (s3_id, selectedView, comment, isPreview) => {
        try {
            await updatePhotoQCH(s3_id, selectedView, comment, isPreview);

            setBoxPhotos((prevPhotos) =>
                prevPhotos.map((photo) => {
                    if (photo.s3_id === s3_id) {
                        return {
                            ...photo,
                            view: selectedView,
                            comment: comment,
                            is_preview: isPreview
                        };
                    }
                    return photo;
                })
            );

            setBoxesData((prevBoxes) => {
                return prevBoxes.map((box) => {
                    if (box.task_box_id === boxesData[boxNum - 1].task_box_id) {
                        return {
                            ...box,
                            s3_objects: box.s3_objects.map((s3Object) => {
                                if (s3Object.s3_object_id === s3_id) {
                                    return {
                                        ...s3Object,
                                        comment: comment,
                                        view: selectedView,
                                        is_preview: isPreview,
                                    };
                                }
                                return s3Object;
                            }),
                        };
                    }
                    return box;
                });
            });
        } catch (error) {
            console.log('Error updating box photo:', error);
        }
    };
    const handleUpdateProdPhoto = async (s3_id, selectedView, comment, isPreview) => {
        try {
            await updatePhotoQCH(s3_id, selectedView, comment, isPreview);

            setPhotos((prevPhotos) =>
                prevPhotos.map((photo) => {
                    if (photo.s3_id === s3_id) {
                        return {
                            ...photo,
                            view: selectedView,
                            comment: comment,
                            is_preview: isPreview
                        };
                    }
                    return photo;
                })
            );

            setBoxProducts((prevBoxProducts) => {
                prevBoxProducts.map((boxProduct) =>
                    boxProduct.task_box_item_id === productFields.task_box_item_id
                        ? {
                            ...boxProduct,
                            s3_objects: boxProduct.s3_objects.map((s3Object) =>
                                s3Object.s3_object_id === s3_id
                                    ? {
                                        ...s3Object,
                                        comment: comment,
                                        view: selectedView,
                                        is_preview: isPreview,
                                    }
                                    : s3Object
                            )
                        }
                        : boxProduct
                )
            });
        } catch (error) {
            console.log('Error updating product photo:', error);
        }
    };

    const updatePhotoQCH = async(s3_id, selectedView, comment, isPreview) => {
        try{
            const response = await fetch (`${baseRoute}/api/v1/s3objects/${s3_id}`,{
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': api_token,
                },
                body: JSON.stringify({
                    s3_comment: comment,
                    s3_view: selectedView,
                    is_preview: isPreview,
                    s3_object_id: s3_id,
                })
            })
            return await response.json()
        }
        catch(error){
            throw error
        }
    }

    const handleShip = async() => {
        setIsOpenHandleShipOptions(true);
    }

    const closeSelectShip = () => {
        setIsOpenHandleShipOptions(false);
    }
    const handleSelectShip = async (isAll) => {
        if (isAll === null){
            setIsOpenHandleShipOptions(false);
            return
        }
        await Ship(isAll);
        setIsOpenHandleShipOptions(false);
    }

    const Ship = async(isAll) => {
        try {
            const response = await fetch(`${baseRoute}/api/v1/tasks/${taskFields.task_id}/approveShipment`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': api_token,
                },
                body: JSON.stringify({
                    all: isAll
                })
            })
            if (!response.ok) {
                toast("Approve shipment error, try again", {
                    position: "top-center",
                    autoClose: 3000,
                    type: "error",
                });
            }
            toast("Shipment approved", {
                position: "top-center",
                autoClose: 3000,
                type: "info",
            });

            return await response.json()
        } catch (error) {
            throw error
        }
    }

    const [loggedIn, setLoggedIn] = useState(true);
    const [isLoggedOut, setIsLoggedOut] = useState(false);
    const handleLogout = () => {
        localStorage.removeItem('token');
        localStorage.removeItem('userRoles');
        setLoggedIn(false);
        setIsLoggedOut(true);
    };

    const [isOpenHandleShipOptions, setIsOpenHandleShipOptions] = useState(false);



    const handleLabelPrint = async (taskItemIds, pageWidth, pageHeight, columns, rows) => {
        try {
            const response = await fetch(`${baseRoute}/api/v1/tasks/items/print-labels`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': api_token,
                },
                body: JSON.stringify({
                    task_item_ids: taskItemIds,
                    page_width: pageWidth,
                    page_height: pageHeight,
                    columns: columns,
                    rows: rows
                })
            });
            if (!response.ok) {
                toast("Error printing label, try again", {
                    position: "top-center",
                    autoClose: 3000,
                    type: "error",
                });
            }
            const data = await response.json();

            openBase64Pdf(data.pdf_bytes);
        } catch (error) {
            throw error;
        }

    }

    function openBase64Pdf(base64String) {

        const binaryString = window.atob(base64String);
        const len = binaryString.length;
        const bytes = new Uint8Array(len);
        for (let i = 0; i < len; i++) {
            bytes[i] = binaryString.charCodeAt(i);
        }

        const blob = new Blob([bytes], { type: 'application/pdf' });

        const url = URL.createObjectURL(blob);

        window.open(url, '_blank');
    }

    const handleBoxNameChange = (event) => {
        if (event.target.value === '') {
            return;
        }
        setBoxFields({ ...boxFields, task_box_name: event.target.value });
    }

    const handleMoveClick = async (product, type) => {
        setClientFilter('')
        setSelectedBoxId('')
        setAddProductOpen(false)

        setMovedItem(product);
        setIsMoveOpen(true);
        if (type === 'tasks') {
            setIsMovedThroughTasks(true);
        } else {
            setIsMovedThroughTasks(false);
        }
    }

    const [isMoveOpen, setIsMoveOpen] = useState(false);
    const [movedItem, setMovedItem] = useState(null);
    const [isMovedThroughTasks, setIsMovedThroughTasks] = useState(false);


    const moveItemThroughTasks = async (taskItemId, taskId) => {
        try {
            const response = await fetch(`${baseRoute}/api/v1/taskItems/${taskItemId}/${taskId}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': api_token,
                },
            });

            if (!response.ok) {
                toast("Move item error, try again", {
                    position: "top-center",
                    autoClose: 3000,
                    type: "error",
                });
            }
            toast("Item moved", {
                position: "top-center",
                autoClose: 3000,
                type: "info",
            });
            return await response.json()
        } catch (error) {
            console.error('Error:', error);
        }
    }

    const moveItemThoughBoxes = async (taskBoxItemId, taskBoxId) => {
        try {
            const response = await fetch(`${baseRoute}/api/v1/taskBoxItems/${taskBoxItemId}/${taskBoxId}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': api_token,
                },
            });

            if (!response.ok) {
                toast("Move item error, try again", {
                    position: "top-center",
                    autoClose: 3000,
                    type: "error",
                });
            }
            toast("Item moved", {
                position: "top-center",
                autoClose: 3000,
                type: "info",
            });
            return await response.json()
        } catch (error) {
            console.error('Error:', error);
        }
    }
    const handleMoveItem = async (itemId, id) => {
        if (isMovedThroughTasks) {
            await moveItemThroughTasks(itemId, id);
        } else {
            await moveItemThoughBoxes(itemId, id);
        }
        const checkListData = await fetchCheckListData();
        setExternalCheckList(checkListData);
        const boxesData = await fetchBoxesData();
        setBoxesData(boxesData);
        const items = await fetchItems(selectedBoxId);
        setBoxProducts(items);
        resetProductFields();
    };

    const [labelInfo, setLabelInfo] = useState({
        part_number: '',
        manufacturer: '',
        date_code: '',
        part_description: '',
        quantity: '',
        label_name: '',
        task_item_id: ''
    });
    const [isLabelInfoOpen, setIsLabelInfoOpen] = useState(false);


    const handleLabelClick = async (product) => {
        const label = product.label_info
        setLabelInfo({
            part_number: label.part_number,
            manufacturer: label.manufacturer,
            date_code: label.date_code,
            part_description: label.part_description,
            quantity: label.quantity,
            label_name: label.label_name,
            task_item_id: product.task_item_id
        });
        setIsLabelInfoOpen(true);
    }

    const handleLabelInfoChange = updatedData => {
        setLabelInfo((prevFormData) => ({
            ...prevFormData,
            ...updatedData
        }))
    }

    const handleLabelInfoSave = async (label) => {
        try {
            await updateLabelInfo(label);
            const data = await fetchCheckListData();
            setExternalCheckList(data);
            setIsLabelInfoOpen(false);
        } catch (error) {
            console.log('Error updating label info:', error);
        }

    }

    const updateLabelInfo = async (label) => {
        try {
            const response = await fetch(`${baseRoute}/api/v1/taskItems/${label.task_item_id}/labelInfo`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': api_token,
                },
                body: JSON.stringify({
                    part_number: label.part_number,
                    manufacturer: label.manufacturer,
                    date_code: label.date_code,
                    part_description: label.part_description,
                    quantity: label.quantity,
                    label_name: label.label_name
                })
            });


            if (!response.ok) {
                toast("Update label info error, try again", {
                    position: "top-center",
                    autoClose: 3000,
                    type: "error",
                });
            }
            toast("Label Info updated", {
                position: "top-center",
                autoClose: 3000,
                type: "info",
            });
            return await response.json()
        } catch (error) {
            console.error('Error:', error);
        }
    }

    return (
        <Box>
            <Grid container spacing={6}>
                <Grid item xs={12} sm={12} md={12}>
                    <Grid container spacing={4}>
                        <Grid item xs={12} sm={12} md={12}>
                            <Header
                                loggedIn={loggedIn}
                                onLogout={handleLogout}
                                isLoggedOut={isLoggedOut}
                            />
                        </Grid>
                        <Grid item sm={2} md={2}>
                            <Hidden smDown>
                                <CheckList
                                    checkList={checkList}
                                    onSearchProduct={handleSearchProduct}
                                    onProductClick={handleProductClick}
                                    handleOpenGallery={handleOpenGallery}
                                    type={"shipment"}
                                    handleOpenAllGallery={handleOpenAllGallery}
                                    externalCheckList={externalCheckList}
                                    handleLabelsPrint={handleLabelPrint}
                                    handleMoveClick={handleMoveClick}
                                    handleLabelClick={handleLabelClick}
                                />

                            </Hidden>
                        </Grid>
                        <Grid item xs={12} sm={10} md={10}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={12} md={12}>
                                    <AddTaskBox taskFields={taskFields} handleAddBox={handleAddBox} handleShip={handleShip}/>
                                </Grid>
                                <Grid item xs={12} sm={12} md={12}>
                                    {isBoxesDataLoaded ? (
                                        <SelectBox boxes={boxesData} selectedBoxId={selectedBoxId} onSelectBox={handleSelectBox}/>
                                    ) : (
                                        <Loader/>
                                    )}
                                </Grid>
                                {selectedBoxId && !isEditBoxOpen && (
                                    <Grid item xs={12} sm={12} md={12}>
                                        <SingleBox
                                            box={boxData}
                                            boxNum={
                                                boxData.task_box_name ?
                                                    boxData.task_box_name :
                                                    boxesData.findIndex((box) => {
                                                        return  box.task_box_id === boxData.task_box_id
                                                    }) + 1}
                                            boxPhotos={boxPhotos}
                                            onDeleteButtonClick={handleDeleteBox}
                                            onEditButtonClick={handleEditBox}
                                            handleUpdatePhoto={handleUpdateBoxPhoto}
                                            handleDeletePhoto={handleDeletePhotos}/>
                                    </Grid>
                                )}
                                <Grid item xs={12} sm={12} md={12}>
                                    {!isEditBoxOpen ? null : (
                                        <Grid item>
                                            <span style={{ fontSize: '24px' }}> Box {boxFields.task_box_name ? (
                                                <TextField id={"task_box_name"} value={boxFields.task_box_name} InputLabelProps={{ shrink: true }} variant="standard" onChange={handleBoxNameChange} />
                                                ) :
                                                                                        boxesData.findIndex((box) => {
                                                                                        return  box.task_box_id === boxData.task_box_id
                                                                                        }) + 1}
                                            </span>
                                        </Grid>
                                    )}
                                </Grid>
                                <Grid item xs={12} sm={12} md={12}>
                                    <Collapse in={isEditBoxOpen}>
                                        <BoxFields
                                            photos={photos}
                                            documents={documents}
                                            onBoxFieldsChange={handleBoxFieldsChange}
                                            boxFields={boxFields}
                                            handleUploadPhotos={handleUploadPhotos}
                                            handleSaveBox={handleSaveBox}
                                            handleDeletePhotos={handleDeletePhotos}
                                            handleUploadDocuments={handleUploadDocuments}
                                            handleDeleteDocs={handleDeleteDocuments}
                                            selectedFiles={selectedFiles}
                                            setSelectedFiles={setSelectedFiles}
                                            handleUpdatePhoto={handleUpdateBoxPhoto}/>
                                    </Collapse>
                                </Grid>

                                <Grid item xs={12} sm={12} md={12}>
                                    {!isAddProductOpen ? null :
                                        (
                                            <span style={{ fontSize: '24px' }}>Add products to Box {boxData.task_box_name ? boxData.task_box_name :
                                                                                                        boxesData.findIndex((box) => {
                                                                                                            return  box.task_box_id === boxData.task_box_id
                                                                                                        }) + 1}
                                            </span>
                                        )
                                    }
                                </Grid>
                                <Grid item xs={12} sm={12} md={12}>
                                    <Collapse in={isAddProductOpen}>
                                        <Hidden smUp>
                                            <Grid container justifyContent={'center'} spacing={2}>
                                                <Grid item xs={4} sm={4}>
                                                    <CheckList
                                                        checkList={checkList}
                                                        onSearchProduct={handleSearchProduct}
                                                        onProductClick={handleProductClick}
                                                        handleOpenGallery={handleOpenGallery}
                                                        type={"shipment"}
                                                        handleOpenAllGallery={handleOpenAllGallery}
                                                        externalCheckList={externalCheckList}
                                                        handleLabelsPrint={handleLabelPrint}
                                                        handleMoveClick={handleMoveClick}
                                                        handleLabelClick={handleLabelClick}
                                                    />

                                                </Grid>
                                                <Grid item xs={8} sm={8}>
                                                    {isAddProductOpen && boxesData.length > 0 ? (
                                                        <ProductFields
                                                            photos={photos}
                                                            onProductFieldsChange={handleProductFieldsChange}
                                                            productFields={productFields}
                                                            handleSaveProduct={handleSaveProduct}
                                                            boxProducts={boxProducts}
                                                            handleUploadPhotos={handleUploadPhotos}
                                                            onSelectProduct={handleSelectProduct}
                                                            handleDeletePhotos={handleDeletePhotos}
                                                            handleDeleteBoxProd={handleDeleteBoxProd}
                                                            selectedProductId={selectedProductId}
                                                            setSelectedProductId={setSelectedProductId}
                                                            handleUpdatePhoto={handleUpdateProdPhoto}
                                                            onMoveProduct={handleMoveClick} />
                                                    ) : null}
                                                </Grid>
                                            </Grid>
                                        </Hidden>
                                        <Hidden smDown>
                                            {isAddProductOpen && boxesData.length > 0 ? (
                                                <ProductFields
                                                    photos={photos}
                                                    onProductFieldsChange={handleProductFieldsChange}
                                                    productFields={productFields}
                                                    handleSaveProduct={handleSaveProduct}
                                                    boxProducts={boxProducts}
                                                    handleUploadPhotos={handleUploadPhotos}
                                                    onSelectProduct={handleSelectProduct}
                                                    handleDeletePhotos={handleDeletePhotos}
                                                    handleDeleteBoxProd={handleDeleteBoxProd}
                                                    selectedProductId={selectedProductId}
                                                    setSelectedProductId={setSelectedProductId}
                                                    handleUpdatePhoto={handleUpdateProdPhoto}
                                                    onMoveProduct={handleMoveClick} />
                                            ) : null}
                                        </Hidden>
                                    </Collapse>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Dialog open={isOpenHandleShipOptions} onClose={closeSelectShip}>
                        <DialogTitle>Ship Options</DialogTitle>
                        <Button variant='outlined' color="success"  onClick={() => handleSelectShip(true)}>
                            Ship all items
                        </Button>
                        <Button variant='outlined' color="success" onClick={() => handleSelectShip(false)}>
                            Ship selected items
                        </Button>
                        <Button variant='outlined' color="success" onClick={() => handleSelectShip(null)}>
                            Cancel
                        </Button>
                    </Dialog>
                    <Dialog open={isOpenGallery} onClose={closeModal}>
                        <DialogContent>
                            <PhotoGallery photosGallery={prodPhotos} handleUpdatePhoto={handleUpdateProdPhoto} handleDelete={handleDeleteChecklistPhotos}/>
                        </DialogContent>
                    </Dialog>
                    <Dialog open={isOpenAllGallery} onClose={closeAllModal}>
                        <DialogContent>
                            <AllPhotosGallery photosGallery={allGalleryData}/>
                        </DialogContent>
                    </Dialog>
                    <Dialog open={isMoveOpen} onClose={() => setIsMoveOpen(false)}>
                        <DialogContent>
                            <MoveItem
                                movedItem={movedItem}
                                externalCheckList={externalCheckList}
                                onMove={handleMoveItem}
                                onClose={() => setIsMoveOpen(false)}
                                boxesData={boxesData}
                                movedType={isMovedThroughTasks ? 'task' : 'box'}
                            />
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => setIsMoveOpen(false)} color="error">
                                Cancel
                            </Button>
                        </DialogActions>
                    </Dialog>
                    <Dialog open={isLabelInfoOpen} onClose={() => setIsLabelInfoOpen(false)}>
                        <DialogContent>
                            <LabelInfo
                                labelInfo={labelInfo}
                                onClose={() => setIsLabelInfoOpen(false)}
                                onChangeLabelInfo={handleLabelInfoChange}
                                onSaveLabel={handleLabelInfoSave}
                                onPrint={handleLabelPrint}
                            />
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => setIsLabelInfoOpen(false)} color="error">
                                Cancel
                            </Button>
                        </DialogActions>
                    </Dialog>
                </Grid>
            </Grid>
        </Box>
    )
}
