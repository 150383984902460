import {
    Box,
    Button, Collapse,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    TextField
} from "@mui/material";
import React from "react";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'

export default function TaskFields({
                                       onTaskFieldsChange,
                                       taskFields,
                                       routes,
                                       onClickAddItems,
                                       isRouteChangeable,
                                       onDeleteButtonClick,
                                       onCreateDocsClick,
                                       onEditDocsClick,
                                       isDocsCreated,
                                   }) {

    const handleDateChange = (newValue) => {
        onTaskFieldsChange({ task_date: newValue });
    };
    const handleRouteChange = (event) => {
        const selectedRouteId = event.target.value;
        const selectedRoute = routes.find((route) => route.route_id === selectedRouteId);
        const routeId = selectedRoute.route_id;
        const routeName = selectedRoute.route_name;
        onTaskFieldsChange({
            route_id: routeId,
            route_name: routeName,
        });
    };


    return (
        <Grid container spacing={2} align={"center"}>

            {/* Task Fields Section */}
            <Grid item xs={12} sm={12} md={12}>
                <Box>
                    <Grid container spacing={2}>
                        {/* Task Number Field */}
                        <Grid item xs={2} sm={2} md={2}>
                            <TextField
                                id="task_num"
                                InputLabelProps={{ shrink: true }}
                                label="Task Number"
                                variant="standard"
                                value={taskFields.task_num}
                                disabled
                            />
                        </Grid>

                        {/* Task Date Picker */}
                        <Grid item xs={3} sm={3} md={3}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                    id="task_date"
                                    label="Select Task Date"
                                    inputFormat={"DD.MM.YYYY"}
                                    value={taskFields.task_date}
                                    onChange={handleDateChange}
                                    renderInput={(params) => <TextField {...params} />}
                                />
                            </LocalizationProvider>
                        </Grid>

                        {/* Select Route Dropdown */}
                        <Grid item xs={3} sm={3} md={3}>
                            <FormControl fullWidth>
                                <InputLabel id="route_id">Select Route</InputLabel>
                                {routes && routes.length > 0 && (
                                    <Select
                                        label='Select Route'
                                        value={taskFields.route_id || ''}
                                        onChange={handleRouteChange}
                                        disabled={!isRouteChangeable}
                                    >
                                        {routes.map((route, index) => (
                                            <MenuItem key={index} value={route.route_id}>
                                                {route.route_name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                )}
                            </FormControl>
                        </Grid>

                        <Grid item xs={4} sm={4} md={4} style={{ display: 'flex', justifyContent: 'center' }}>
                            {isRouteChangeable ? (
                                <Button variant='outlined' color="success" onClick={onClickAddItems}>
                                    ADD TASK ITEMS
                                </Button>
                            ) : (
                                <Grid container spacing={2} justifyContent={"center"} alignItems={"center"}>
                                    {isDocsCreated ? (
                                        <>
                                            <Grid item xs={6} sm={6} md={6}>
                                                <Collapse in={taskFields.task_state === "preparing"}>
                                                    <Button variant='outlined' color="success" onClick={() => onDeleteButtonClick(taskFields.task_id)} fullWidth>
                                                        REMOVE THIS TASK
                                                    </Button>
                                                </Collapse>
                                            </Grid>
                                            <Grid item xs={6} sm={6} md={6}>
                                                <Button variant='outlined' color="success" onClick={onEditDocsClick} fullWidth>
                                                    EDIT DOCS
                                                </Button>
                                            </Grid>
                                        </>
                                    ) : (
                                        <>
                                            <Grid item xs={6} sm={6} md={6}>
                                                <Button variant='outlined' color="success" onClick={() => onDeleteButtonClick(taskFields.task_id)} fullWidth>
                                                    REMOVE THIS TASK
                                                </Button>
                                            </Grid>
                                            <Grid item xs={6} sm={6} md={6}>
                                                <Button variant='outlined' color="success" onClick={onCreateDocsClick} fullWidth>
                                                    CREATE DOCS
                                                </Button>
                                            </Grid>
                                        </>
                                    )}
                                </Grid>
                            )}
                        </Grid>

                    </Grid>
                </Box>
            </Grid>
        </Grid>
    );
}
