import {FormControl, Grid, InputLabel, MenuItem, Select, Typography} from "@mui/material";
import React from "react";


export default function SelectBox({boxes, onSelectBox, selectedBoxId}){



    return(
        <Grid container>
            {boxes.length > 0 ? (
            <FormControl fullWidth>
                <InputLabel>Select box</InputLabel>

                    <Select label='Select box' value={selectedBoxId || ''} onChange={onSelectBox}>
                        {boxes.map((box, index) => (
                            <MenuItem key={index} value={box.box_id || box.task_box_id}>
                                { box.task_box_name ? box.task_box_name : (
                                    <Typography>
                                        Box {index +1}
                                    </Typography>
                                )}
                            </MenuItem>
                        ))}
                    </Select>
            </FormControl>
            ) : (
                <Typography variant={'h6'}>
                    There are no boxes in this order, click ADD BOX
                </Typography>
            )}
        </Grid>
    )
}