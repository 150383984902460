import React from 'react';
import { Grid } from '@mui/material';

export default function Company({ ship_to }){
    let companyString = '';

    try {
        companyString = ship_to.map(ship => ship.company.toUpperCase()).join(' ');
    } catch (error) {
        console.error('Error while mapping company:', error);
    }

    return (
        <Grid container>
            {companyString}
        </Grid>
    );
}
