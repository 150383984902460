import React from 'react';
import { Container, Grid, TextField } from '@mui/material';

export default function SearchTaskPanel({ partNumberFilter, setPartNumberFilter }) {

    return (
        <Grid item xs={12} sm={12} md={12}>
            <Container>
                        {/* -------------------------------------part-number-field--------------------------------- */}
                        <TextField
                            variant="outlined"
                            label="Enter Part Number"
                            type='search'
                            fullWidth
                            size='small'
                            value={partNumberFilter || ''}
                            onChange={(event) => setPartNumberFilter(event.target.value)}
                        />
            </Container>
        </Grid>
    );
}
