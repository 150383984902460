import React from 'react';
import {Container, FormControl, Grid, InputLabel, MenuItem, Select, TextField} from '@mui/material';
import Chips from '../Filters/Chips';


export default function FiltersBoard({ values, setValues}){

    const handleChange = (event, fieldName) => {
        const inputValue = event.target.value;
        const valueArray = inputValue.split(',').map(value => value.trim());

        setValues((prevValues) => ({
            ...prevValues,
            [fieldName]: fieldName === 'Status'|| fieldName === 'Deal' ? inputValue.trim() : valueArray,
        }));
    };

    const handleDeleteAll = () =>{
        setValues({});
    }

    const handleDelete = (chip) => {
        setValues((prevValues) => {
            const updatedValues = { ...prevValues };
            const [fieldName, value] = chip.split(': ');

            if (Array.isArray(updatedValues[fieldName])) {
                // Remove the value from the array
                updatedValues[fieldName] = updatedValues[fieldName].filter((val) => val !== value);

                // If the array becomes empty after filtering, remove the entire field
                if (updatedValues[fieldName].length === 0) {
                    delete updatedValues[fieldName];
                }
            } else {
                // If it's not an array (e.g., for the "Status" field), remove the entire field
                delete updatedValues[fieldName];
            }

            return updatedValues;
        });
    };
    const renderChips = () => {
        const chips = [];
        Object.keys(values).forEach((fieldName) => {
            if (Array.isArray(values[fieldName])) {
                values[fieldName].forEach((value) => {
                    const chipLabel = `${fieldName}: ${value}`;
                    chips.push(chipLabel);
                });
            } else {
                const chipLabel = `${fieldName}: ${values[fieldName]}`;
                chips.push(chipLabel);
            }
        });
        return chips;
    };


    return(
        <Container>
            <Grid item xs={12}>
                <Chips
                    chips = {renderChips()}
                    onDelete = {handleDelete}
                    onDeleteAll={handleDeleteAll}
                />
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={12}>
                        <TextField
                            variant="standard"
                            label="Enter PO number"
                            type='search'
                            fullWidth
                            size='small'
                            value={values.Deal || ''}
                            onChange={(event) => handleChange(event, 'Deal')}
                        >
                        </TextField>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                        <TextField
                            variant="standard"
                            label="Введите Supplier company"
                            type='search'
                            fullWidth
                            size='small'
                            value={values.Supplier ? values.Supplier.join(','): ''}
                            onChange={(event) => handleChange(event, 'Supplier')}
                        >
                        </TextField>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                        <FormControl fullWidth>
                            <InputLabel>Select Status</InputLabel>
                            <Select
                                label='Select Status'
                                value={values.Status || ''}
                                onChange={(event) => handleChange(event, 'Status')}
                            >
                                <MenuItem key={1} value={"in_transit"}>
                                    In transit
                                </MenuItem>
                                <MenuItem key={2} value={"delivered"}>
                                    Delivered
                                </MenuItem>
                                <MenuItem key={3} value={"escalation"}>
                                    Escalation
                                </MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
            </Grid>
        </Container>

    );
}