import {
    Button,
    Grid,
    Paper,
    List,
    ListItem,
    ListItemText,
    ListItemSecondaryAction, IconButton
} from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import DeleteIcon from "@mui/icons-material/Delete";


export default function DocumentUploader({handleUpload, handleDelete, documents, selectedFiles, setSelectedFiles}){

    const handleFileChange = (event) => {
        const files = event.target.files;
        setSelectedFiles([...selectedFiles, ...files]);

    };

    const handleDeleteSelectedFile = async(index) => {
        setSelectedFiles((prevFiles) => prevFiles.filter((_, indexToDelete) => indexToDelete !== index))
    }


    return (
        <Paper elevation={3} style={{ padding: '16px' }}>
            <input type="file" multiple onChange={handleFileChange} />

            <List>
                {documents.map((doc) => (
                    <ListItem key={doc.s3_object_id}>
                        <a href={`${process.env.REACT_APP_API_BASE_ROUTE}${doc.source}`} download={doc.title}>
                            <ListItemText primary={doc.title} secondary={`type: ${doc.type}`}/>
                        </a>
                        <ListItemSecondaryAction>
                            <IconButton edge="end" onClick={() => handleDelete(doc.s3_object_id)}>
                                <DeleteIcon />
                            </IconButton>
                        </ListItemSecondaryAction>
                    </ListItem>
                ))}
            </List>

            <List>
                {selectedFiles.map((file, index) => (
                    <ListItem key={index}>
                        <ListItemText primary={file.name} />
                        <IconButton edge="end" onClick={() => handleDeleteSelectedFile(index)}>
                            <DeleteIcon />
                        </IconButton>
                    </ListItem>

                ))}
            </List>

            <Grid container justifyContent="flex-end">
                <Button color ="success" variant="contained" startIcon={<CloudUploadIcon />} onClick={() => handleUpload(selectedFiles)}>
                    Upload All
                </Button>
            </Grid>
        </Paper>
    )
};


