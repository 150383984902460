import React, { useState, useEffect } from "react";
import {Button, Checkbox, FormControlLabel, Grid, TextField, Typography} from "@mui/material";
import Products from "../Products";
import TaskClients from "./TaskClients";

export default function CheckList({
                                      checkList,
                                      onSearchProduct,
                                      onProductClick,
                                      handleOpenGallery,
                                      type,
                                      handleOpenAllGallery,
                                      externalCheckList,
                                      handleLabelsPrint,
                                      handleMoveClick,
                                      handleLabelClick
                                  }) {
    const [filter, setFilter] = useState('all');
    const [products, setProducts] = useState(checkList);
    const [taskItemIds, setTaskItemIds] = useState([]);

    useEffect(() => {
        let filteredProducts = checkList;

        if (filter !== 'all') {
            filteredProducts = checkList.filter((product) => {
                if (filter === 'shippedOrAccepted') {
                    return product.total_quantity === product.quantity_sum;
                }
                if (filter === 'notShippedOrAccepted') {
                    return product.total_quantity !== product.quantity_sum;
                }
                return true;
            });
        }
        setProducts(filteredProducts);
    }, [filter, checkList, type]);

    const handleFilterChange = (newFilter) => {
        setFilter(newFilter);
    };
    useEffect(() => {
        setTaskItemIds(externalCheckList.flatMap(item => item.positions.map(position => position.task_item_id)));
    }, [externalCheckList]);

    return (
        <Grid container spacing={2} align="flex-start">
            {externalCheckList && externalCheckList.length > 0 && (
                <Grid item xs={12} sm={12} md={12}>
                    <Button variant="text" color="success" onClick={() => handleLabelsPrint(taskItemIds, 210, 297, 2, 4)}>
                        Print Labels
                    </Button>
                </Grid>
            )}
            <Grid item xs={12} sm={12} md={12}>
                <Typography variant="h5">Check list</Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
                <Grid container>
                    <TextField
                        variant="standard"
                        label="Search"
                        type="search"
                        fullWidth
                        size="small"
                        onChange={(e) => onSearchProduct(e.target.value)}
                    />
                </Grid>
            </Grid>
            {externalCheckList && externalCheckList.length > 0 ? (
                <TaskClients
                    externalCheckList={externalCheckList}
                    handleOpenAllGallery={handleOpenAllGallery}
                    handleOpenGallery={handleOpenGallery}
                    onProductClick={onProductClick}
                    handleMoveClick={handleMoveClick}
                    handleLabelClick={handleLabelClick}
                />
            ) : (
                <>
                    <Grid item xs={12} sm={12} md={12}>
                        <FormControlLabel
                            control={<Checkbox checked={filter === 'all'} onChange={() => handleFilterChange('all')} />}
                            label="All items"
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={filter === 'shippedOrAccepted'}
                                    onChange={() => handleFilterChange('shippedOrAccepted')}
                                />
                            }
                            label={type === "shipment" ? "Shipped" : "Accepted"}
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={filter === 'notShippedOrAccepted'}
                                    onChange={() => handleFilterChange('notShippedOrAccepted')}
                                />
                            }
                            label={type === "shipment" ? "Not Shipped" : "Not Accepted"}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                        <Products products={products} onProductClick={onProductClick} handleOpenGallery={handleOpenGallery} handleOpenAllGallery={handleOpenAllGallery}/>
                    </Grid>
                </>
            )}
        </Grid>
    );
}