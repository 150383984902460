import React from 'react';
import { Container, Grid } from '@mui/material';
import SingleCard from './SingleCard';


export default function Cards({ deals }){

    const renderCards = Array.isArray(deals) ? (
        deals.map((deal, index) =>(
            <Grid key={index} item  xs={12} sm={12} md={12}>
                <SingleCard deal = {deal}/>
            </Grid>
        ))
    ) : (
        <Grid item>No available data</Grid>
    )
    
    return(
        <Container>
            <Grid container spacing={5}>
                <Grid item xs={12} sm={12} md={12}>
                    <Grid container spacing={2}>
                        {renderCards}
                    </Grid>
                </Grid>
            </Grid>     
        </Container>        
    );
}