import {Button, Grid, TextField} from "@mui/material";
import PhotoEditor from "../PhotoEditor";
import React from "react";
import SelectProduct from "./SelectProduct";

const TextFieldComponent = ({id, label, value, onChange}) => (
    <Grid item xs={3} sm={3} md={3}>
        <TextField id={id} label={label} InputLabelProps={{ shrink: true }} variant="standard" value={value || ''} onChange={onChange}/>
    </Grid>
);
export default function ProductFields({photos, onProductFieldsChange, productFields, handleSaveProduct, boxProducts, handleUploadPhotos, onSelectProduct, handleDeletePhotos, handleDeleteBoxProd, selectedProductId, setSelectedProductId, handleUpdatePhoto, onMoveProduct}){

    const handleChangeField = (event) => {
        const {id, value} = event.target;
        onProductFieldsChange({[id]: value});
    };

    const fields = [
        {id: "part_number", label: "partNumber", value: productFields.part_number, onChange: handleChangeField},
        {id: "date_code", label: "dateCode", value: productFields.date_code, onChange: handleChangeField},
        {id: "manufacturer", label: "manufacturer", value: productFields.manufacturer, onChange: handleChangeField},
        {id: "cns", label: "CNS", value: productFields.cns, onChange: handleChangeField},
        {id: "quantity", label: "quantity", value: productFields.quantity, onChange: handleChangeField},
        {id: "weight", label: "weight", value: productFields.weight, onChange: handleChangeField},
    ];

    return(
        <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12}>
                {boxProducts.length > 0 ?
                    <SelectProduct products={boxProducts} onSelectProduct={onSelectProduct} selectedProductId={selectedProductId} setSelectedProductId={setSelectedProductId}/>
                    : ''
                }
            </Grid>
            {fields.map((field, index) => (
                <TextFieldComponent key={index} id={field.id} label={field.label} value={field.value} onChange={field.onChange} />
            ))}
            <Grid item xs={12} sm={12} md={12}>
                <PhotoEditor photos={photos} handleUploadPhotos={handleUploadPhotos} handleDeletePhotos={handleDeletePhotos} handleUpdatePhoto={handleUpdatePhoto}/>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
                <Grid container spacing={2}>

                    {
                        selectedProductId ?
                            <>
                                <Grid item xs={4} sm={4} md={4}>
                                    <Button variant={'outlined'} color="success" fullWidth onClick={handleDeleteBoxProd}>Delete product</Button>
                                </Grid>
                                <Grid item xs={4} sm={4} md={4}>
                                    <Button variant={'outlined'} color="success" fullWidth onClick={() => onMoveProduct(productFields)}>Move product to another box</Button>
                                </Grid>
                                <Grid item xs={4} sm={4} md={4}>
                                    <Button variant={'outlined'} color="success" fullWidth onClick={handleSaveProduct}>Save product</Button>
                                </Grid>
                            </>

                            :
                            <>
                                <Grid item xs={6} sm={6} md={6}>
                                    <Button variant={'outlined'} color="success" fullWidth onClick={handleDeleteBoxProd}>Delete product</Button>
                                </Grid>
                                <Grid item xs={6} sm={6} md={6}>
                                    <Button variant={'outlined'} color="success" fullWidth onClick={handleSaveProduct}>Save product</Button>
                                </Grid>
                            </>
                    }

                </Grid>
            </Grid>
        </Grid>
    )
}