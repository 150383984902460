import {Container, Grid} from "@mui/material";
import React from "react";
import SingleTask from "./SingleTask";


export default function Tasks({tasks, onEditButtonClick, onDeleteButtonClick, onCompectButtonClick}){
    const renderTasks = Array.isArray(tasks) ? (
        tasks.map((task, index) =>(
            <Grid key={index} item  xs={12} sm={12} md={12}>
                <SingleTask task = {task} onEditButtonClick={() => onEditButtonClick(task)} onDeleteButtonClick={() => onDeleteButtonClick(task.task_id)} onComplectButtonClick={() => onCompectButtonClick(task)}/>
            </Grid>
        ))
    ) : (
        <Grid item>No available data</Grid>
    )

    return(
        <Container>
            <Grid container spacing={5}>
                <Grid item xs={12} sm={12} md={12}>
                    <Grid container spacing={2}>
                        {renderTasks}
                    </Grid>
                </Grid>
            </Grid>
        </Container>
    );
}